.input-wrap-score {
  &.is-disabled {
    .dropdown-indicator {
      i {
        color: var(--input-color--disabled);
      }
    }
  }
  .input-holder {
    justify-content: space-between;
    display: flex;

    .dropdown-indicator {
      i {
        font-size: var(--icon-size-xxxs);
      }
    }
  }
}
