@import "../../constants.scss";

.alert-indicator-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-shadow: var(--box-shadow-prioritize-indicator);
  border-radius: var(--border-radius-s);
  margin-bottom: 20px;

  .alert-indicator-wrap {
    padding: 12px 15px;
    border-radius: var(--border-radius-s);
    transform: translate(4px, 1px);
    width: calc(100% - 4px);
    height: calc(100%);
    margin-top: -1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--border-color);
    font-size: var(--font-size-s);
    font-weight: 500;
    background-color: #fff;
    color: #41425a;
    line-height: 1.4;
    .alert-info {
      display: flex;
      align-items: center;

      &.info {
        i {
          color: var(--secondery-main-color);
        }
      }

      &.warning {
        i {
          color: #ff6a00;
        }
      }
    }
    i {
      margin-right: var(--padding-xs);
    }
  }
  .alert-indicator-offset {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: var(--border-radius-s);
    &.info {
      background-color: var(--secondery-main-color);
    }

    &.warning {
      background-color: #ff6a00;
    }
    width: 100%;
    border: none;
  }
}
