@import "../../constants";

html {
  min-height: 100%;
}

.login-page-background-image {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-position: center top;
  background-size: cover;
  background-repeat: repeat;
  z-index: -1;
  background-color: #42435b;
  background: url("../../images/login-bkg.png");
}

.login-page-background-logo {
  position: absolute;
  height: 100%;
  width: 100vh;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  background-position: top;
  background-size: contain;
  opacity: 0.1;
}
.login-view-body {
  background-color: #f7f8fa;
  box-shadow: 0 0 50px #00000029;
  width: 70vw;
  min-width: 1200px;
  margin: 0 auto;
  margin-top: 54px;
  margin-bottom: 30px;
  border-radius: var(--border-radius-s);
  border: 1px solid #fff;
}

.login-page-content {
  cursor: default;
  display: flex;
  padding: 90px 36px 85px;
  //min-height: 534px;

  .login-page-content-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .login-page-content-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
  }
}

.login-page-header {
  display: flex;
  justify-content: center;
}

.login-page-title {
  font-size: 40px;
  color: #41425a;
  font-weight: 900;
  margin-bottom: 35px;
  text-align: center;
}

.login-page-form-container {
  color: var(--main-text-color);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 520px;
  min-width: 460px;
  justify-content: center;
  padding-top: 38px;

  .login-username {
    //margin-top: 30px;
  }
  .input-label {
    font-size: var(--font-size-l);
  }
}
.login-page-form {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.login-screenshot-img {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.login-page-form {
  .input-holder {
    color: #45465e;
  }
}
.form-button-wrap .login-btn {
  min-width: 250px;
}

.login-button-container {
  position: relative;
  .login-submit-button {
    height: 40px;
    border: 1px solid #d5dae3;
    background-color: #138af7;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border-radius: var(--border-radius-s);
    width: 100%;
  }
}

.login-logo-container {
  width: 246px;
  height: 44px;
  margin-bottom: 0;
  margin-top: 102px;
}

.login-page-change-password-instructions {
  color: #8e979b;
  font-size: 16px;
  padding-top: 23px;
}

.login-page-change-password-instructions {
  color: #8e979b;
  font-size: 16px;
  padding-top: 23px;
}

/* responsive */

@media screen and (max-width: 1279px) {
  .login-page-content .login-page-content-image {
    width: 40%;
  }
  .login-page-content .login-page-content-form {
    width: 60%;
  }
  .login-view-body {
    min-width: 960px;
  }
}
@media screen and (max-width: 1023px) {
  .login-logo-container {
    margin-top: 34px;
  }
  .login-view-body {
    min-width: 340px;
    max-width: 600px;
    margin-top: 34px;
  }
  .login-page-content {
    justify-content: flex-start;
    flex-flow: column wrap;
    padding: 30px 36px 30px;
  }
  .login-page-content .login-page-content-image {
    width: 100%;
    height: 180px;
  }
  .login-page-content .login-page-content-form {
    width: 100%;
  }
  body {
    background: #42435b;
  }
}

@media screen and (max-width: 799px) {
  .login-page-form-container {
    padding-top: 30px;
    .input-wrap.is-large {
      margin-bottom: 15px;
    }
  }
  .login-page-title {
    font-size: var(--font-size-xxl);
    margin-bottom: 20px;
  }
  .login-page-form-container {
    min-width: 300px;
  }
}
@media screen and (max-height: 799px) {
  .login-logo-container {
    margin-top: 50px;
  }
  .login-page-content {
    padding: 30px 36px;
    //min-height: 440px;
  }
}
@media screen and (min-height: 800px) {
  .login-view-body {
    margin-top: 10vh;
  }
}
@media screen and (min-height: 1079px) {
  .login-view-body {
    margin-top: 14vh;
  }
}
