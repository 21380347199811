@import "../../../../constants";

.findings-list-header-wrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid var(--main-border-color);
  color: var(--table-header-color);
  border-right: none;
  border-left: none;
  border-top: none;
  cursor: default;
  height: 52px;
  flex: 0 0 52px;
  width: 100%;

  &:hover {
    background-color: transparent;
  }
  .table-header-cell {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 var(--global-table-col-padding);
    font-size: var(--font-size-xs);
    font-weight: 500;
    color: var(--primary-text-color);
    padding-top: 3px;

    .seem-icon-down-arrow,
    .seem-icon-up-arrow {
      color: #c6c6cd;
      font-size: var(--icon-size-s);
      margin-left: 5px;
    }
    &.group-indicator-container {
      padding: 0;
      .group-indicator {
        height: 100%;
        width: 4px;
        background: #e3e3e7;
        transition: background-color ease-in-out 0.15s;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &.finding-account-col {
      padding-left: 6px;
    }
  }
  .findings-table-checkbox.checkbox-style {
    left: 0px;
    top: 0px;
  }
}
.findings-table-checkbox {
  .checkbox-label {
    margin-top: 11px;
  }
}
.finding-list-item-table-row.multi-select-indicator-row {
  height: 44px !important;
  flex: 0 0 44px !important;
  display: flex;
  position: relative;
  width: 100%;
  .group-indicator-container {
    position: absolute;
    left: 0;
    top: 0;
    .group-indicator {
      background-color: #e3e3e7;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.multi-select-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 100%;
  font-size: var(--font-size-s);
  font-weight: 500;
  background-color: #f9f9fb;
  .multi-select-indicator-text {
    margin-right: 15px;
  }
}
