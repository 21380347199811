@import "../../../../../constants";

.filter-dropdown-wrap {
  .form-button-wrap {
  }
  @include filter-dropdown-wrap-style;
  .radio-button-input-wrap {
    height: 32px;
    margin-bottom: 0;
  }
  .radio-button-list-option {
    margin-inline-end: 0;
  }
  &.filter-dropdown-wrap-is-status {
    margin-left: 107px;
  }
}

.field-selected-value {
  border-radius: 8px 0px 0px 8px;
  @include filter-item-style;
  @include ellipsis(100%);
  line-height: 32px;
}
