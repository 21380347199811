@import "../application/src/constants";

.scopes-list-wrapper {
  display: flex;
  align-items: center;
  color: var(--secondery-main-color);
  font-weight: 500;
  flex-wrap: wrap;
  width: 100%;
  font-size: var(--font-size-xs);
  padding-top: 6px;
  padding-bottom: 6px;

  .profiles-scopes-tab {
    border-radius: 100px;
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: 106px;
    height: 24px;
    border: 1px solid var(--secondery-main-color);
    padding: 2px 8px;
    span {
      @include ellipsis(100%);
    }

    @media screen and (max-width: 1440px) {
      max-width: 85px;
    }

    i {
      margin-right: 4px;
    }
  }

  & > :not(:last-child) {
    margin-right: 4px;
  }

  & > * {
    margin-bottom: 8px;
  }
}
