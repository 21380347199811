@import "../../../constants";

.sla-popover-container {
  height: 114px;
  width: 270px;
  background-color: white;
  box-shadow: var(--rmq-box-shadow);
  border-radius: var(--border-radius-s);
  padding: var(--padding-m);
  color: var(--primary-main-color);

  & > :not(:last-child) {
    margin-bottom: 15px;
  }
}

.sla-popover-row {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-xs);

  .sla-popover-row-param {
    font-weight: var(--font-weight-semibold);
  }

  .sla-overdue {
    font-weight: var(--font-weight-semibold);
    color: red;
  }
}

.sla-popover-icon {
  cursor: pointer;
}
