.locations-tree-wrapper {
  width: 100%;
  .scope-management-wrap,
  .scope-management-container {
    width: 100%;

    .scope-management-wrapper {
      border-radius: var(--border-radius-s);

      &:hover {
        border: var(--border-style);
      }
    }

    .selected-scope-data {
      width: 100%;
    }

    .seem-icon-dropdown-arrow {
      font-size: var(--icon-size-xxxs) !important;
      box-shadow: none;
      color: var(--secondery-main-color);
    }
  }
}
