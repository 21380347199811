@import "../../constants";

.sidebar-layout-wrapper {
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;

  .sidebar-layout-main {
    width: 100%;
    height: 100%;
    padding: 24px;
    flex: 1 1 600px;
    overflow: auto;
  }
}
