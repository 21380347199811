@import "../application/src/constants";

.user-avatar {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-style: solid;
  border-color: #fff;

  &.is-small {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    padding-top: 2px;
    border-width: 1px;
    font-size: var(--font-size-xxs);
    font-weight: 500;
  }

  &.is-medium {
    width: 34px;
    height: 34px;
    flex: 0 0 34px;
    font-weight: 600;
    border-width: 2px;
    font-size: var(--font-size-s);
  }
}
