@import "../../constants";

.manual-ticket-form {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .endpoint-list {
    min-height: 300px;
    overflow: auto;
    padding-right: 15px;
  }

  .endpoint-placeholder {
    height: 300px;
  }

  .endpoint-keys {
    min-height: 68px;
  }
}
