@import "../../../constants.scss";

.filter-in-out-container {
  display: flex;
  position: absolute;
  background: white;
  border-radius: var(--border-radius-s);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  width: 142px;
  width: 58px;
  height: 24px;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  bottom: 0;

  .in-out-wrapper {
    font-size: var(--icon-size-m);
    color: var(--secondery-main-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filter-include-container {
    margin-right: 12px;
  }
}

.findings-carousel-item {
  .filter-in-out-container {
    opacity: 0;
    transition: opacity ease-in-out 0.15s, transform ease-in-out 0.15s;
  }
  &:hover {
    .filter-in-out-container {
      opacity: 1;
      transform: translateY(15px);
    }
  }
}
.include-in-filter-icon-class,
.exclude-from-filter-icon-class {
  width: 110px;
  text-align: center;
}

.finding-title-col,
.finding-score-col,
.finding-sla-status-col,
.finding-ticket-status-col {
  .filter-in-out-container {
    z-index: 9999999999;
    right: 0;
    opacity: 0;
    bottom: 15px;
    transition: none;
    background-color: var(--table-row-color-hover);
    box-shadow: none;
  }
  &:hover {
    .filter-in-out-container {
      opacity: 1;
    }
  }
}
