@import "../../constants";

.side-drawer-wrapper {
  height: calc(100vh - var(--header-height));
  min-height: 250px;
  right: 1px;
  top: 60px;
  z-index: 10;
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 18px 14px 0 14px;
  border: 1px solid #f5f5f5;
  border-radius: var(--border-radius-s);
  transition: var(--transition);
  background: #ffffff 0 0 no-repeat padding-box;

  &.has-shadow {
    &.--l {
      box-shadow: var(--side-drawer-shadow-l);
    }
    &.--s {
      box-shadow: var(--side-drawer-shadow-s);
    }
  }

  &.is-full-screen {
    max-width: 100%;
    width: 100%;
    background: transparent;
  }
}

@media screen and (max-width: 1440px) {
  .side-drawer-wrapper {
    &:not(.is-full-screen) {
      width: unset !important;
    }
  }
}
