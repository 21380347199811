.integration-details-wrapper {
  width: 997px;

  .back-to-integrations {
    margin-bottom: 24px;

    .form-button {
      padding: 0;
    }
  }
}
