@import "../application/src/constants";

.permissions-wrapper {
  position: relative;
  height: 100%;

  .permissions-btn {
    position: absolute;
    right: 0;
    top: -3px;
  }

  .page-description {
    margin-top: 16px;
  }

  .permissions-list-wrapper {
    margin-top: 31px;

    & > :not(:last-child) {
      margin-bottom: 16px;
    }

    .permissions-list-item-wrapper {
      width: 100%;
      height: 80px;
      border: 1px solid var(--border-color);
      box-shadow: var(--integration-ticket-shadow);
      border-radius: var(--border-radius-s);
      display: flex;

      .permissions-list-cell {
        padding: 14px;
      }

      & > :not(:last-child) {
        border-right: 1px solid var(--main-border-color);
        margin-right: 10px;
      }
    }
  }
}
