@import "../application/src/constants";

.integration-fields-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 135px;
  margin-bottom: 24px;

  & > :not(:last-child) {
    margin-right: 13px;
  }
}

.integration-fields-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.section-title {
  color: var(--title-color);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-bold);
  margin: 20px 30px 20px 0;
  border-bottom: var(--border-style);
  line-height: 1.5;
}
.section-subtitle {
  margin-top: 20px;
  font-size: var(--font-size-s);
  color: var(--title-color);
  font-weight: 500;
  margin-bottom: 5px;
}

.row {
  display: flex;
}

.field {
  padding-right: 15px;
  margin-top: 16px;

  &-l {
    @extend .field;
    width: 100%;
  }

  &-s {
    @extend .field;
    width: 50%;
  }

  .checkbox-input-wrap.checkbox-style {
    .checkbox-input + label:before {
      top: 0;
      left: 0;
    }
  }
}
