@import "../../../constants";

.sidebar-group-wrapper {
  display: flex;
  flex-direction: column;

  color: var(--primary-main-color);
  .side-group-header {
    display: flex;
    height: 21px;
    align-items: center;

    .sidebar-group-title {
      font-size: var(--font-size-s);
      font-weight: 500;
      margin-left: 8px;
    }

    i {
      font-size: var(--font-size-m);
    }
  }

  .sidebar-group-items {
    color: #8d8e9c;
    margin-left: 24px;
    margin-top: 2px;

    & > :not(:last-child) {
      margin-bottom: 5px;
    }

    .sidebar-group-item {
      cursor: pointer;
      text-decoration: none;
      color: inherit;
      @include animate-color;
      &:hover {
        color: var(--primary-main-color);
      }
      &.active {
        color: var(--secondery-main-color);
        font-weight: 500;
      }
    }
  }
}
