@import "../../constants";

.remediation-queue-wrapper {
  position: relative;
  overflow-y: auto;
  width: 100%;
  display: flex;
  height: calc(100vh - var(--header-height) - 15px);
  flex-flow: row nowrap;
  flex-flow: column nowrap;
  align-items: center;

  .rmq-queue-data {
    width: 100%;
    max-width: var(--rmq-container-width);
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--rmq-header-height));
    z-index: 3;
  }

  .findings-data-area {
    box-shadow: none;
    height: calc(100% - 5px);
    padding-top: 70px;
    overflow-x: auto;
    min-width: unset;
    max-width: calc(100% - 30px);
  }

  .rmq-settings-prioritize-wrapper {
    top: 20px;
    right: 35px;
  }
}
.remediation-queue-container {
  border-radius: 10px;
  padding: 20px calc(var(--page-padding) * 2);
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
  margin: 20px var(--page-padding) 0 var(--page-padding);

  &.rmq-findings-table-wrapper {
    position: relative;
    padding: 12px 0;
    height: calc(100vh - var(--rmq-header-height) - 700px);
    min-height: 450px;
    overflow: auto;

    .findings-list-header-wrapper,
    .findings-list-item-wrapper {
      position: relative;

      .finding-account-col {
        width: var(--rmq-col-account);
        flex: 0 0 var(--rmq-col-account);
        &.table-header-cell {
          width: var(--rmq-col-account);
          flex: 0 0 var(--rmq-col-account);
        }
      }

      .finding-title-col {
        width: var(--rmq-col-title);
        flex: 0 0 var(--rmq-col-title);

        &.table-header-cell {
          width: var(--rmq-col-title);
          flex: 0 0 var(--rmq-col-title);
        }
      }
      .finding-status-col {
        width: var(--rmq-col-status);
        flex: 0 0 var(--rmq-col-status);
        &.table-header-cell {
          width: var(--rmq-col-status);
          flex: 0 0 var(--rmq-col-status);
        }
      }
      .finding-score-col {
        width: var(--rmq-col-score);
        flex: 0 0 var(--rmq-col-score);
        &.table-header-cell {
          width: var(--rmq-col-score);
          flex: 0 0 var(--rmq-col-score);
        }
      }
      .finding-sla-status-col {
        width: var(--rmq-col-sla-status);
        flex: 0 0 var(--rmq-col-sla-status);
        &.table-header-cell {
          width: var(--rmq-col-sla-status);
          flex: 0 0 var(--rmq-col-sla-status);
        }
      }
      .finding-ticket-status-col {
        width: var(--rmq-col-ticket-status);
        flex: 0 0 var(--rmq-col-ticket-status);
        &.table-header-cell {
          width: var(--rmq-col-ticket-status);
          flex: 0 0 var(--rmq-col-ticket-status);
        }
      }
      .finding-ticket-id-col {
        width: var(--rmq-col-ticket-id);
        flex: 0 0 var(--rmq-col-ticket-id);
        &.table-header-cell {
          width: var(--rmq-col-ticket-id);
          flex: 0 0 var(--rmq-col-ticket-id);
        }
      }
    }
  }
}

.remediation-queue-title {
  font-size: var(--font-size-xl);
  font-weight: 500;
  color: #26026c;
  margin-bottom: 14px;
  width: 100%;
  white-space: nowrap;
}
.input-wrap.has-size-custom.rmq-input-project,
.input-wrap.has-size-custom.rmq-input-schedule {
  width: 70%;
  min-width: 200px;
}

.input-wrap.has-size-custom.rmq-input-issue-type,
.input-wrap.has-size-custom.rmq-input-que-size {
  width: 30%;
  min-width: 80px;
}
.rmqfs-table {
  .findings-data-area {
    height: 340px;
  }
  .findings-table-container {
    height: calc(100% - 30px);
    max-height: 300px;
  }
}

.rmqfwl-table {
  .findings-data-area {
    height: 350px;
  }
  .findings-table-container {
    height: calc(100% - 40px);
    max-height: 300px;
  }
}
.rmq-finding-table {
  .side-drawer-wrapper {
    position: fixed;
    min-height: 100vh;
    top: var(--header-height);
  }
  .group-indicator-container {
    display: none;
  }
}
.rmq-findings-table-wrapper {
  .findings-data-area.is-loading:after {
    display: none;
  }
}
