@import "../../../constants";

.workload-description {
  margin: 16px 0;
  color: #8d8e9c;
  font-size: var(--font-size-s);
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
}
