@import "../../constants";

.show-more-list-wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  .show-more-text {
    font-size: var(--font-size-xs);
    margin-left: 6px;
    color: var(--secondery-main-color);
    font-weight: 500;
    cursor: pointer;
  }

  .show-more-list-name {
    width: fit-content;

    &.is-small {
      @include ellipsis(60px);
    }

    &.is-medium {
      @include ellipsis(80px);
    }
  }
}

.popover-container {
  background-color: white;
  width: 318px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid var(--main-border-color);
  border-radius: var(--border-radius-s);
  font-size: var(--font-size-xs);
  max-height: 170px;
  min-height: 65px;
  padding: 8px 8px 8px 13px;
}

.show-more-list-popover-wrapper {
  font-size: var(--font-size-s);

  .show-more-list-popover-item {
    display: flex;
    align-items: center;

    .user-avatar {
      margin-right: 12px;
    }
  }

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
}
