.handle-container {
  display: flex;
  align-items: center;

  .handle-point {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    left: 20px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    &.add {
      .inside-dot {
        display: none;
      }
    }

    .inside-dot {
      width: 4px;
      height: 4px;
      background-color: white;
      border-radius: 50%;
    }

    &.rejected {
      background-color: var(--jira-rejected-color);
    }

    &.done {
      background-color: var(--jira-done-color);
    }

    &.exception {
      background-color: var(--finding-risk-accepted-color);
    }

    &.false_positive {
      background-color: var(--finding-flase-positive-color);
    }

    &.open_confirmed {
      background-color: var(--finding-open-confirmed);
    }
  }
}

.add-queue-to-flow {
  display: flex;
  align-items: center;
  .add-queue-connector {
    height: 1px;
    background-color: #e9e9e9;
    width: 25px;
  }
  .add-queue-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    background-color: white;
    border: 1px solid #e9e9e9;
    border-radius: 25px;
    color: var(--secondery-main-color);
    font-size: var(--font-size-xs);
  }
}

.action-menu-items-wrapper.new-queue-when-menu {
  padding: 0;
  .actions-menu-title {
    padding: 0 12px;
    color: #8d8e9c;
  }
}
