@import "../../constants.scss";

.selection-menu-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--font-size-s);
  justify-content: center;
  height: 42px;

  ul {
    height: 40px;
  }
  .selection-menu-list {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    height: 60px;
    align-items: center;
  }

  &.primary-menu {
    margin-left: 60px;
    font-size: var(--font-size-s);
    height: 100%;

    .selection-menu-option {
      i {
        font-size: 16px;
        color: #b7b4bd;
      }
      font-weight: 500;
      padding-left: 15px;
      padding-right: 15px;
      margin-right: 15px;
      height: 100%;
      position: relative;
      color: #b7b4bd;

      .selection-menu-option-text {
        white-space: nowrap;
      }

      .seem-icon {
        vertical-align: middle;
      }
      &:hover {
        //opacity: 1;
        color: #fff;
        i {
          color: #fff;
        }
        .menu-underline {
          background: #41425a;
          opacity: 1;
        }
      }
      .menu-underline {
        position: absolute;
        left: 0;
        top: 55px;
        background: var(--secondery-main-color);
        width: 100%;
        height: 5px;
        border-top-left-radius: var(--border-radius-xs);
        border-top-right-radius: var(--border-radius-xs);
        opacity: 0;
      }
      &.is-active {
        opacity: 1;
        color: var(--secondery-main-color);

        .menu-underline {
          opacity: 1;
          background: var(--secondery-main-color);
        }
        .seem-icon {
          color: inherit;
        }
      }

      &.is-disabled {
        pointer-events: none;
        color: var(--input-color--disabled);
        opacity: 0.4;
        cursor: default;
        input {
          cursor: default;
        }
      }
    }
  }

  &.secondary-menu {
    display: flex;
    position: relative;

    .selection-menu-list {
      position: relative;
      background: var(--group-by-panel-background);
      border-radius: var(--border-radius-s-m);
      height: 42px;
      display: flex;
      align-items: center;
      padding-left: 4px;
      padding-right: 4px;

      .selection-bg {
        position: absolute;
        left: 0;
        top: 4px;
        border-radius: var(--border-radius-s-m);
        border: 2px solid var(--secondery-main-color);
        background: #fff;
        width: 200px;
        height: 35px;
        z-index: 0;
        transition: transform ease-out 0.25s;
      }
    }

    .selection-menu-list-selected-0 {
      .selection-bg {
        width: 80px;
        transform: translateX(5px);
      }
    }

    .selection-menu-list-selected-1 {
      .selection-bg {
        width: 83px;
        transform: translateX(88px);
      }
    }

    .selection-menu-list-selected-2 {
      .selection-bg {
        width: 67px;
        transform: translateX(176px);
      }
    }

    .selection-menu-list-selected-3 {
      .selection-bg {
        width: 102px;
        transform: translateX(247px);
      }
    }

    .selection-menu-list-selected-4 {
      .selection-bg {
        width: 87px;
        transform: translateX(355px);
      }
    }

    .selection-menu-list-selected-5 {
      .selection-bg {
        width: 50px;
        transform: translateX(448px);
      }
    }

    .selection-menu-option {
      padding: 0 15px;
      margin-right: 5px;
      transition: var(--transition);
      border-radius: var(--border-radius-s-m);
      font-weight: 500;
      z-index: 1;
      height: 36px;
      line-height: 39px;
      @include ellipsis(100%);
      @include animate-color;

      &:hover {
        background: rgba(255, 255, 255, 0.6);
        opacity: 0.9;
        border-radius: var(--border-radius-s-m);
      }

      &.is-active {
        color: var(--secondery-main-color);
        font-weight: 600;
        opacity: 1;
        cursor: default;
        border-radius: var(--border-radius-s-m);

        &:hover {
          background: transparent;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .selection-menu-option {
    user-select: none;
    // width: 180px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
      margin: 0px 12px 0 0;
    }
  }

  &.tertiary-menu {
    .selection-menu-option {
      .selection-img {
        width: 22px;
        height: 22px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 3px;
        filter: grayscale(100%);
        cursor: default;
      }

      &.is-active {
        .selection-img {
          filter: none;
        }
        &:hover {
          cursor: default;
          color: var(--primary-text-color);
          .selection-img {
            opacity: 1;
          }
        }
      }
      &:hover {
        color: var(--input-color--hover);
        .selection-img {
          opacity: 0.8;
        }
      }
    }
  }

  .sub-header-menu-item-wrapper {
    background-color: white;
    position: absolute;
    top: 81px;
    left: 8px;
    border-radius: var(--border-radius-s);
    width: 200px;
    // background-color: white;
    color: var(--primary-main-color);
    font-size: var(--font-size-s);
    cursor: pointer;
    padding: 4px 10px 4px 18px;
    box-shadow: var(--menu-box-shadow);
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .sub-header-menu-item-link-active,
    .sub-header-menu-item-link {
      display: flex;
      align-items: center;
      padding: 12px 18px;
      font-weight: 500;
      height: 42px;
      font-size: var(--font-size-s);
      background: #f9f9fb;
      border-radius: 0;
      margin-bottom: 4px;
      text-decoration: none;
      color: var(--primary-text-color);

      &:hover {
        background-color: #e4e7fe;
      }
    }

    .sub-header-menu-item-link-active {
      background-color: #e4e7fe;
    }
  }
}

@media screen and (max-width: 1023px) {
  .selection-menu-wrapper.primary-menu
    .selection-menu-option
    .selection-menu-option-text {
    font-size: 12px;
  }
  .settings-menu .user-details-container .username-display {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .selection-menu-wrapper.primary-menu {
    margin-left: 30px;
  }
}
