@import "../../constants";

.users-list-container {
  height: 100%;
  .users-list-cell {
    display: flex;
    align-items: center;

    &.users-list-name {
      .user-avatar {
        margin-right: 6px;
      }
    }

    &.users-list-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: var(--font-size-m);
      color: var(--primary-main-color);

      & > :not(:last-child) {
        margin-right: 23px;
      }
    }
  }
}

.user-name-bold {
  font-weight: 600;
}

.user-actions-form {
  .modal-buttons-container {
    justify-content: space-between;

    .form-button-wrap {
      margin: 0;
    }
  }
}
