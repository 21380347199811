.integrate-slack-tooltip {
  pointer-events: auto !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }

  .integrate-slack-link {
    text-decoration: underline;
    cursor: pointer;
  }
}
