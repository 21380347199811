@import "../../../constants";

$finding-attachments-table-col-4: 40px;
$finding-attachments-table-col-3: 40px;
$finding-attachments-table-col-2: 250px;
$finding-attachments-table-col-1: calc(
  100% - #{$finding-attachments-table-col-2} - #{$finding-attachments-table-col-3} -
    #{$finding-attachments-table-col-4} - 20px
);

$finding-attachments-with-state-table-col-3: 84px;
$finding-attachments-with-state-table-col-2: 250px;
$finding-attachments-with-state-table-col-1: calc(
  100% - #{$finding-attachments-with-state-table-col-2} - #{$finding-attachments-with-state-table-col-3} -
    20px
);

.attachments-container {
  display: flex;
  justify-content: flex-end;

  > div {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.finding-attachments-table {
  min-width: 600px;
  overflow: auto;
  .data-table-row {
    .data-table-cell:nth-child(1) {
      width: $finding-attachments-table-col-1;
      flex: 1 0 auto;
    }

    .data-table-cell:nth-child(2) {
      flex: 0 0 $finding-attachments-table-col-2;
      width: $finding-attachments-table-col-2;
    }

    .data-table-cell:nth-child(3) {
      flex: 0 0 $finding-attachments-table-col-3;
      width: $finding-attachments-table-col-3;
      cursor: pointer;
    }

    .data-table-cell:nth-child(4) {
      flex: 0 0 $finding-attachments-table-col-4;
      width: $finding-attachments-table-col-4;
      cursor: pointer;
    }

    &.is-uploading {
      .data-table-cell {
        background-color: #ececf2;
      }
      .data-table-cell:nth-child(3) {
        color: var(--secondery-main-color);
      }
    }

    &.is-error {
      .data-table-cell {
        background-color: var(--input-error-background);
      }
      .data-table-cell:nth-child(3) {
        color: var(--input-error-color);
      }
    }

    &.is-uploading,
    &.is-downloading,
    &.is-error {
      .data-table-cell:nth-child(1) {
        width: $finding-attachments-with-state-table-col-1;
        flex: 1 0 auto;
      }

      .data-table-cell:nth-child(2) {
        flex: 0 0 $finding-attachments-with-state-table-col-2;
        width: $finding-attachments-with-state-table-col-2;
      }

      .data-table-cell:nth-child(3) {
        flex: 0 0 $finding-attachments-with-state-table-col-3;
        width: $finding-attachments-with-state-table-col-3;
        font-weight: 500;
        cursor: default;

        i {
          margin-right: 3px;
        }
      }

      .row-status {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.start-sync-tooltip {
  width: 380px;
}

.stop-sync-tooltip {
  width: 450px;
}

.attachments-no-data {
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-text-color);
  background-color: var(--main-background-color);
  border-radius: var(--border-radius-s);
  font-weight: 500;
}
