@import "../../../../constants";

.ReactCollapse--content {
  border-top: none;
  padding: 0 0;
}

.more-less-indicator {
  color: var(--secondery-main-color);
  font-weight: 400;
  font-size: 13px;
  display: inline-flex;
}

.tags-container {
  .tags-header {
    color: var(--table-header-color);
  }
}
