@import "../../constants.scss";

.static-fallback-container {
  width: 100%;
  height: 100%;
  position: relative;

  .popup-dialog-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    height: 250px;
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translatex(-50%);

    .popup-dialog-text {
      margin-top: 30px;
    }
  }
}
