@import "../constants.scss";

.app-header-wrapper {
  height: var(--header-height);
  padding: 0 var(--page-padding) 0 var(--page-padding);
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--app-header-color);
  color: var(--secondary-text-color);
  position: sticky;
  top: 0;
  z-index: 2;

  i {
    color: var(--secondary-text-color);
  }
  .logo-menu-container {
    display: flex;
    align-items: center;
    height: 100%;
    .logo-container {
      width: 154px;
      height: 30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      i {
        color: var(--secondery-main-color);
      }
    }
    .logo-link {
      line-height: 0;
    }
    .logo-link:hover {
      opacity: 0.85;
    }
  }

  .logout {
    height: 40px;
    width: fit-content;
    background-color: #00d068;
    border-radius: var(--border-radius-s);
    border: 1px solid var(--main-border-color);
    color: #fff;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--main-border-color);
      box-shadow: 0 3px 16px #00000029;
      cursor: pointer;
    }
  }
  .app-header-left {
    display: flex;
    align-items: center;
  }
}
.header-popover-menu {
  border-radius: 4px;
  background-color: white;
  box-shadow: var(--menu-box-shadow);
}

@media screen and (max-width: 900px) {
  .username-display {
    display: none;
  }
  .app-header-wrapper .user-details-container .user-icon {
    margin-right: 0;
  }
}
