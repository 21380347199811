@import "../../../../../constants";
.carousel-wrapper {
  display: flex;
  justify-content: center;
  margin-right: 0;
  &.carousel-wrapper-no-results {
    pointer-events: none;
  }

  .findings-navigator-carousel {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: var(--transition);
    &.findings-navigator-carousel-hidden {
      opacity: 0;
    }
  }
  .groups-carousel-container {
    justify-content: center;
    width: 100%;
    z-index: 1;
    flex: 0 0 120px;

    .react-multi-carousel-track {
      width: 100%;
    }
  }
  .react-multi-carousel-dot-list {
    position: relative;
    top: -18px;
    flex: 0 0 20px;
  }
  .react-multi-carousel-dot button {
    border: none;
    height: 6px;
    width: 6px;
  }

  .react-multi-carousel-dot--active button {
    background-color: var(--secondery-main-color);
  }
  &.has-arrows {
    .findings-navigator-carousel {
      margin: 0 45px;
    }
  }
}

.custom-arrow-container {
  height: 30px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 16px;
  color: var(--secondery-main-color);

  &:hover {
    color: var(--main-button-color--hover);
  }
}

.carousel-custom-buttons {
  position: absolute;
  display: flex;
  height: 30px;
  justify-content: space-between;
  flex-direction: row;
  margin-left: -28px;
  margin-top: 38px;
}
.loading-blocks-wrap-carousel {
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  flex: 0 0 120px;
}
.loading-block-carousel {
  width: 142px;
  height: 82px;
  position: relative;
  background: #fff;
  margin-top: 24px;
  margin-right: 17px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);

  .loading-block-square {
    left: 40px;
    top: 16px;
    width: 60px;
    height: 28px;
  }
  .loading-block-row {
    left: 30px;
    top: 52px;
    width: 80px;
    height: 14px;
  }
}
@media screen and (max-width: 900px) {
  .carousel-wrapper .groups-carousel-container {
    //padding-left: 0;
    &:first-child {
      //padding-left: 0;
      .findings-carousel-item {
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
