@import "../../../constants";

@keyframes dash {
  to {
    background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
  }
}
.modal-dialog-content.upload-findings-fro-csv-modal {
  .modal-buttons-container {
    font-size: var(--font-size-s);
    justify-content: space-between;
  }
}
.dropzone-container {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #41425a;
  background: linear-gradient(90deg, var(--light-text-color) 50%, #fff 50%),
    linear-gradient(90deg, var(--light-text-color) 50%, #fff 50%),
    linear-gradient(0deg, var(--light-text-color) 50%, #fff 50%),
    linear-gradient(0deg, var(--light-text-color) 50%, #fff 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 16px 1px, 16px 1px, 1px 16px, 1px 16px;
  background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
  min-height: 250px;
  margin: 20px auto;
  align-items: center;
  cursor: pointer;
  color: var(--light-text-color);

  &.is-hover,
  &:hover {
    background: linear-gradient(
        90deg,
        var(--primary-text-color) 50%,
        transparent 50%
      ),
      linear-gradient(90deg, var(--primary-text-color) 50%, transparent 50%),
      linear-gradient(0deg, var(--primary-text-color) 50%, transparent 50%),
      linear-gradient(0deg, var(--primary-text-color) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 16px 1px, 16px 1px, 1px 16px, 1px 16px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    border-radius: 3px;
    padding: 10px;
    animation: dash 8s linear infinite;
    position: relative;
    color: var(--primary-text-color);
    z-index: 1;
    box-shadow: 0 0 0 #f3f3f3;
  }
}
.dropzone-container.dropzone-container-uploaded {
  background: #fff;
  border: var(--border-style);
  animation: none;
  color: var(--primary-text-color);
  cursor: default;
}
.dropzone-container-file {
  margin-right: 5px;
  font-size: var(--font-size-s);
  &.dropzone-container-file-error {
    color: var(--danger-button-color);
  }
}

.valid-message-container {
  display: flex;
  justify-content: space-between;

  .filename-message {
    margin-right: 3px;
  }
}

.error-message-container {
  .error-message {
    padding-top: 5px;
    margin-right: 3px;
  }

  .error-button {
    text-align: center;
    margin-top: 9px;
  }
}
