.new-queue-action-trigger {
  height: 88px;
  display: flex;
  width: calc(100% - 5px);
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: var(--border-radius-s);
  box-shadow: var(--rmq-box-shadow);
  border-left: 5px solid var(--primary-main-color);

  .create-rmq-btn {
    align-self: center;
  }

  .new-queue-trigger-data {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    justify-content: space-evenly;

    .new-queue-title {
      color: var(--app-header-color);
      font-size: var(--font-size-m);
      font-weight: 500;
    }
  }
}

.builtin-queue-intro {
  width: calc(100% - 5px);
  height: 44px;
  border-radius: var(--border-radius-s);
  box-shadow: var(--rmq-box-shadow);
  background-color: #ffffff;
  margin-top: 11px;
  display: flex;
  align-items: center;
  border-left: 5px solid var(--secondery-main-color);

  i {
    margin-left: 20px;
    margin-right: 10px;
    color: var(--secondery-main-color);
  }

  .builtin-queue-title {
    font-weight: 500;
    margin-right: 5px;
  }
}
