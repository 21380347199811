@import "../../constants";

.schedule-input-container {
  justify-content: space-between;
  cursor: pointer;

  .seem-icon-schedule {
    color: var(--secondery-main-color);
  }

  .schedule-value-label {
    display: flex;
    align-items: center;
    padding-top: 1px;
  }
}
