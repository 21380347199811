@import "../constants.scss";

.input-wrap {
  @include input-wrap;

  &.is-focused {
    .input-holder {
      border-color: var(--input-border-color--focused);
    }
  }
  .mce-content-body.mce-edit-focus {
    border-color: var(--input-border-color--focused);
  }
  .input-label {
    @include input-label;
  }
  .input-holder,
  .select__control {
    @include input-holder;
    cursor: pointer;
    textarea,
    .select__single-value {
      color: var(--input-color);
    }
    &:focus,
    &.is-focused {
      border: var(--input-border--focused);
    }
  }

  //sizes
  .is-inline {
    &.has-size-xxs {
      max-width: calc(var(--label-width-xxs) + var(--input-width-xxs));
      &:not(.has-label) {
        max-width: var(--input-width-xxs);
      }
      label {
        width: var(--label-width-xxs);
      }
      .input-holder,
      .select-input-holder-wrap {
        width: var(--input-width-xxs);
      }
    }
    &.has-size-xs {
      max-width: calc(var(--label-width-xs) + var(--input-width-xs));
      &:not(.has-label) {
        max-width: var(--input-width-xs);
      }
      label {
        width: var(--label-width-xs);
      }
      .input-holder,
      .select-input-holder-wrap {
        width: var(--input-width-xs);
      }
    }
    &.has-size-s {
      max-width: calc(var(--label-width-s) + var(--input-width-s));
      &:not(.has-label) {
        max-width: var(--input-width-s);
      }
      label {
        width: var(--label-width-s);
      }
      .input-holder,
      .select-input-holder-wrap {
        width: var(--input-width-s);
      }
    }
    &.has-size-m {
      max-width: calc(var(--label-width-m) + var(--input-width-m));
      &:not(.has-label) {
        max-width: var(--input-width-m);
      }
      label {
        width: var(--label-width-m);
      }
      .input-holder,
      .select-input-holder-wrap {
        width: var(--input-width-m);
      }
    }
    &.has-size-l {
      max-width: calc(var(--label-width-l) + var(--input-width-l));
      &:not(.has-label) {
        max-width: var(--input-width-l);
      }
      label {
        width: var(--label-width-l);
      }
      .input-holder,
      .select-input-holder-wrap {
        width: var(--input-width-l);
      }
    }
    &.has-size-xl {
      max-width: calc(var(--label-width-xl) + var(--input-width-xl));
      &:not(.has-label) {
        max-width: var(--input-width-xl);
      }
      label {
        width: var(--label-width-xl);
      }
      .input-holder,
      .select-input-holder-wrap {
        width: var(--input-width-xl);
      }
    }
    &.has-size-xxl {
      max-width: calc(var(--label-width-xxl) + var(--input-width-xxl));
      &:not(.has-label) {
        max-width: var(--input-width-xxl);
      }
      label {
        width: var(--label-width-xxl);
      }
      .input-holder,
      .select-input-holder-wrap {
        width: var(--input-width-xxl);
      }
    }
    &.has-size-max {
      width: 100%;
      label {
        width: var(--label-width-max);
        min-width: 120px;
      }
      .input-holder,
      .select-input-holder-wrap {
        width: var(--input-width-max);
      }
    }
  }

  &.has-size-xxs {
    width: calc(var(--label-width-xxs) + var(--input-width-xxs));
    .input-holder,
    .select-input-holder-wrap {
      width: 100%;
    }
  }
  &.has-size-xs {
    width: calc(var(--label-width-xs) + var(--input-width-xs));
    .input-holder,
    .select-input-holder-wrap {
      width: 100%;
    }
  }
  &.has-size-s {
    width: calc(var(--label-width-s) + var(--input-width-s));
    .input-holder,
    .select-input-holder-wrap {
      width: 100%;
    }
  }
  &.has-size-m {
    width: calc(var(--label-width-m) + var(--input-width-m));
    .input-holder,
    .select-input-holder-wrap {
      width: 100%;
    }
  }
  &.has-size-l {
    width: calc(var(--label-width-l) + var(--input-width-l));
    .input-holder,
    .select-input-holder-wrap {
      width: 100%;
    }
  }
  &.has-size-xl {
    width: calc(var(--label-width-xl) + var(--input-width-xl));
    .input-holder,
    .select-input-holder-wrap {
      width: 100%;
    }
  }
  &.has-size-xxl {
    width: calc(var(--label-width-xxl) + var(--input-width-xxl));
    .input-holder,
    .select-input-holder-wrap {
      width: 100%;
    }
  }
  &.has-size-max {
    width: 100%;
    .input-holder,
    .select-input-holder-wrap {
      width: 100%;
    }
  }
  &.has-size-custom {
    label {
      width: 100%;
    }
    .input-holder,
    .select-input-holder-wrap {
      width: 100%;
    }
  }

  &.has-label-width {
    &.has-size-xxs,
    &.has-size-xs,
    &.has-size-s,
    &.has-size-m,
    &.has-size-l,
    &.has-size-xl,
    &.has-size-xxl {
      max-width: unset;
    }
  }

  &.is-disabled {
    color: var(--input-color--disabled);
    cursor: default;
    label {
      color: var(--input-color--disabled);
      background-color: transparent;
    }
    .mce-content-body,
    .select__control,
    input {
      color: var(--input-color--disabled) !important;
      cursor: default !important;
      border: var(--input-border--disabled);
    }
    .input-holder {
      border: var(--input-border--disabled);
      cursor: not-allowed;
      &:hover {
        box-shadow: none;
      }
    }
    .ck-editor__top {
      display: none;
    }
    .select__single-value--is-disabled {
      color: var(--input-color--disabled) !important;
    }
  }
  &.has-read-only {
    .input-holder,
    .select__contorl,
    .mce-content-body {
      box-shadow: none;
      border-color: var(--input-border-color--readOnly);
      cursor: default;
      //pointer-events: none;
      background: #fff;
      background-color: #fff;
    }
  }

  &.is-inline {
    margin-right: 10px;
  }
  &.is-large {
    margin-bottom: 15px;
    .select__control,
    .input-holder {
      font-size: var(--font-size-l);
      padding: 0px 10px;
      height: 50px;
    }
  }
  &.is-inline {
    flex-direction: row;
  }
  &.has-label-top {
    flex-flow: column;
    margin-bottom: var(--input-margin-bottom);
    label {
      width: 100%;
      margin-bottom: var(--input-margin-bottom);
    }
    &.has-size-xxs {
      width: var(--input-width-xxs);
      label {
        width: 100%;
      }
      .input-holder,
      .select-input-holder-wrap {
        width: 100%;
      }
    }
    &.has-size-xs {
      width: var(--input-width-xs);
      label {
        width: 100%;
      }
      .input-holder,
      .select-input-holder-wrap {
        width: 100%;
      }
    }
    &.has-size-s {
      width: var(--input-width-s);
      label {
        width: 100%;
      }
      .input-holder,
      .select-input-holder-wrap {
        width: 100%;
      }
    }
    &.has-size-m {
      width: var(--input-width-m);
      label {
        width: 100%;
      }
      .input-holder,
      .select-input-holder-wrap {
        width: 100%;
      }
    }
    &.has-size-l {
      width: var(--input-width-l);
      label {
        width: 100%;
      }
      .input-holder,
      .select-input-holder-wrap {
        width: 100%;
      }
    }
    &.has-size-xl {
      width: var(--input-width-xl);
      label {
        width: 100%;
      }
      .input-holder,
      .select-input-holder-wrap {
        width: 100%;
      }
    }
    &.has-size-xxl {
      width: var(--input-width-xxl);
      label {
        width: 100%;
      }
      .input-holder,
      .select-input-holder-wrap {
        width: 100%;
      }
    }
  }
  &.table-filter-style {
    width: 100%;
  }
  &.bomba-style {
    .input-label {
      @include bomba-style-label;
    }
    .select__option-wrap {
      height: 34px;
      margin-bottom: 3px;

      &:last-child {
        .select-option {
          margin-bottom: 0;
        }
      }
    }
    .select-option-wrap {
      height: 34px;
    }
    .select__option {
      line-height: unset;
      height: 34px;
      display: flex;
      align-items: center;
      margin-top: 3px;
      background: #f9f9fb;
      &:first-child {
        //margin-top: 0;
      }
      &:hover {
        background-color: var(--secondary-main-hover-color);
      }
    }
    .select__single-value {
      //font-weight: var(--font-weight-semibold);
    }
    //.select__control {
    //  padding: 0 5px;
    //}
    .select__value-container,
    .select__placeholder,
    .select__option {
      font-size: var(--input-font-size);
    }
    &.is-disabled {
      .form-button-wrap.is-icon .seem-icon-dropdown-arrow {
        color: var(--input-color--disabled);
      }
    }
    .select__control {
      height: 34px;
      //padding: 0 5px;
    }
    .select__indicator {
      padding: 0;
    }
    .select__indicators {
      padding: 0;
    }
    &.has-label-mini {
      .input-label {
        border-bottom: none;

        margin: 0;
        padding: 0;
        height: 38px;
        //font-size: var(--font-size-s);
        @include mini-label;
      }
    }
    label {
      margin-bottom: 0;
    }
  }
  &.has-label-mini {
    .input-label {
      @include mini-label;
    }
    &.has-label-top {
      .input-label {
        margin-bottom: 0;
        padding-bottom: 0;
        padding-right: 15px;
        padding-top: 8px;
        height: 28px;
      }
    }
  }
}
.form-row {
  display: flex;
  width: 100%;
  .input-wrap {
    + .input-wrap {
      margin-left: 15px;
    }
  }
  + .form-row {
    margin-top: 5px;
  }
  .form-row-half {
    width: 50%;
    &:nth-child(2) {
      margin-left: 15px;
    }
  }
}
.error-holder {
  position: relative;
  width: 1px;
  height: 1px;
  opacity: 0;
}
.placeholder-text {
  color: var(--input-placeholder-color);
  font-weight: 400;
}
.form-text {
  font-size: var(--font-size-s);
  color: var(--primary-text-color);
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.4;
}
.form-separator {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: var(--border-style);
  width: 100%;
}
.input-help-text {
  margin-top: 5px;
  margin-left: 5px;
}
.form-separator-horizontal {
  margin-left: 15px;
  border-left: var(--border-style);
  padding-right: 15px;
  width: 15px;
  height: 38px;
}
.input-help-tooltip {
  width: 260px;
  line-height: 1.4;
  text-align: center;
  font-weight: normal;
  max-width: 350px;
}
