@import "../../../../constants.scss";

.findings-actions-button {
  &.has-outline {
    .form-button {
      box-shadow: 0 0 0 2px var(--secondery-main-color);
    }
  }
}
.findings-actions {
  z-index: 1;
  margin-left: -60px;

  .findings-actions-notification {
    width: 230px;
    background: var(--secondery-main-color);
    border-radius: 4px;
    padding: 15px 18px;
    color: white;
    font-weight: 500;

    .seem-icon-new-actions {
      margin-right: 7px;
    }

    .new-actions-title-wrap {
      font-size: var(--font-size-s);
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }

    .new-actions-description {
      font-size: var(--font-size-xs);
      line-height: 1.4;
    }
  }

  .Popover-tip {
    fill: var(--secondery-main-color);
    transform: translateX(193px) !important;
  }
}
.aggregate-findings-tooltip {
  width: 222px !important;
}
