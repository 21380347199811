@import "../../../constants.scss";

.error-message-tip-holder {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.has-label-top {
  .error-message-tip-holder {
    top: 75%;
    transform: translateY(-75%);
  }
}
.error-message-tip {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  font-size: var(--font-size-xs);
  font-weight: 500;
  border-radius: var(--border-radius-s);
  //transform: translate(150%,0);

  //@include bubble-style;

  i {
    margin-right: 5px;
  }

  &.alert {
    color: var(--input-error-color);
    background-color: var(--input-error-background);
  }

  &.info {
    background: #138af7 0 0 no-repeat padding-box;
    color: #ffffff;
    border: 1px solid #138af7;
  }
}
//.select-input-holder-wrap-inner + .error-message-tip,
//.ck-editor + .error-message-tip {
//  margin-top: 5px;
//}
//.input-wrap
//{
//  &.is-large {
//    .error-message-tip {
//      top: 9px;
//    }
//  }
//  &.has-label-top {
//    .error-message-tip {
//      top: 25px;
//    }
//  }
//  &.input-wrap-text-editor {
//    .error-message-tip {
//
//      margin-top: 35px;
//    }
//  }
//}

//.Popover-body {
//display: inline-flex;
//  flex-direction: column;
//}

