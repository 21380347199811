@import "../../../../../constants";

.scope-management-menu-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 559px;
  padding: 0 15px 15px 15px;
  box-shadow: var(--scope-filter-menu-box-shadow);
  border-radius: var(--border-radius-scope-filter);
  max-height: var(--menu-max-height);
  overflow: auto;
  min-height: var(--menu-min-height);
  position: absolute;
  left: -148px;

  .scope-management-header {
    display: flex;
    // justify-content: flex-end;
    width: 100%;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    font-size: 18px;

    .create-new-scope-btn {
      .form-button {
        padding-right: 10px;
      }
      .btn-right-icon {
        position: initial;
        margin-left: 20px;
        font-size: var(--font-size-xs);
      }
    }

    .scope-management-header-items {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      height: 62px;
    }

    .scope-management-header-separator {
      border-bottom: 1px solid var(--main-border-color);
      width: 100%;
      // padding-top: 8px;
    }
  }
  .scope-management-content {
    min-height: 250px;
  }
}

.scope-management-action-menu {
  .action-menu-ul {
    width: 300px;

    .action-menu-li {
      margin-bottom: 0;
      &:hover {
        background-color: #e4e7fe;
      }
      &:not(:last-child) {
        border-bottom: var(--border-style);
      }
      background-color: #fff;
      height: 90px;
    }
  }
}
