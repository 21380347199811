@import "../../../constants";

.instructions-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 665px;

  .instructions-form {
    height: 100%;
    overflow: auto;

    .instructions-editor {
      .mce-content-body {
        height: inherit;
        padding: 10px 15px;
        background: var(--main-background-color) !important;
        border-radius: var(--border-radius-s-m);
      }

      &.--edit {
        .mce-content-body {
          background: white !important;
        }
      }
    }

    .instruction-form-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 19px;

      & > :not(:last-child) {
        margin-right: 13px;
      }
    }
  }

  .instructions-close {
    align-self: flex-end;
  }

  .instructions-setup {
    display: flex;

    .instructions-title {
      font-size: var(--font-size-l);
      color: var(--app-header-color);
      font-weight: 500;
      margin-bottom: 28px;
      margin-right: 19px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .instructions-wrap {
    max-width: 553px;
  }
}
