.create-tenant-wrapper {
  border-bottom: var(--border-style);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.username {
  display: flex;
}

.checkbox-input-wrap.checkbox-style.send-email-cb {
  margin-top: 16px;

  .checkbox-input + label:before,
  .checkbox-input + label:after {
    top: 0;
    left: 0;
  }
}
