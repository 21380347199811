@import "../../../constants.scss";

.input-wrap-text {
  color: var(--input-color);
  display: flex;
  flex-direction: row;

  ::placeholder {
    color: var(--input-placeholder-color);
    opacity: 1.1;
  }

  &.with-help {
    label {
      display: flex;
      align-items: center;
      i {
        margin-left: 10px;
      }
    }
  }

  &.table-filter-text-input {
    width: 100%;
  }

  &.has-error {
    .input-holder {
      border-color: var(--input-error-color);

      > input {
        color: var(--input-error-color);
      }
    }
  }

  &.filter-name-input {
    &.has-read-only {
      .input-holder {
        font-weight: 600;
        border: none;
      }
    }
  }

  .input-holder {
    > input {
      padding: 0;
      border: none;
      width: 100%;
      height: 100%;
      @include ellipsis(100%);
    }

    input:focus {
      outline: none;
    }
  }

  input[type="password"] {
    //font-family: "seem-icons";
    //font-style: normal;
    //font-weight: normal;
    //speak: none;

    /* For safety - reset parent styles, that can break glyph codes*/
    //font-variant: normal;
    //text-transform: none;

    /* Font smoothing. That was taken from TWBS */
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

    /* add spacing to better separate each image */
    //letter-spacing: 10px;
    //color: #707070;
    //font-size: 10px;
  }

  &.is-disabled {
    cursor: default;

    .input-holder,
    input {
      pointer-events: none;
      cursor: default;
      border-color: var(--input-border-color--disabled);
      color: var(--input-color--disabled);
    }
  }
}
