@import "../../constants";

.badge-container {
  display: flex;
  align-items: center;
  height: 38px;
  //cursor: default;
  &:hover {
    .badge-text {
      color: var(--input-color--hover);
    }
  }

  .badge-icon-container {
    @include round-shape("transparent", var(--secondary-text-color));
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    width: 20px;
    height: 20px;

    .seem-icon {
      font-size: var(--font-size-xs);
    }

    &.with-shadow {
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
    }
  }

  .badge-text {
    @include ellipsis(100%);
    color: var(--primary-text-color);
    font-size: var(--font-size-xs);
    font-weight: 400;
    text-align: center;
    margin-left: 8px;
  }
}

.entity-details-pane-wrapper {
  .badge-container {
    .badge-text {
      font-weight: 500;
    }
    .badge-icon-container {
      width: 24px;
      height: 24px;

      .seem-icon {
        font-size: var(--icon-size-m);
      }
    }
  }
}
