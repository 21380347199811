@import "../../../constants.scss";

.schedule-settings-wrapper {
  max-width: 424px;
  border: var(--input-border);
  box-shadow: var(--box-shadow);
  background-color: #fff;
  padding: 5px 20px;
  width: 100%;


  display: flex;
  flex-flow: column nowrap;

  .schedule-settings-container {
    padding: 10px 0 0px 0;
    width: 100%;
    overflow: auto;

    .schedule-part-title {
      margin-bottom: 15px;
      @include mini-label;
    }
    &.schedule-settings-container-footer {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 10px;
      padding-top: 0;
    }
  }
}