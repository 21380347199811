@import "../../../constants.scss";

.saml-setup-wrap {
  .saml-options {
    display: flex;
    flex-direction: column;

    .radio-button-input-wrap {
      height: auto;
      margin-bottom: 12px;

      .radio-input {
        flex: 0 0 0;
        width: auto;
        margin-right: 8px;
      }
    }
  }

  .submit-saml {
    margin-top: 20px;
  }

  .file-uploader {
    height: 38px;
    display: flex;
    align-items: center;
  }

  .login-url-wrapper {
    margin-top: 36px;
  }

  .login-url {
    margin-top: 28px;
    font-weight: 500;
  }

  .url-description {
    margin-right: 100px;
    margin-top: 16px;
  }

  .copy {
    margin-left: 8px;
  }

  .form-button-group {
    .form-button-wrap {
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .input-wrap.has-label-top {
    margin-bottom: 0;
  }
}
