@import "../../../constants";

.filters-menu-header {
  position: relative;
  display: flex;
  width: 180px;
  flex: 0 0 180px;
  height: 40px;
  padding: 9px 6px 0px 0px;

  border-top: var(--input-border);
  border-left: var(--input-border);
  border-bottom: var(--input-border);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  justify-content: space-between;
  cursor: pointer;

  .selected-filter-title {
    cursor: pointer;
    &.is-empty {
      font-weight: 400;
      color: var(--input-placeholder-color);
      opacity: 1.1;
    }
    font-weight: 500;
    font-size: var(--font-size-s);
    margin-left: 12px;
    @include ellipsis(100%);
    user-select: none;
  }

  .dropdown-indicator {
    margin-left: 8px;
    margin-right: 8px;
    i {
      font-size: var(--font-size-xs);
    }
  }
}
.app-view-wrapper-placeholder {
  display: block;
  position: relative;
  height: 64px;
  width: 100%;
}
