@import "../../../constants";

.has-edit-mode {
  .filters-panel-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: initial;
  }
}
.filters-panel-form-wrapper {
  display: flex;
  min-height: 40px;
  width: 100%;
  overflow: hidden;
}
.filter-items-wrap {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row wrap;
  .form-button-wrap {
    height: 32px;
    margin-top: 2px;
  }
}
.filter-buttons-wrap {
  margin-left: auto;
  display: flex;
  flex: 0 1 auto;
  .form-button-wrap.is-icon {
    width: 30px;
    height: 32px;
    text-align: center;
    .form-button {
      width: 30px;
      height: 30px;
      text-align: center;
      .seem-icon {
        padding: 0;
      }
    }
  }
}
.dropdown-loading-indicator {
  @include dropdown-loading;
}
