@import "../../../../constants.scss";
.finding-attachments-table {
  .attachment-name-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      @include ellipsis(#{calc(100% - 30px)});
    }
  }
  .ticket-logo {
    flex: 0 1 auto;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: auto;
  }
}
