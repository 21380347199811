.error-indicator-wrapper {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: var(--border-radius-s);
  height: 44px;
  width: 100%;
  position: relative;

  .error-indicator-offset {
    background-color: #ff6a00;
    width: 100%;
    transform: translateX(2px);
    border: none;
    height: 100%;
    border-radius: var(--border-radius-s);
  }

  .error-indication-data {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #e8e9f0;
    transform: translate(6px, -45px);
    height: 46px;
    padding: 0 var(--padding-s);
    border-radius: var(--border-radius-s);
    font-size: var(--font-size-s);
    font-weight: 500;
    .error-indicator-icon {
      color: #ff6a00;
      margin-right: 8px;
    }
    .error-description {
      margin-top: 2px;
    }
  }
}
