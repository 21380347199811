@import "../../../../../constants";

.react-multi-carousel-list {
  padding-left: 10px;
  user-select: none;
  justify-content: flex-start;
}
.react-multi-carousel-item {
  flex: 0 0 158px !important;
  &:first-child {
    padding-left: 0;
  }
}

.findings-carousel-item {
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 142px;
  height: 82px;
  border-radius: var(--border-radius-s);
  background: #fff;
  margin-right: 14px;
  padding: 0 10px;
  color: var(--primary-text-color);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
  transition: all ease-in-out 0.15s, top ease-in-out 0.15s;
  top: 0;

  &:hover {
    transition: all 0.15s ease-in-out;
    box-shadow: 0px 4px 12px rgba(38, 38, 38, 0.16);
    top: -1px;
    background-color: white;
  }
  &.is-row {
    flex-direction: row;
    .findings-carousel-item-left {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }

    .navigation-item-separator {
      width: 1px;
      background-color: #e2e4ec;
      margin: 19px 0;
    }

    .findings-carousel-item-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 10px;

      .findings-carousel-item-count {
        font-size: var(--font-size-xxl);
        font-weight: 500;
      }

      .findings-carousel-item-bottom-name {
        font-size: var(--font-size-xxs);
      }
    }
  }

  .findings-carousel-item-top {
    text-align: center;
    font-size: var(--font-size-xxl);
    font-weight: 500;
    display: flex;
    align-items: baseline;
    justify-content: center;

    .sevirity-level {
      display: none;
    }
  }
  .findings-carousel-item-bottom {
    display: flex;
    align-items: center;
    font-size: var(--font-size-xxs);
  }
  .findings-carousel-item-bottom-icon {
    margin-right: 10px;
    margin-top: 4px;
    width: 24px;
  }
  img:after {
    content: "";
    width: 1px;
    height: 16px;
    background: #ccc;
  }
  .findings-carousel-item-bottom-name {
    @include ellipsis(100%);
    max-width: 100px;
  }
  .findings-carousel-item-bottom-name-full {
    display: none;
  }

  .finding-group-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 55px);
  }

  .findings-navigation-item-count {
    font-size: 16px;
    font-weight: bold;
  }

  .findings-navigation-item-name {
    display: flex;
    align-items: flex-start;
    @include ellipsis(100%);
    font-size: 12px;
  }

  &.group-by-sla_status,
  &.group-by-category {
    .findings-carousel-item-bottom {
      justify-content: center;

      i {
        margin-left: 1px;
      }
    }
  }

  &.group-by-cloud_account_friendly_name {
    &.findings-carousel-item {
      background: none;

      .findings-carousel-item-top {
        position: absolute;
        left: 50%;
        top: 5px;
        transform: translateX(-50%);
      }
      .findings-carousel-item-bottom {
        position: absolute;
        top: 50px;
      }
      .findings-carousel-item-bottom-icon {
        position: absolute;
        top: -10px;
        transition: var(--transition);
      }

      &:before {
        z-index: -1;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 142px;
        height: 82px;
        background: #fff;
        transition: var(--transition);
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.06);
        border-radius: var(--border-radius-s);
      }

      .findings-carousel-item-bottom-name-full {
        display: none;
      }

      .findings-carousel-item-bottom-name {
        left: 30px;
        position: absolute;
        top: 0;
      }
      &:hover {
        transform: scale(1);
        box-shadow: none;
        .findings-carousel-item-bottom-name {
          display: none;
        }
        .findings-carousel-item-bottom-name-full {
          display: inline-block;
          word-wrap: break-word;
          width: 100px;
        }
        .findings-carousel-item-bottom-icon {
          transform: translateX(-5px);
        }
        .findings-carousel-item-bottom-name {
          position: relative;
          width: 100px;
          left: 30px;
        }
        .findings-carousel-item-bottom-name-full {
          display: inline-block;
          position: relative;
          left: 30px;
        }
        &:before {
          box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
          transform: scale(1.12);
        }
      }
    }
  }
  &.group-by-owner_email {
    align-items: center;
    justify-content: center;

    .findings-carousel-item-top {
      align-items: center;
    }

    .user-avatar {
      margin-top: 0;
    }
  }
  &.group-by-severity {
    align-items: center;
    justify-content: center;
    .sevirity-level {
      display: block;

      width: 24px;
      height: 24px;
      margin-right: 10px;
      border-radius: var(--border-radius-s);

      &.sevirity-level-nothing {
        display: none;
      }
      &.sevirity-level-critical {
        background-color: var(--critical-alert-color);
      }
      &.sevirity-level-high {
        background-color: var(--high-alert-color);
      }
      &.sevirity-level-medium {
        background-color: var(--medium-alert-color);
      }
      &.sevirity-level-low {
        background-color: var(--low-alert-color);
      }
    }
  }
}
