@import "../../constants.scss";

$medium-tag-size: 20px;
$large-tag-size: 30px;

.score-tag-wrap {
  height: $medium-tag-size;
  display: flex;
  font-size: var(--font-size-xs);
  color: #fff;
  font-weight: 500;
  position: relative;
  z-index: 1;

  .score-tag {
    position: relative;
    z-index: 1;
    text-align: center;
    line-height: $medium-tag-size;
    min-width: $medium-tag-size;
    &.tag-long {
      &:after {
        border-radius: 40px;
        width: 24px;
      }
    }
    &:after {
      content: "";
      width: $medium-tag-size;
      height: $medium-tag-size;
      display: block;
      position: absolute;
      z-index: -1;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &.critical {
      &:after {
        background-color: var(--critical-alert-color);
      }
    }
    &.high {
      &:after {
        background-color: var(--high-alert-color);
      }
    }
    &.medium {
      &:after {
        background-color: var(--medium-alert-color);
      }
    }
    &.low {
      &:after {
        background-color: var(--low-alert-color);
      }
    }
    &.resources {
      &:after {
        background-color: rgba(65, 66, 90, 0.6);
      }
    }
  }
  &.is-large {
    width: $large-tag-size;
    height: $large-tag-size;
    .score-tag {
      min-width: $large-tag-size;
      line-height: $large-tag-size;
      &:after {
        width: 36px;
        height: $large-tag-size;
      }
    }
  }
  &.is-large-width {
    width: $large-tag-size;
    height: $medium-tag-size;
    .score-tag {
      min-width: $large-tag-size;
      line-height: $medium-tag-size;
      &:after {
        width: 32px;
        height: $medium-tag-size;
      }
    }
  }
}
.score-word {
  margin-left: 10px;
  line-height: 20px;
}
