@import "../../../constants.scss";

.form-button-group {
  display: flex;
  .form-button-wrap {
    margin-right: 10px;

    &:first-child {
      margin-left: 10px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &.align-end {
    justify-content: flex-end;
  }
}
.form-button-wrap {
  position: relative;
  cursor: pointer;
  transition: var(--transition);
  display: inline-block;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex: 0 0 auto;
  //&.has-size-max {
  //  width: 100%;
  //}

  .form-button {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    height: 28px;
    padding: 0 12px;
    min-width: 80px;
    //width: 71px;
    color: var(--main-button-color);
    background-color: white;
    border: var(--main-button-border);
    font-weight: var(--font-weight-semibold);
    text-align: center;
    border-radius: var(--border-radius-m);
    font-size: 12px;
    transition: var(--transition);
    outline: none;
    // border-width: 2px;
    user-select: none;

    &:active {
      .form-button {
        opacity: 1;
      }
    }
    &:focus {
      .form-button {
      }
    }
  }
  &:hover {
    .form-button {
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
      border: var(--main-button-border--hover);
      color: var(--main-button-color--hover);
    }
    &.is-icon {
      background: transparent;
      background-color: transparent;
      i {
        background: transparent;
        background-color: transparent;
      }
      &.is-small {
        font-size: var(--font-size-xs);
      }
    }
  }
  &.is-textLink {
    .form-button {
      border: none;
      color: var(--link-color);
      font-size: var(--font-size-s);
      box-shadow: none;
      background: none;
      padding: 0;
      border-radius: 0;
      min-width: unset;

      &:hover {
        color: var(--link-color--hover);
      }
      &:focus {
        color: var(--link-color--focus);
      }
      &:active {
        color: var(--link-color--active);
      }
    }
    &.is-disabled {
      .form-button {
        border: none;
      }
    }
    &.is-in-form {
      margin-top: 18px;
      &:last-child {
        margin-bottom: 24px;
      }
    }
  }
  &.is-in-menu {
    width: calc(
      100% - var(--filter-menu-item-paddings) - var(--filter-menu-item-paddings)
    );
    margin: 0 var(--filter-menu-item-paddings) var(--filter-menu-item-paddings)
      var(--filter-menu-item-paddings);
    margin-top: 10px;
    button {
      width: 100%;
      height: 32px;
    }
  }
  &.is-icon {
    .form-button {
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.is-invert .form-button {
    color: var(--invert-button-color);
    background: var(--invert-button-background-color);
    border: var(--invert-button-border);
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;

    &:hover {
      color: var(--invert-button-color--hover);
      background: var(--invert-button-background-color--hover);
      border: var(--invert-button-border--hover);
      box-shadow: 0 0 40px 40px var(--invert-button-background-color--hover)
        inset;
    }
  }
  &.is-fullwidth {
    width: 100%;
  }
  &.has-left-icon {
    .form-button {
      padding-left: 8px;
      padding-right: 8px;
      .seem-icon {
        margin-top: 0;
        padding-right: 6px;
        font-size: var(--font-size-xs);
      }
    }
  }
  &.has-size-max {
    .form-button {
      width: 100%;
    }
  }
  &.has-no-borders {
    .form-button {
      border-color: transparent;
    }
  }
  &.has-right-icon {
    .form-button {
      padding-right: 30px;
      transition: padding-right ease-in-out 0.2s;
    }
    i.seem-icon {
      right: 6px;
      position: absolute;
      top: 6px;
    }
  }
  &.is-icon {
    .seem-icon {
      //color: var(--secondary-button-background-color);
    }
    &.is-icon-ghost {
      .form-button {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius-s);
        color: var(--icon-ghost-button-color);
      }

      &:hover {
        .form-button {
          background: var(--icon-ghost-button-background--hover);

          .seem-icon {
            color: var(--icon-ghost-button-color);
          }
        }
      }
    }
  }
  &.is-xl {
    font-size: var(--font-size-xl);
    &.is-icon {
      .form-button {
        //border-radius: 0;
        &:hover {
          box-shadow: none;
        }

        .seem-icon {
          color: var(--secondary-button-background-color);
          font-size: var(--icon-size-xl);
        }
      }
    }
  }
  &.is-disabled {
    .form-button {
      box-shadow: none;
      cursor: not-allowed;
      color: var(--main-button-color--disabled);
      border: var(--main-button-border--disabled);
    }

    &.is-textLink {
      .form-button {
        border: none;
      }
    }
  }
  &.is-xl {
    .form-button {
      font-size: var(--font-size-s);
      padding: 0 16px;
      height: 34px;
      border-radius: var(--border-radius-l);
      font-weight: 500;
    }
    &.is-icon {
      .form-button {
        padding: 0 14px 0 10px;
        .seem-icon {
          font-size: var(--icon-size-m);
          width: 18px;
        }
      }
    }
    &.has-right-icon {
      i.seem-icon {
        right: 22px;
        top: 14px;
        font-size: var(--icon-size-xl);
      }
    }
  }
  &.is-large {
    .form-button {
      font-size: var(--font-size-l);
      padding: 0 30px;
      height: 50px;
      border-radius: var(--border-radius-l);
    }
    &.is-icon {
      .form-button {
        .seem-icon {
          font-size: var(--icon-size-l);
          width: 24px;
        }
      }
    }
    &.has-right-icon {
      i.seem-icon {
        right: 22px;
        top: 14px;
        font-size: var(--icon-size-xl);
      }
    }
  }
  &.is-small {
    &.is-icon {
      .form-button {
        .seem-icon {
          font-size: var(--icon-size-xs);
          width: 14px;
          padding: 5px;
        }
      }
    }
  }
  &.is-xs {
    &.is-icon {
      .form-button {
        .seem-icon {
          font-size: var(--icon-size-xxs);
          text-align: center;
        }
      }
    }
  }
  &.is-secondary {
    .form-button {
      color: white;
      background-color: var(--secondary-button-background-color);
      border: var(--secondary-button-background-color);

      &:hover {
        background-color: var(--secondary-button-color-hover);
        border: 1px solid var(--secondary-button-border-hover);
      }
    }
    &.is-disabled {
      .form-button {
        color: var(--secondary-button-color-disabled);
        background: var(--secondary-button-background-disabled);
      }
    }
  }

  &.is-secondary-invert {
    .form-button {
      color: var(--secondary-button-background-color);
      background-color: white;
      border: 1px solid var(--secondary-button-background-color);

      &:hover {
        background-color: var(--secondary-main-hover-color);
      }
    }
  }

  &.is-danger {
    .form-button {
      color: var(--danger-button-color);
      background-color: var(--danger-button-background-color);
      border: none;

      &:hover {
        background-color: var(--danger-button-background-color-hover);
        color: var(--danger-button-color-hover);
        border: none;
      }
    }
    &.is-disabled {
      .form-button {
        color: var(--danger-button-color-disabled);
        background: var(--danger-button-background-disabled);
      }
    }
  }

  &.is-clean {
    .form-button {
      border: 0;
      width: unset;
      height: unset;
      padding: 0;
      background: transparent;
      border: 0;
      min-width: unset;
      background: transparent;
      background-color: transparent;
    }
    &:hover {
      .form-button,
      .seem-icon {
        background: transparent;
        background-color: transparent;
        box-shadow: none;
        border: 0;
        color: var(--main-button-color--hover);
        border-color: var(--main-button-color--hover);
      }
    }

    &.is-secondary {
      i {
        color: var(--secondary-button-background-color);
      }

      &:hover {
        i {
          color: var(--secondary-button-color-hover);
        }
      }
    }
  }
  &.dark-style {
    .form-button {
      width: unset;
      height: unset;
      padding: 0;
      background: transparent;
      border: 0;
      min-width: unset;
      background: transparent;
      background-color: transparent;
      color: var(--primary-main-color);
    }
    &:hover {
      .form-button,
      .seem-icon {
        background: transparent;
        background-color: transparent;
        border: 0;
        color: #a6a6a6;
      }
    }
  }
}
