.loading-blocks-wrap-carousel {
  height: 95px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding: 0 10px;
  flex: 0 0 120px;
}
.loading-block-carousel {
  width: 142px;
  height: 82px;
  background: #fff;
  position: relative;
  margin-top: 24px;
  margin-right: 17px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);

  .loading-block-square {
    left: 40px;
    top: 16px;
    width: 60px;
    height: 28px;
  }
  .loading-block-row {
    left: 30px;
    top: 52px;
    width: 80px;
    height: 14px;
  }
}
