@import "../application/src/constants";

.integration-details-instances-wrapper {
  width: 100%;

  .instances-list-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .instances-title {
      font-size: var(--font-size-l);
      color: var(--app-header-color);
      font-weight: 500;
    }
  }
}
