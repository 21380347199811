@import "../application/src/constants";

.details-summary-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--main-border-color);
  width: 100%;
  height: 105px;
  margin-bottom: 42px;
  padding-right: 16px;
  padding-left: 19px;
  border-radius: var(--border-radius-s);

  .integration-logo {
    min-width: 60px;

    img {
      height: 42px;
      padding-right: 19px;
      border-right: 1px solid var(--main-border-color);
    }
  }

  .details-summary-information {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 22px;

    .integration-name {
      color: var(--app-header-color);
      font-weight: 500;
      font-size: var(--font-size-l);
    }

    .integration-last-sync {
      color: var(--primary-main-color);
      font-size: var(--font-size-s);
    }
  }
}
