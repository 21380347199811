.dashboard-wrapper {
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 60px);

  .dashboard-controls-panel-line {
    position: absolute;
    height: 2px;
    background: #efefef;
    display: block;
    top: 117px;
    left: var(--page-padding);
    width: calc(100vw - 2 * var(--page-padding));
  }
  .dashboard-controls-panel {
    display: flex;
    justify-content: space-between;
    padding: 0 var(--page-padding);
    position: relative;
    height: 54px;
    align-items: center;

    .tabs-menu-wrapper {
      padding-top: 19px;
      width: auto;
    }

    .customize-sla-button {
      background-color: transparent;
    }
  }
}
