@import "../../../constants";
@import "../../../icons/seem-icons/style.css";

.input-wrap-datepicker {
  .input-holder {
    width: unset !important;
    cursor: pointer;
  }
  input {
    font-family: var(--font-family) !important;
  }
  .is-disabled {
    cursor: not-allowed;

    .input-holder,
    input {
      cursor: default;
      border-color: var(--input-border-color--disabled);
      color: var(--input-color--disabled);
    }

    input[type="date"]:before {
      color: #b7b7b6;
    }
  }
  &.has-clearable:not(.is-disabled) {
    input[type="date"]:before {
      right: 32px;
    }
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  font-family: "seem-icons";
  content: $seem-icon-schedule;
  /* This is the calendar icon in FontAwesome */
  width: 15px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 12px;
  color: var(--secondery-main-color);
  cursor: pointer;
  //z-index: 1111;
}
