.users-table-container {
  margin: 20px;
  overflow-y: auto;
  height: 500px;
  display: flex;
  flex-direction: column;

  .sidebar-display-area-top-button {
    align-self: end;
  }

  .users-list-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: var(--font-size-m);
    color: var(--primary-main-color);

    & > :not(:last-child) {
      margin-right: 23px;
    }
  }
}
