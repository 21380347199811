@import "../constants";

.settings-menu {
  display: flex;
  justify-content: flex-end;

  .settings-menu-option-wrapper {
    padding-right: 8px;
    margin-right: 8px;

    &.system-settings-container {
      display: flex;
      position: relative;
      cursor: pointer;

      &.header-settings-menu-active {
        opacity: 1;
        color: var(--secondery-main-color);

        .menu-underline {
          position: absolute;
          right: 5px;
          top: 33px;
          background: var(--secondery-main-color);
          height: 5px;
          border-top-left-radius: var(--border-radius-xs);
          border-top-right-radius: var(--border-radius-xs);
          opacity: 0;

          &.is-demo {
            right: -2px;
          }
        }

        .menu-underline {
          width: 40px;
          opacity: 1;
          background: var(--secondery-main-color);
        }

        .seem-icon {
          color: inherit;
        }
      }
    }

    &.user-container-option {
      margin-right: 0;
      padding-right: 0;
    }

    .settings-menu-option {
      &:hover {
        color: #b7b4bd;
        .seem-icon {
          color: #b7b4bd;
        }
      }

      .seem-icon-last-synced {
        margin-top: 4px;
      }
    }
  }

  .seem-icon-last-synced,
  .seem-icon-user {
    font-size: 20px;
    margin-right: 10px;
  }

  .seem-icon-fix {
    font-size: 16px;
    margin-right: 10px;
  }

  .user-details-container {
    display: flex;
    align-items: center;
    transition: var(--transition);
    cursor: pointer;

    .user-icon {
      margin-right: 10px;
    }

    .username-display {
      font-size: var(--font-size-s);
      font-weight: 500;
    }
  }
}
