@import "../application/src/constants";

.allowed-scopes-wrapper {
  padding-bottom: 16px;

  .allowed-scopes-tree {
    overflow-y: auto;
    height: 450px;
    max-height: 500px;
  }
}
