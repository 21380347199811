@import "../../../../../constants";

.scope-menu-item-wrapper {
  display: flex;
  margin-right: 18px;
  &.is-disabled {
    .scope-menu-item-title,
    .seem-icon {
      color: #a4a5a7;
    }
  }
  i {
    margin-right: 10px;
    color: var(--secondery-main-color);
    font-size: var(--font-size-m);
  }

  .scope-menu-item-data {
    display: flex;
    flex-direction: column;

    .scope-menu-item-title {
      margin-bottom: 7px;
    }

    .scope-menu-item-sub-title {
      font-size: var(--font-size-xs);
      color: var(--findings-header-color);
      line-height: 1.4;
    }
  }
}
