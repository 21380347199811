.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input {
  width: 80px;
}
.custom-file-input::before {
  content: "+ Add";
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  height: 28px;
  padding: 0 12px;
  min-width: 80px;
  width: 80px;
  //width: 71px;
  color: white;
  background-color: var(--secondary-button-background-color);
  border: var(--secondary-button-background-color);
  font-weight: var(--font-weight-semibold);
  text-align: center;
  border-radius: var(--border-radius-m);
  font-size: 12px;
  transition: var(--transition);
  outline: none;
  // border-width: 2px;
  user-select: none;
}

.custom-file-input:hover::before {
  background-color: var(--secondary-button-color-hover);
  border: 1px solid var(--secondary-button-border-hover);
}
