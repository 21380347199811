@import "../../constants.scss";

$link-height: 34px;

.links-menu-wrapper {
  border: 1px solid var(--main-border-color);
  //width: 100%;

  //height: 34px;
  border-radius: var(--border-radius-l);
  display: flex;
  align-items: center;
  //padding: 0 20px;
  // padding-bottom: 20px;
  box-shadow: var(--box-shadow);
  flex-flow: row wrap;
  overflow: hidden;

  .link-menu-item {
    padding: 0 10px;
    cursor: pointer;
    position: relative;
    font-size: var(--font-size-s);
    white-space: nowrap;
    border-right: 1px solid var(--main-border-color);
    color: var(--primary-text-color);

    &:last-child {
      border-right: 0;
    }
    &.disabled {
      .link-menu-item-text {
        color: var(--input-color--disabled);
      }
      &:hover {
        background: none;
        cursor: default;
      }
    }

    &:hover {
      background: #f1f3fe;
    }

    &:first-child {
      border-top-left-radius: var(--border-radius-l);
      border-bottom-left-radius: var(--border-radius-l);
    }
    .link-menu-item-text {
      user-select: none;
      font-weight: 500;
      font-size: var(--font-size-xs);
      //height: 34px;
      //line-height: 34px;
      padding-right: 2px;
      padding-left: 2px;
      height: $link-height;
      line-height: $link-height;
      position: relative;
      display: inline-block;

      &:after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 2px;
        width: 100%;
        background: transparent;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    //&:last-child {
    //  &:after {
    //    display: none;
    //  }
    //}
    //
    //&:after {
    //  content: '';
    //  position: absolute;
    //  right: -20px;
    //  height: 34px;
    //  width: 1px;
    //  background: var(--main-border-color);
    //}
  }

  .link-menu-item--active {
    .link-menu-item-text {
      //border-bottom: 2px solid var(--secondery-main-color);
      color: var(--secondery-main-color);
      &:after {
        background-color: var(--secondery-main-color);
      }
    }
  }
}

@media screen and (max-width: 1610px) {
  .links-menu-wrapper .link-menu-item {
    padding: 0 4px;
  }
}
