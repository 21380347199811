@import "../../../constants.scss";

.sla-status-title-wrap {
  @include ellipsis(100%);
}

.sla-status-icon-container {
  width: 20px;
  flex: 0 0 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebebeb;
  &.is-large {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
  }

  i {
    font-size: var(--font-size-xs);
  }

  .seem-icon.seem-icon-in-time {
    margin-top: -1px;
  }

  &.sla-in_time {
    color: var(--finding-sla-in-time-color);
  }

  &.sla-due_soon {
    color: var(--finding-sla-due-soon-color);
  }

  &.sla-overdue {
    border: 1px solid var(--finding-sla-overdue-color);
    color: var(--finding-sla-overdue-color);

    i {
      font-size: 8px;
    }
  }
}
