@import "../../../constants";

.data-source-wrap {
  display: flex;
  align-items: center;

  img {
    height: var(--icon-size-xl);
    width: var(--icon-size-xl);
  }

  .finding-in-data-src-wrapper {
    display: flex;
    align-items: center;

    .id-in-datasource {
      text-decoration: none;
      margin: 0 5px;
      @include ellipsis(120px);

      &.is-disabled {
        color: inherit;
        &:active {
          pointer-events: none;
        }
      }
    }

    .id-in-datasrc-copy {
      font-size: var(--font-size-s);
      cursor: pointer;
    }
  }

  .datasource-original-score {
    margin-left: 5px;
  }
}

.data-source-wrap.is-hidden {
  display: none;
}
.is-full-screen {
  .data-source-wrap .finding-in-data-src-wrapper .id-in-datasource {
    max-width: 200px;
  }
}
