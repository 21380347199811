@import "../application/src/constants";

.ts-instance-wrapper {
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  border: 1px solid var(--main-border-color);
  border-radius: var(--border-radius-s);
  color: var(--primary-main-color);
  margin-bottom: 8px;
  border-right: 1px solid var(--main-border-color);

  & > :not(:last-child) {
    border-right: 1px solid var(--main-border-color);
  }

  .ts-instance-cell {
    height: 37px;
    display: flex;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;
  }

  .ts-instance-name {
    font-weight: 500;
    width: 240px;
    @include ellipsis(100%);
    flex-shrink: 0;
    height: auto;
  }

  .ts-instance-sync {
    width: 100%;
    justify-content: space-between;
  }
}
