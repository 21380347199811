@import "../../../../constants";

.add-comment-form-wrapper {
  display: flex;
  flex-direction: column;

  .add-comment-form-controls {
    display: flex;
    flex-direction: row;
    .user-avatar {
      //margin-top: 40px;
      margin-right: 8px;
    }
    .text-editor-area {
      width: 100%;
    }
  }

  .add-comment-btn {
    align-self: flex-end;
    margin-left: 8px;
  }

  .cancel-btn {
    align-self: flex-end;
    margin-left: 10px;
  }

  .push-comments-to-jira-checkbox {
    margin-bottom: 0;
  }
}
