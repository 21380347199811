@import "../../../constants";

.performances-widget-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  height: calc(100% - 30px);

  &.no-data {
    height: 100%;
  }

  .performance-icon-container {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: var(--jira-done-color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: 20px;

    &.is-empty {
      background-color: rgba(65, 66, 90, 0.2);

      i {
        font-size: 20px;
      }
    }

    i {
      font-size: 24px;
    }
  }

  .no-performance {
    font-size: var(--font-size-l);
    font-weight: 400;
  }

  .performance-data {
    font-weight: 500;
    font-size: var(--font-size-xxl);
    margin-bottom: 10px;
  }

  .performance-statistics {
    &.performance-up {
      color: var(--jira-done-color);
    }

    &.performance-no_change {
      color: var(--primary-main-color);
      opacity: 0.3;
    }

    &.performance-down {
      color: #eb2a45;
    }
    font-weight: 500;

    i {
      font-size: var(--font-size-m);
      margin-right: 5px;
    }
  }
}
