@import "../../constants";

.sidebar-wrapper {
  background-color: white;
  width: 318px;
  height: 100%;
  padding-left: 24px;
  padding-top: 24px;
  box-shadow: var(--widget-box-shadow);
  flex: 0 1 318px;

  .sidebar-title {
    font-size: var(--font-size-l);
    font-weight: 500;
    margin-bottom: 33px;
    color: black;
  }

  .sidebar-menu {
    & > :not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .sidebar-wrapper {
    width: 260px;
    flex: 0 1 260px;
  }
}
