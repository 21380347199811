@import "../../../constants.scss";

.filters-menu-header {
  .filters-menu-button-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .filters-menu-title {
    font-size: var(--font-size-m);
    display: inline-flex;
    color: var(--primary-text-color);
    font-weight: 600;
    margin-top: 14px;
  }
  .dropdown-loading-indicator {
    padding-top: 1px;
    margin-top: 7px;
    left: 0;
  }
}
.filters-menu-items {
  margin-top: 10px;
  margin-right: 14px;
  .select__menu-notice--no-options {
    height: 60px;
    text-align: left;
  }
}

.filter-panel-selection-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .filter-panel-option-name {
    background-color: #f9f9fb;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-radius: var(--border-radius-s);
    font-size: var(--font-size-s);

    &:hover {
      background-color: var(--secondary-main-hover-color);
    }
  }

  .filter-option-edit {
    width: 60px;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    margin-left: 10px;
    background-color: #f9f9fb;
    border-radius: var(--border-radius-s);

    &:hover {
      background-color: var(--secondary-main-hover-color);
    }
  }
  .filter-panel-option-edit {
  }
}
