@import "../application/src/constants";

.profiles-wrapper {
  width: 100%;
  padding-bottom: 16px;

  &.is-loading,
  &.is-error {
    height: 450px;
  }

  &.is-error {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
  }
}
