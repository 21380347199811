@import "../../../../../constants";

.activity-item {
  // width: 449px;
  height: 51px;
  border-radius: 4px;
  background-color: #f9f9fb;
  font-size: 14px;
  color: #212b36;
  margin-bottom: 5px;
  padding: 0 12px;
  align-items: center;
  width: 100%;
}

.activity-log-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.activity-log-data {
  display: flex;
  align-items: center;
  font-size: var(--font-size-xs);
  .ticket-url-wrap {
    width: 95px;
  }
  .ticket-url {
    margin-top: 0;
    padding-right: 10px;
    @include ellipsis(100px);
  }
  .jira-states {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .seem-icon-next-arrow {
      font-size: 8px;
      margin-left: 3px;
      margin-right: 3px;
    }

    .jira-status-icon-container {
      width: 24px;
      height: 20px;

      .seem-icon {
        font-size: var(--font-size-xs);
      }
    }
  }
}

.activity-log-time {
  font-size: var(--font-size-xxs);
  color: var(--findings-header-color);
}
