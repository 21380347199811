@import "../../../constants.scss";

.rmq-node-wrapper {
  display: flex;
  height: 144px;

  .add-queue-to-flow {
    display: flex;
    justify-content: center;
    align-items: center;

    .add-queue-connector {
      height: 1px;
      background-color: #e9e9e9;
      width: 25px;
    }

    .add-queue-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      background-color: white;
      border: 1px solid #e9e9e9;
      border-radius: 25px;
      color: var(--secondery-main-color);
      font-size: var(--font-size-xs);
    }
  }

  .rmq-node {
    background: #ffffff;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    width: 282px;
    display: flex;

    .rmq-node-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 5px;
      height: 40px;

      &.nodrag {
        cursor: default;
      }
      &:hover {
        .rmq-node-operations {
          visibility: visible;
          opacity: 1;
        }
      }

      .rmq-node-operations {
        opacity: 0;
        @include animate-opacity;
      }
    }

    .separator {
      height: 1px;
      background-color: #e9e9e9;
    }

    .separator-vertical {
      width: 1px;
      background-color: #e9e9e9;
      // margin: 5px 0;
    }

    .rmq-node-data {
      width: 100%;
      .rmq-node-item-span {
        @include ellipsis(100%);
        max-width: calc(100% - 45px);
        line-height: 12px;
      }
    }

    .target-handle,
    .source-handle {
      border: none;
      background-color: transparent;
    }

    .output {
      width: 44px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .handle-container {
        display: flex;
        align-items: center;

        .jira-status-icon-container {
          border-radius: 50%;
          width: 24px;
          height: 24px;

          i {
            font-size: var(--font-size-xs);
          }
        }
      }
    }
  }
}

.edge {
  .react-flow__edge-path {
    stroke-width: 2px;
    // marker-end: url("#hell");
  }
  &.rejected {
    .react-flow__edge-path {
      stroke: var(--jira-rejected-color) !important;
    }
  }

  &.done {
    .react-flow__edge-path {
      stroke: var(--jira-done-color) !important;
    }
  }

  &.false_positive {
    .react-flow__edge-path {
      stroke: var(--finding-flase-positive-color) !important;
    }
  }

  &.exception {
    .react-flow__edge-path {
      stroke: var(--finding-risk-accepted-color) !important;
    }
  }

  &.open_confirmed {
    .react-flow__edge-path {
      stroke: var(--finding-open-confirmed) !important;
    }
  }
}

.rmq-node-data {
  font-weight: 500;
  font-size: var(--font-size-xs);

  .rmq-node-item-icon {
    font-size: var(--icon-size-xs);
    color: var(--secondery-main-color);
    width: 14px;
    margin-right: 6px;
  }

  .rmq-node-items {
    height: 62px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
  }

  .rmq-node-item {
    display: flex;
    padding-bottom: 10px;
  }

  .rmq-name {
    @include ellipsis(#{calc(100% - 64px)});
    font-size: var(--font-size-s);
    padding-left: 15px;
    cursor: pointer;
    &:hover {
      color: var(--secondery-main-color);
    }
  }

  .rmq-node-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    .rmq-node-action-info {
      display: flex;
      align-items: center;
      height: 40px;

      .rmq-node-action-icon {
        height: 24px;
        margin-right: 5px;
        display: flex;
        align-items: center;
      }
    }

    .rmq-status {
      padding-right: 20px;
      position: relative;

      .seem-icon-synced {
        color: var(--secondery-main-color);
      }

      .seem-icon-not-synced {
        color: #bcbcc6;
      }
    }
  }
}

.badge-icon-container {
  &.exception {
    background-color: var(--finding-risk-accepted-color);
  }

  &.false_positive {
    background-color: var(--finding-flase-positive-color);
  }

  &.open_confirmed {
    background-color: var(--finding-open-confirmed);
  }
}
