.label-indicator-wrapper {
  //width: 87px;
  height: 25px;
  border-radius: 15px;
  background-color: #f9f9fb;
  display: flex;
  align-items: center;
  padding: 0 7px;

  .label-indicator-icon {
    font-size: var(--font-size-m);
    margin-right: 4px;
  }

  .label-text {
    font-size: var(--font-size-xxs);
    color: var(--primary-text-color);
    font-weight: 500;
    padding-top: 3px;
    white-space: nowrap;
  }
}
