@import "../application/src/constants";

.permissions-list-wrapper {
  .permissions-list-item-wrapper {
    font-size: var(--font-size-xs);

    .permissions-list-cell {
      &.profile-details {
        width: 214px;
        line-height: 20px;
        margin-top: 2px;

        .details-name {
          font-size: var(--font-size-s);
          font-weight: 500;
          margin-bottom: 8px;
        }

        .details-update {
          &.no-data {
            opacity: 0.6;
          }
        }
      }

      &.profile-scopes {
        font-weight: 500;
        width: 532px;
        line-height: 20px;

        @media screen and (max-width: 1440px) {
          width: 320px;
        }

        .no-data {
          color: #8d8e9c;
          font-weight: 400;
        }

        .profile-scopes-title {
          margin-bottom: 3px;
        }

        .profile-scopes-list {
          display: flex;
          align-items: center;
          color: var(--secondery-main-color);

          .seem-icon-more-actions {
            margin-left: 6px;
            cursor: pointer;
          }
        }
      }

      &.profile-users-and-actions {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;

        .users {
          display: flex;
          height: min-content;

          .no-data {
            color: #8d8e9c;
          }

          .users-title {
            font-weight: 500;
            width: 76px;
          }

          .users-list {
            display: flex;
          }
        }

        .actions {
          & > :not(:first-child) {
            margin-left: 13px;
          }
        }
      }
    }
  }
}

.popover-container {
  background-color: white;
  width: 318px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid var(--main-border-color);
  border-radius: var(--border-radius-s);
  font-size: var(--font-size-xs);
}

.scopes-popover-container {
  height: 77px;
  padding-left: 6px;
  padding-right: 6px;
}

.delete-profile {
  font-size: var(--font-size-s);
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  line-height: 20.5px;

  .delete-profile-users-description {
    text-align: left;
  }

  .delete-profile-users-title {
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .delete-profile-connected-users {
    width: 358px;
    height: 157px;
    overflow-y: auto;
    border: 1px solid #e2e4ec;
    padding: 16px;
    margin-bottom: 24px;
    border-radius: var(--border-radius-s);
  }

  .delete-profile-close {
    margin-bottom: 35px;
    align-self: center;
  }
}
