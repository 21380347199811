.customer-provisioning-wrapper {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      color: var(--title-color);
      font-size: var(--font-size-xl);
      font-weight: 600;
    }
  }
}
