@import "../../../constants";

.input-wrap-tags {
  &.has-max-height {
    .input-holder {
      overflow: auto;
    }
  }

  .input-holder {
    width: auto;
    height: auto !important;
    padding: 0;
    display: flex;
    align-items: flex-start;
  }
}

.input-wrap-tags.has-error {
  border-color: var(--input-error-color);
  cursor: pointer;
}

.tags-select {
  position: relative;
  padding: 3px 50px 0 6px;
  line-height: 1.2;
  cursor: text;
  width: 100%;
  min-height: 36px;

  &.is-loading {
    .loading-placeholder {
      padding: 4px;
    }
  }

  .dropdown-loading-indicator {
    opacity: 0;
    display: flex;
    align-self: center;
    font-size: 4px;
    line-height: 1;
    margin-right: 4px;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
    color: var(--input-color--disabled);
    padding: 0;
    position: absolute;
    right: 38px;
    width: 18px;
    justify-content: space-between;
    transition: opacity ease-in-out 0.15s, color ease-in-out 0.15s;

    .loading-dot {
      animation: loading-dots 1s ease-in-out 0ms infinite;
      background-color: currentColor;
      border-radius: 5px;
      display: inline-block;
      height: 4px;
      vertical-align: top;
      width: 4px;
      &:nth-child(2) {
        animation: loading-dots 1s ease-in-out 160ms infinite;
      }
      &:nth-child(3) {
        animation: loading-dots 1s ease-in-out 320ms infinite;
      }
    }
  }

  &.is-loading {
    display: flex;
    align-items: center;
    color: var(--input-color--disabled);
    .dropdown-loading-indicator {
      opacity: 1;
    }
  }

  .tags-select__selected {
    display: inline;
  }

  .tags-select__search {
    display: inline-flex;

    /* match tag layout */
    padding: 4px;

    /* prevent autoresize overflowing the container */
    // width: 100%;
    max-width: 100%;

    .tags-select__search-input {
      /* prevent autoresize overflowing the container */
      width: 100%;
      max-width: 100%;
      min-width: 80px;

      /* remove styles and layout from this element */
      margin: 0;
      padding: 0;
      border: 0;
      outline: none;

      /* match the font styles */
      font-size: inherit;
      line-height: inherit;

      input {
        width: 160px;
        &::placeholder {
          color: var(--input-placeholder-color);
          opacity: 1;
        }
      }
    }
  }
}
