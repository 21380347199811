@import "../../../../constants";

.modal-change-status {
  .modal-content {
    display: flex;
    height: 100%;
    flex-flow: column nowrap;
  }
  .modal-buttons-container {
    align-items: flex-end;
    padding-bottom: 35px;
    height: unset;
    flex: 1 0 auto;
  }
  .mce-content-body {
    height: 120px;
    &.mce-edit-focus {
      height: 82px;
    }
  }
}
.change-status-reason-wrap {
  padding-bottom: 20px;
  height: 174px;
}
.status-controller {
  padding-left: 42px;
  margin-bottom: 15px;
}

.form-row {
  flex: 0 1 auto;
}

.change-status-reason {
  display: flex;
  flex: 0 1 auto;
  .text-editor-area {
    width: 100%;
  }
  .modal-buttons-container {
    flex: 1 0 auto;
  }
}

.change-status-wrapper {
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  color: var(--primary-text-color);
  font-size: var(--font-size-s);
}
.change-status-form {
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 auto;

  .change-status-count-indicator-wrapper {
    margin-top: 16px;
    margin-bottom: 17px;

    .rmq-sla-prioritize-info {
      font-weight: 400;
    }

    .findings-count-to-change {
      font-weight: 500;
      color: var(--primary-text-color);
    }

    .change-status-indicator-text {
      color: var(--primary-text-color);
    }
  }
}
.status-menu {
  position: relative !important;
  display: block;
  z-index: 999999999999;

  .select__menu {
    position: relative !important;
    z-index: 999999999999;

    .select__menu-list {
      position: relative !important;
      z-index: 999999999999;
    }
  }
  .select__option {
    width: calc(100% - 16px);
    margin-left: 8px;
    border-radius: var(--border-radius-xs);
  }
}

.user-change-status-comment {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
}

.actual-status {
  width: 130px;
}

.due-date-options {
  .radio-button-input-wrap {
    height: 32px;
  }
}
