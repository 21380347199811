@import "../../../constants";

.settings-panel-wrapper {
  display: flex;
  width: 100%;
  max-height: 610px;
}

//responsive
@media screen and (max-width: 1439px) {
  .settings-panel-wrapper {
    max-height: unset;
  }
}
