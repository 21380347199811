.Toastify {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
  .Toastify__toast-body {
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--primary-text-color);
    font-family: var(--font-family);
    font-weight: 500;
    padding: 0;
  }

  .Toastify__progress-bar--default {
    background: var(--secondery-main-color) !important;
  }

  .toast-box-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .seema-wrap {
      width: 100px;
      height: 80px;
      position: relative;
      &.is-large {
        height: 50px;
        transform: scale(1.25);
      }
    }
  }

  .unsaved-filter {
    font-size: var(--font-size-s);
    padding: 16px;
    border-left: 5px solid var(--secondery-main-color);
    border-radius: var(--border-radius-s);

    .seem-icon {
      font-size: var(--font-size-s);
      margin-right: 12px;
      color: var(--secondery-main-color);
    }

    .Toastify__toast-body {
      color: var(--primary-main-color);
      font-weight: 500;
    }

    .Toastify__close-button--default {
      color: var(--secondery-main-color);
      opacity: 1;
    }

    .unsaved-toast-wrapper {
      width: 100%;
      position: relative;

      .unsaved-toast-offset {
        background-color: var(--secondery-main-color);
        width: 100%;
        transform: translateX(2px);
        border: none;
      }

      .unsaved-toast-data {
        display: flex;

        .unsaved-toast-sub-title {
          font-weight: 400;
          margin-top: 12px;
        }

        .form-button-wrap {
          margin-top: 12px;
        }
      }
    }
  }
}
