@import "../../constants";

$header-height: 100px;
$header-height-no-filters: 48px;

.table-wrapper {
  font-size: var(--font-size-l);
  color: var(--primary-text-color);
  font-weight: 600;
  width: 100%;
  background: #fff;
  border-radius: var(--border-radius-s);
  box-shadow: var(--box-shadow);
  padding: 0;
  border-width: 0;
  border-collapse: collapse;

  .thead {
    .tr {
      display: flex;
      border-bottom: none;
      position: relative;
      height: $header-height;

      &.--no-filters {
        border-bottom: 1px solid var(--table-border-color);
        height: $header-height-no-filters;

        .sort-direction-indicator {
          padding: 0 5px 0 5px;
        }

        .th {
          padding: 0;
        }
      }

      &:hover {
        background: var(--main-background-color);
        .column-header-title-text {
          color: var(--primary-text-color);
        }
      }
    }

    .th-filter-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      flex: 1 1 100%;

      .input-wrap {
        margin-bottom: 0;
        .input-holder {
          margin-bottom: 0;
          height: 38px;
        }
      }

      .input-wrap-select.is-multi {
        .select__control {
          padding: 0 7px;
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
        }
      }

      .table-filter-icon {
        margin-left: 5px;
      }
    }
    .th {
      position: relative;
      padding: 8px 10px 10px 10px;
      margin: 10px 0;
      align-items: flex-start;
      text-align: left;
      @include table-header-cell;
      display: flex;
      flex-flow: row wrap;
      flex-flow: column nowrap;
      justify-content: flex-start;

      .multi-select-item-selected,
      .multi-select-items-count,
      .seem-icon-dropdown-style
        .form-button-wrap.is-icon
        .seem-icon-dropdown-arrow {
        color: var(--table-header-color);
      }
      .select__control--is-focused {
        border-color: #acadaf;
      }
      .seem-icon-table-filter {
        margin-right: 5px;
        color: #acadaf;
        font-size: 14px;
      }
      &:first-child {
        padding-left: 55px;
      }
      &.is-sortable {
        .sort-direction-indicator {
          opacity: 0;
          transition: opacity ease-in-out 0.15s;
        }

        &.is-sorted {
          .sort-direction-indicator {
            opacity: 1;
            transition: opacity ease-in-out 0.15s;
          }
        }
        &:hover {
          .sort-direction-indicator {
            opacity: 1;
          }
        }
      }
    }

    .column-header-title {
      display: flex;
      height: 19px;
      line-height: 19px;
      flex: 1 1 100%;
      overflow: hidden;
      width: 100%;
    }
    .column-header-title-text {
      @include ellipsis(100%);
      @include table-header-cell;
    }
    .sort-direction-indicator {
      margin-left: 5px;
      padding: 5px 5px 0 5px;
      .seem-icon {
        color: #c6c6cd;
        font-size: var(--icon-size-s);
      }
    }
  }
  .tbody {
    .tr {
      position: relative;
      display: flex;
      background-color: var(--table-row-color);
      transition: background-color ease-in-out 0.15s;
      border-bottom: 1px solid var(--table-border-color);
      .jira-status-container {
        height: 32px;
      }

      &:hover {
        background-color: var(--table-row-color-hover);
        cursor: pointer;
      }
      &-subrow {
        background-color: var(--table-row-color-hover);

        td {
          font-weight: 400;
        }

        &:hover {
          background-color: var(--table-sub-row-color-hover);
        }
      }
    }

    .td:not(:last-child) {
      //border-right: 1px solid var(--table-border-color);
    }

    .td {
      position: relative;
      margin-top: 14px;
      margin-bottom: 13px;
      padding: 0 10px;

      @include table-cell;
      &:first-child {
        padding-left: 55px;
      }
      .loading-row {
        width: 80%;
        height: 20px;
      }
    }
  }

  &.secondary {
    box-shadow: none;

    .thead {
      .tr {
        padding-left: 15px;
        &:hover {
          background-color: transparent;
        }
      }
      .th {
        border-right: none;
      }
    }

    .tbody {
      .td:first-child {
        padding-left: 18px;
      }
      .tr:after {
        display: none;
      }

      .tr:hover {
        cursor: default;
      }
    }
  }
}

.table-wrap-loading {
  height: calc(100% - 75px);
}
