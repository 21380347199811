.App {
  &.monitoring {
    overflow: auto;
  }
}
.monitoring-wrapper {
  .td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
