@import "../../constants.scss";

.manual-finding-form {
  .score-date-container {
    display: flex;
  }

  .manual-finding-form-score-wrap {
    width: 70px;
  }
  .manual-finding-form-date-wrap {
    width: 178px;
  }

  .row-status {
    width: 100%;
    margin-top: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--link-color);
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semibold);

    i {
      margin-right: 5px;
    }
  }
}
