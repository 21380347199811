@import "../../../../../constants";

.overview-item {
  display: flex;
  flex-direction: column;
  height: 56px;
  width: 130px;
  border-left: 1px solid #e1e1e2;
  padding-left: 15px;

  &:not(.overview-status-chart) {
    .overview-item-value {
      @include ellipsis(100%);
    }
  }
  .overview-item-title {
    margin-bottom: 3px;
    @include mini-label;
  }

  .overview-item-value {
    font-size: 14px;
    font-weight: 500;
    color: #212b36;
  }
}
