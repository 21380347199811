.saml-instructions-wrapper {
  margin-bottom: 36px;
  .saml-instructions-editor {
    & {
      margin-top: 12px;
    }

    .mce-content-body {
      height: 100% !important;
      padding: 24px;
      background: var(--main-background-color) !important;

      div:first-child {
        width: 100% !important;
      }
    }
  }
}
