$icomoon-font-family: "seem-icons" !default;
$icomoon-font-path: "fonts" !default;

$seem-icon-change-score: "\e962";
$seem-icon-aggregated: "\e961";
$seem-icon-circle-plus: "\e95f";
$seem-icon-circle-minus: "\e960";
$seem-icon-new-actions: "\e95e";
$seem-icon-create-ticket: "\e95a";
$seem-icon-manualfinding: "\e95b";
$seem-icon-change-status: "\e95c";
$seem-icon-bulk-upload: "\e95d";
$seem-icon-checkbox-indeterminate: "\e959";
$seem-icon-open-confirmed: "\e917";
$seem-icon-browse: "\e958";
$seem-icon-scope-group: "\e957";
$seem-icon-exit-scope: "\e956";
$seem-icon-filter-recommended: "\e952";
$seem-icon-filter-saved: "\e953";
$seem-icon-filter-unsaved: "\e954";
$seem-icon-root: "\e955";
$seem-icon-add-user: "\e951";
$seem-icon-edit: "\e94a";
$seem-icon-risk-accepted: "\e948";
$seem-icon-false-positive: "\e949";
$seem-icon-info: "\e945";
$seem-icon-in-time: "\e946";
$seem-icon-overdue: "\e947";
$seem-icon-performance-down: "\e943";
$seem-icon-performance-up: "\e944";
$seem-icon-icon-resources-filled: "\e942";
$seem-icon-notebook: "\e93f";
$seem-icon-last-synced: "\e940";
$seem-icon-seemplicity-mark: "\e941";
$seem-icon-warning: "\e93e";
$seem-icon-download: "\e93d";
$seem-icon-link: "\e93b";
$seem-icon-root_old: "\e93a";
$seem-icon-scope: "\e938";
$seem-icon-scope-group_old: "\e939";
$seem-icon-search: "\e936";
$seem-icon-table-filter: "\e933";
$seem-icon-copy: "\e932";
$seem-icon-comment: "\e92f";
$seem-icon-new-ticket: "\e931";
$seem-icon-checkmark: "\e91b";
$seem-icon-slash: "\e91c";
$seem-icon-expand: "\e91f";
$seem-icon-left-arrow: "\e92b";
$seem-icon-right-arrow: "\e92c";
$seem-icon-close: "\e92d";
$seem-icon-scheduled: "\e92e";
$seem-icon-open: "\e930";
$seem-icon-checkbox-unchecked: "\e916";
$seem-icon-checkbox-checked: "\e915";
$seem-icon-alert: "\e929";
$seem-icon-dot-circle: "\e91e";
$seem-icon-help: "\e918";
$seem-icon-lock: "\e919";
$seem-icon-private: "\e91a";
$seem-icon-public: "\e91d";
$seem-icon-plus: "\e900";
$seem-icon-radio-unchecked: "\2022";
$seem-icon-add-comment: "\e901";
$seem-icon-fix: "\e902";
$seem-icon-close1: "\e903";
$seem-icon-ignore: "\e904";
$seem-icon-jira: "\e905";
$seem-icon-snooze: "\e906";
$seem-icon-target-date: "\e907";
$seem-icon-more-actions: "\e908";
$seem-icon-up-arrow: "\e909";
$seem-icon-back-arrow: "\e90a";
$seem-icon-next-arrow: "\e90b";
$seem-icon-jira-icon-backlog: "\e90c";
$seem-icon-jira-icon-done: "\e90d";
$seem-icon-jira-icon-in-progress: "\e90e";
$seem-icon-jira-icon-not-in: "\e90f";
$seem-icon-dashboard: "\e910";
$seem-icon-findings: "\e911";
$seem-icon-jira-icon-scheduled: "\e912";
$seem-icon-remed: "\e913";
$seem-icon-bin: "\e914";
$seem-icon-spinner: "\e981";
$seem-icon-chevron-down: "\e927";
$seem-icon-chevron-up: "\e928";
$seem-icon-filter: "\e921";
$seem-icon-line-plus: "\e922";
$seem-icon-icon-menu: "\e923";
$seem-icon-icon-plus: "\e925";
$seem-icon-icon-resources: "\e926";
$seem-icon-eye: "\e9ce";
$seem-icon-user: "\e92a";
$seem-icon-not-synced: "\e935";
$seem-icon-synced: "\e937";
$seem-icon-schedule: "\e934";
$seem-icon-down-arrow: "\e924";
$seem-icon-pause: "\ea16";
$seem-icon-dropdown-arrow: "\e920";
$seem-icon-x-small: "\e93c";
$seem-icon-connections: "\e94c";
$seem-icon-shield: "\e94d";
$seem-icon-key: "\e94e";
$seem-icon-account: "\e94b";
$seem-icon-bin-2: "\e94f";
$seem-icon-refresh: "\e950";
