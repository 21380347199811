@import "../../../constants";

.details-pane-header-container {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-s);
  justify-content: flex-start;
  font-weight: 500;
  align-items: center;
  margin-bottom: 20px;

  .details-pane-header-top-components,
  .details-pane-header-bottom-components {
    display: flex;

    & > :not(:last-child) {
      padding-left: 14px;
      padding-right: 12px;
      border-right: 1px solid var(--table-border-color);
    }

    & > :first-child {
      padding-left: 0px;
    }

    & > :last-child {
      padding-left: 14px;
    }
  }

  .details-pane-header-top-components {
    align-items: center;
  }

  .details-pane-header-bottom-components {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .details-pane-header-top-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .details-pane-header-title {
    font-size: var(--font-size-xxl);
    padding-bottom: 10px;
    font-weight: 500;
    color: var(--details-pane-main-titles-color);
    align-self: flex-start;
    margin-top: 16px;
    word-break: break-word;
  }
}
