@import "../../../constants.scss";

//checkbox inside select
.input-wrap-select {
  //display: flex;
  //align-items: center;
  .checkbox-input + label:before,
  .checkbox-input + label:after {
    top: 0;
  }

  .select__value-container,
  .select__placeholder,
  .select__option {
    font-size: var(--input-font-size);
    padding: 0;
  }
  .select__placeholder {
    padding: 0;
    @include ellipsis(100%);
  }
  .select__control {
    //padding: 2px 10px 1px 10px;
    //cursor: pointer;
    //border-radius: var(--input-border-radius);
    &:hover {
      //color: var(--input-color--hover);
      //box-shadow: var(--input-box-shadow);
      .select__single-value {
        color: var(--input-color--hover);
      }
    }
    &.select__control--is-focused {
      border: var(--input-border--focused);
    }
  }
  .select__value-container {
    flex-flow: row nowrap;
    .single-value-wrapper {
      display: flex;

      &.has-icon {
        .select__single-value {
          margin-left: 40px;
        }
      }

      &.has-image {
        .select-image-value {
          height: 20px;
          width: 20px;
        }
        .select__single-value {
          margin-left: 25px;
        }
      }
    }

    .select-icon-value {
      @include tag-shape(
        var(--secondery-main-color),
        var(--secondary-text-color)
      );
      padding: 0;
      line-height: 28px;
      height: 28px;
      width: 28px;
    }
  }
  .select__placeholder {
    color: var(--input-placeholder-color);
  }
  &.is-multi {
    .select__control {
      padding: 4px 10px;
      .select__multi-value {
        margin-right: 2px;
        flex: 0 1 auto;
      }
    }
  }
  &.is-round {
    .select__control {
      border-radius: var(--border-radius-l);
    }
  }
  &.is-disabled {
    .select__placeholder {
      color: var(--input-color--disabled);
    }
    .select-input-holder-wrap {
      cursor: not-allowed;
    }
  }
}
//Style the menu which is in the popover
.select-input-menu {
  .select__option {
    cursor: pointer;
    transition: var(--transition);
    font-size: var(--input-font-size);
    height: 34px;
    line-height: 21px;
    @include ellipsis(100%);

    .select-option-icon {
      @include tag-shape(tranparent, var(--primary-text-color));
      padding: 0;
      width: 28px;
      height: 28px;
      line-height: 28px;
    }
    &:hover {
      background-color: var(--secondary-main-hover-color);

      .select-option-with-icon {
        .select-option-icon {
          @include tag-shape(white, var(--primary-text-color));
          padding: 0;
          line-height: 28px;
          width: 28px;
          height: 28px;
        }
      }
    }

    &.select__option--is-selected {
      background-color: var(--secondary-main-hover-color);

      .select-option-with-icon {
        .select-option-icon {
          @include tag-shape(
            var(--secondery-main-color),
            var(--secondary-text-color)
          );
          padding: 0;
          width: 28px;
          height: 28px;
          line-height: 28px;
        }
      }
    }

    .select-option-with-icon {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      .select-option-with-icon-label {
        font-size: var(--font-size-xs);
        font-weight: 500;
        margin-left: 5px;
      }
    }
  }
}
.multi-select-item-selected,
.multi-select-items-count {
  color: var(--input-color);
  font-weight: 600;
  font-size: var(--font-size-xs);
  @include ellipsis(100%);
}

.select__menu {
  @include animate-fade-in(0.1s);
  box-shadow: var(--menu-box-shadow);
  transition: box-shadow ease-in-out 0.15s;
  .select__menu-list {
    z-index: 999;
    padding-bottom: 0;
    max-height: 180px;
  }
}
.select__menu-notice {
  font-size: var(--input-font-size);
  line-height: 21px;
}
.select__menu-list--is-multi {
  .select__option {
    height: auto;
    display: flex;
    align-items: center;
    line-height: 16px;
    img {
      width: 20px;
      margin-left: 10px;
    }
    i {
      margin-left: 10px;
    }
    .checkbox-input-wrap {
      width: 100%;
      margin-bottom: 0;
    }
  }
  .select__option {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.select__value-container--is-multi {
  flex-wrap: nowrap !important;
  div.select__multi-value {
    margin: 1px;
  }
}
.select__menu {
  left: 1px;
  width: calc(100% - 2px);
}
.input-wrap-select.has-read-only {
  .select__control {
    background-color: #fff;
    cursor: default;
  }
}
.input-wrap-select.has-error {
  .select__control {
    border-color: var(--input-error-color);
    cursor: pointer;
  }
}

.ck-body-wrapper + [class^="css"] {
  z-index: 9999 !important;
}
.input-wrap-select.changeStatus {
  //width: 140px;
  .select__single-value {
    //font-weight: var(--font-weight-semibold);
  }
  .select__control {
    padding: 0 5px;
  }
  .select__value-container,
  .select__placeholder,
  .select__option {
    font-size: var(--font-size-xs);
  }
}

.select__menu-portal {
  z-index: 99999 !important;
}
