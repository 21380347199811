@import "../../../../constants";

.remediation-queue-activity {
  flex: 1 1 auto;
  min-height: 452px;
  padding: 15px 0;
}
.remediation-queue-activity-list {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
  &.loading,
  &.no-data {
    min-height: 160px;
    justify-content: center;
    align-items: center;
  }

  .loader-wrap.is-center {
    font-size: var(--font-size-s);
    color: var(--findings-header-color);
    font-weight: 500;
  }
}
