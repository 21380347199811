@import "../../../constants";

.radio-button-list-wrapper {
  display: flex;

  &.column {
    flex-direction: column;
  }

  .radio-input {
    cursor: pointer;
  }
}
