.endpoint-list {
  position: relative;

  .endpoint-list-info {
    position: absolute;
    right: 10px;
    top: 4px;
    display: flex;
    align-items: center;
    height: 20px;
    color: var(--link-color);
    font-size: var(--font-size-xs);
    font-weight: 500;
    z-index: 1;

    .seem-icon {
      font-size: var(--icon-size-s);
      margin-right: 4px;
    }
  }

  .sections-loader {
    height: 100%;
  }
}
