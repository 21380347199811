@import "../../constants";

.trends-controls-wrapper {
  display: flex;
  align-items: center;
  //margin-top: -12px;

  .trends-time-scale-container {
    display: flex;
    align-items: center;
    padding-left: 22px;
    //margin-top: 6px;

    .trends-since-label {
      margin-left: 15px;
      font-size: var(--font-size-xs);
      opacity: 0.68;
      padding-top: 4px;
      @include ellipsis(100%);
    }
  }

  > *:not(:last-child) {
    margin-right: 15px;
  }

  .input-wrap {
    margin-bottom: 0;

    .select-input-holder-wrap {
      margin-bottom: 0;
    }
  }
}

.unify {
  .widget-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 53px);
    max-height: none;

    .chart-and-legend-wrap {
      height: 500px;
      justify-content: center;
    }
  }
}
