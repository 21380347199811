@import "../../constants";

.scope-management-wrap {
  display: flex;
  background: #ffffff;
  width: 296px;
  cursor: pointer;

  &.is-disabled {
    background-color: #f9f9fc;
    color: #8c8c96;

    .scope-management-wrapper {
      cursor: default;

      &:hover {
        border: 1px solid #e8e9f0;
      }

      .scope-label {
        color: #8c8c96;
      }
      .selected-scope-data {
        .selected-resource-title {
          color: #8c8c96;
        }
      }
    }
  }

  &.read-only {
    //pointer-events: none;
    .scope-management-wrapper {
      cursor: default;
      .selected-scope-data {
        .selected-resource-title {
          color: #8c8c96;
          cursor: default;
        }
      }
    }
  }

  .scope-management-wrapper {
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    border: var(--border-style);
    transition: border ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
      background ease-in-out 0.15s;
    &:hover {
      border: 1px solid var(--secondery-main-color);
      //box-shadow: 0 0 3px var(--secondery-main-color);
    }

    .scope-label {
      font-weight: 500;
      color: var(--primary-main-color);
      margin-left: 15px;
      margin-right: 12px;
    }

    .scope-separator {
      width: 1px;
      background-color: #d9d9de;
      height: 38px;
    }

    .selected-scope-data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 65px);
      padding: 0 12px;

      .selected-resource-title {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: var(--font-size-s);
        width: 100%;
        color: var(--primary-text-color);
        overflow: hidden;
        padding-right: 10px;
        cursor: pointer;

        &.placeholder {
          color: var(--input-placeholder-color);
          opacity: 1.1;
        }

        i {
          margin-right: 5px;
        }
        .selected-resource-title-text {
          @include ellipsis(100%);
          max-width: calc(100% - 20px);
        }
      }

      .dropdown-indicator {
        i {
          font-size: var(--font-size-xs);
        }
      }
    }
    .dropdown-loading-indicator {
      //  @include dropdown-loading;
      left: 0;
    }
  }
  &.read-only {
    .scope-management-wrapper {
      border: var(--input-border);
      box-shadow: none;
    }
  }
}
.scope-popover-class-findings {
  z-index: 98;
}

.scope-popover-class-remediation {
  .scope-management-menu-wrapper {
    left: -280px;
    width: 562px;
  }
}
.filter-popover-class-remediation {
  .filters-menu-popup-wrapper {
    left: -280px;
    width: 561px;
    max-height: 290px;
  }
}

.scope-popover-class-remediationQueue {
  .scope-management-menu-wrapper {
    left: -302px;
    width: 603px;
  }
}
.filter-popover-class-remediationQueue {
  .filters-menu-popup-wrapper {
    left: -299px;
    width: 599px;
  }
}
