@import "../../constants";

.setup-totp-modal {
  .modal-content {
    min-height: 368px;
  }
}
.qrCodeContainer {
  height: 134px;
}
.qr-loading {
  height: 134px;
  margin-bottom: 20px;
}
.qr-code-title {
  padding-bottom: 20px;
  text-align: center;
}

.totp-token-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
