@import "../../constants";

.details-pane-header-cloud-provider {
  font-size: var(--font-size-xs);
  font-weight: 500;
  color: var(--primary-main-color);

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;

    img {
      width: var(--icon-size-xl);
      height: var(--icon-size-xl);
      margin-right: 8px;
    }

    &.repo-link-disabled {
      pointer-events: none;
      cursor: default;
    }
  }
}
.details-pane-header-container
  .details-pane-header-bottom-components
  .details-pane-score-wrap {
  padding-right: 15px;
}
.details-pane-sla-status {
  display: flex;
  border: 0 !important;
  width: 100%;
  justify-self: flex-start;
  align-items: center;

  .seem-icon-help {
    padding-left: 5px;
  }
}

.side-drawer-wrapper {
  .tags-container {
    margin-top: 20px;
  }
}
