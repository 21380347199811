@import "../../../constants";

.details-pane-buttons-row {
  display: flex;
  //height: 60px;
  justify-content: space-between;

  .details-pane-button-create-ticket {
    .form-button {
      height: 38px;
      border-radius: var(--border-radius-l);
      border-color: var(--main-border-color);
    }
  }

  .details-pane-status-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > :not(:last-child) {
      margin-right: 16px;
    }
  }

  .jira-status {
    .form-button {
      width: 110px;
    }
  }

  .actual-status {
    width: 130px;

    .input-wrap {
      margin-bottom: 0px;
    }
  }

  .ticket-status-wrap {
    display: flex;
    flex-flow: column nowrap;
  }

  .data-source-icon {
    height: 25px;
    width: 25px;
  }

  .status-data {
    border-radius: var(--border-radius-s);
  }

  .actual-status-text {
    margin-left: 5px;
  }

  .actual-status {
    font-weight: 500;
    font-size: var(--font-size-xs);
    display: flex;
    align-items: center;

    .select__indicators,
    .select__indicator {
      padding: 0;
    }
    &:after {
      content: "";
      position: absolute;
      width: 1px;
      height: 38px;
      background: var(--main-border-color);
      right: -2px;
      top: 29px;
    }
  }

  .ticket-status-wrap {
    a {
      text-decoration: none;
    }

    .jira-status-icon-container {
      cursor: pointer;
    }
  }

  & > :not(:last-child) {
    padding-left: 14px;
    padding-right: 12px;
    border-right: 1px solid var(--table-border-color) !important;
  }

  & > :last-child {
    padding-left: 14px;
  }
}

.details-pane-comment-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.select__indicator-separator {
  display: none;
}

.seem-icon-dropdown-style {
  .form-button-wrap.is-icon {
    color: #7289ff;
    height: 34px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;

    .seem-icon-dropdown-arrow {
      font-size: var(--icon-size-xxxs);
      box-shadow: none;
      color: var(--secondery-main-color);
    }
  }
}

.modal-manual-ticket .modal-content {
  height: 620px;
  overflow: hidden;
}
