body {
  margin: 0;
  font-family: "Heebo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
  min-height: 100vh;
}

html,
body,
form,
fieldset,
table,
tr,
td,
img {
  margin: 0;
  padding: 0;
  font-family: "Heebo", sans-serif;
}

input,
button,
select,
textarea,
optgroup,
option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}
