@import "../../../../../constants";

.filter-dropdown-wrap {
  opacity: 0;
  @include filter-dropdown-wrap-style;
  max-height: 400px;
  width: 173px;
  @include animate-fade-in(0.2s);
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  overflow: auto;
  &.has-tags,
  &.has-date-input,
  &.has-age-input,
  &.has-title-input,
  &.has-other-input,
  &.has-input,
  &.has-search {
    width: 256px;
  }
  .tag-wrapper {
    margin-bottom: 2px;
    max-width: calc(100% - 6px);
  }
  .dropdown-tag-container .menu-search {
    margin-bottom: 0;
  }
  &.condition-filter {
    min-width: unset;
    width: auto;
  }
  .input-wrap.input-wrap-text {
    &.has-size-max {
      width: 100%;
      margin-bottom: 0;
    }
    &.menu-search {
      height: 38px;
    }
  }
  .dropdown-tag-container {
    .menu-search {
      margin-top: 10px;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .input-wrap-datepicker.input-wrap.input-wrap-text {
    margin-bottom: 0px;
  }
  .input-wrap-datepicker {
    margin-bottom: 0;
  }
}
.condition-selected-value {
  margin: 0 1px;
  @include filter-item-style;
}
.filter-dropdown-item-wrap {
  @include filter-dropdown-item-wrap-style;
  &:hover {
    background: #ddd;
  }

  &.is-selected {
    background: #e3e6fe;
  }
}
.filter-dropdown-item {
  @include filter-dropdown-item-style;
}
.checkbox-list-option {
  @include filter-dropdown-item-wrap-style;
}
