@import "../../../constants";

.modal-dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 100%;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: none !important;
  transition: none !important;
  z-index: 99;

  .modal-dialog-overlay {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &.is-black {
      background: rgba(0, 0, 0, 0.6);
    }
  }

  .modal-dialog-actions {
    display: flex;
    justify-content: flex-end;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-weight: 600;
    color: var(--primary-main-color);
    border-bottom: 1px solid var(--main-border-color);
    margin: 0 var(--padding-l);
    height: 56px;
    align-items: center;
    .seem-icon {
      margin-right: 10px;
      font-size: 22px;
      color: var(--secondery-main-color);
    }
    .seem-icon.seem-icon-close {
      font-size: var(--font-size-m);
    }
  }

  .modal-header-title-wrap {
    display: flex;
    align-items: center;
  }

  .modal-title {
    color: var(--title-color);
    font-size: 18px;
    font-weight: 500;
    margin-top: 4px;
  }
  .modal-subtitle {
    font-size: var(--font-size-s);
    color: var(--primary-text-color);
    margin-bottom: 20px;
  }

  .modal-right {
    justify-content: flex-end;
    right: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60%;
  }
  .modal-content {
    padding: 10px 30px 0 30px;
    overflow: auto;
    .modal-scroll-area {
      max-height: calc(100vh - 260px);
      padding-right: 10px;
      overflow: auto;
    }
  }

  .modal-buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    width: 100%;
    flex: 0 0 60px;
    margin-top: auto;
  }
}

.modal-title-image {
  height: 25px;
  width: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
}

.modal-dialog-content {
  position: relative;
  background: #fff;
  border: 1px solid #ccc;
  min-height: 100px;
  flex-flow: column nowrap;
  justify-content: space-between;
  display: flex;
  border-radius: var(--border-radius-s);
  flex-flow: column nowrap;
  min-width: 400px;
  box-shadow: 0 0 15px rgba(65, 66, 90, 0.2);
}

// Modal sizes

@media (min-width: 1200px) {
  .modal-lg {
    width: 1170px;
  }
}

@media (max-width: 1200px) {
  .modal-lg {
    width: 94%;
  }
}

// Defaults
.modal-size-xxs {
  width: 320px;
}

.modal-size-xs {
  width: 400px;

  .modal-content {
    padding: 10px 20px 0 20px;
  }
}

.modal-size-s {
  width: 576px;
}

.modal-size-m {
  width: 798px;
}

.modal-size-l {
  width: 925px;
}

.modal-size-xl {
  width: 1152px;
}

.modal-size-xxl {
  width: 1500px;
}

.modal-size-xxxl {
  width: 1800px;
}

@media screen and (max-width: 1920px) {
  .modal-size-xl {
    width: 1152px;
  }
  .modal-size-xxl {
    min-width: 1220px;
    max-width: 1500px;
    width: 78%;
  }
}

@media screen and (max-width: 1279px) {
  .modal-size-m {
    width: 798px;
  }
  .modal-size-l {
    min-width: 904px;
    max-width: 1092px;
    //width: 86%;
  }
  .modal-size-xl {
    min-width: 964px;
    max-width: 1152px;
    width: 90%;
  }
  .modal-size-xxl {
    min-width: calc(1024px - 30px);
    max-width: 1220px;
    width: 95%;
  }
}

@media screen and (max-width: 1024px) {
  .modal-size-m {
    min-width: 648px;
    max-width: 798px;
    width: 68%;
  }
  .modal-size-l {
    min-width: 648px;
    max-width: 904px;
    //width: 86%;
  }
  .modal-size-xl {
    min-width: 708px;
    max-width: 964px;
  }
  .modal-size-xxl {
    min-width: calc(100vw - 30px);
    max-width: 992px;
    width: calc(100vw - 30px);
  }
}

@media screen and (max-width: 768px) {
  .modal-size-m {
    width: 648px;
  }
  .modal-size-l {
    width: 688px;
    min-width: 92%;
  }
  .modal-size-xl {
    width: 708px;
  }
  .modal-size-xxl {
    width: calc(100vw - 30px);
  }
}

@media screen and (max-width: 680px) {
  .modal-size-m,
  .modal-size-l,
  .modal-size-xl,
  .modal-size-xxl,
  .modal-size-xxxl {
    width: calc(100vw - 30px);
    min-width: unset;
    max-width: unset;
  }
}
