@import "../../../../constants";

$filter-menu-title-height: 40px;
$tabs-menu-wrapper-height: 50px;

.filters-menu-popup-wrapper {
  background: #ffffff;
  box-shadow: var(--scope-filter-menu-box-shadow);
  border-radius: var(--border-radius-scope-filter);
  width: 536px;
  height: 403px;
  left: -89px;
  position: absolute;

  .filters-menu-title {
    height: $filter-menu-title-height;
    font-weight: 500;
    font-size: 18px;
    padding: 20px var(--filter-menu-item-paddings) 0 16px;
  }
  .tabs-menu-wrapper {
    height: $tabs-menu-wrapper-height;
    padding: 16px 10px 0px 10px;
  }

  .filters-list-container {
    overflow-y: auto;
    height: calc(
      100% - #{$tabs-menu-wrapper-height} - #{$filter-menu-title-height} - 18px
    );
    width: calc(100% - 10px);
    padding: 0 0 10px 0;
    margin-top: 18px;

    .filter-menu-row {
      width: 100%;
      padding: 8px 10px 8px 20px;
      margin-top: 3px;
      display: flex;
      align-items: center;
      font-size: var(--font-size-s);
      justify-content: space-between;
      flex-direction: row;
      cursor: pointer;
      &:first-child {
        margin-top: 0;
      }

      &.is-active {
        background-color: #e3e6fe;

        &:hover {
          background-color: #e3e6fe;
        }
      }

      &:hover {
        background-color: #f0f0f3;
      }

      .filter-name-wrapper {
        display: flex;
        align-items: center;
        color: var(--primary-text-color);
        i {
          color: var(--primary-text-color);
          font-size: var(--font-size-s);
        }

        .filter-name {
          margin-left: 10px;
        }

        .popular-badge {
          background: var(--secondery-main-color);
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
          border-radius: var(--border-radius-s);
          height: 20px;
          line-height: 20px;
          font-weight: 500;
          font-size: var(--font-size-xxs);
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 9px;
          margin-left: 10px;
        }
      }
    }
  }
}
