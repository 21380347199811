@import "../../../../constants";

//RMQ Synched Table Definitions
:root {
  //releveant to both RMQ tables
  --rmq-table-min-width: 1140px;
  --rmq-table-max-width: 1316px;
  --rmq-container-width: 1384px;
  --rmq-col-account: 185px;
  --rmq-col-score: 90px;
  --rmq-col-status: 110px;
  --rmq-col-sla-status: 120px;
  --rmq-col-ticket-status: 130px;
  --rmq-col-ticket-id: 140px;
  --rmq-col-title: calc(
    var(--rmq-table-max-width) - var(--rmq-col-account) - var(--rmq-col-score) -
      var(--rmq-col-status) - var(--rmq-col-sla-status) -
      var(--rmq-col-ticket-status) - var(--rmq-col-ticket-id)
  );

  --rmq-total-width: calc(
    var(--rmq-col-account) + var(--rmq-col-title) + var(--rmq-col-score) +
      var(--rmq-col-status) + var(--rmq-col-sla-status) +
      var(--rmq-col-ticket-status) + var(--rmq-col-ticket-id)
  );
  // ------------------------------------------------------------------------ //
}

.rmq-findings-table-wrapper-title {
  display: flex;
  flex-direction: column;
  padding: 20px 25px 0 30px;
  height: 58px;
  position: absolute;
  top: 20px;
  z-index: 1;
}
.synced-rmq-findings-title {
  color: var(--title-color);
  font-size: var(--font-size-xl);
  font-weight: 600;
}
.rmq-tables-wrap {
  height: calc(100vh - 452px);
}
:root {
  .rmq-tables-wrap {
    .rmqfwl-table {
      --rmq-col-ticket-status: 0 !important;
    }
  }
}
