.time-picker-wrap {
  display: flex;
  align-items: center;
  //.select__value-container {
  //
  //  justify-content: center;
  //  //padding-right: 7px;
  //}
  .single-value-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.time-picker-separator {
  margin: 0 5px;
}
.time-picker-minutes,
.time-picker-hour {
  width: 80px;
}
