@import "../application/src/constants";

.integration-header-wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;

  .integration-header-search {
    margin-top: 24px;
    width: 743px;
  }

  background-color: white;
}
