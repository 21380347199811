@import "../../constants.scss";

$rmq-table-header-height: 100px;

.remediation-queues-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.rmq-table-container {
  width: 100%;
  padding: 0 var(--page-padding) 0 var(--page-padding);
  overflow: auto;
  padding-top: 35px;
  .table-wrapper .thead .th.is-sortable .sort-direction-indicator {
    padding-top: 0;
  }
}
.remediation-queue-list {
  position: relative;

  .table-wrap-loading {
    height: calc(100% - 75px);
  }
  .loader-wrap.is-center {
    position: absolute;
    width: calc(100% - 60px);
    height: auto;
    margin: -16px auto 0 auto;
    text-align: center;
    box-shadow: none;
    top: 194px;
    left: 30px;
    right: 30px;
    bottom: 0;
  }
  .table-wrapper {
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
  }
  .jira-status-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .jira-status-icon-container {
      width: 24px;
      height: 20px;

      .seem-icon {
        font-size: var(--font-size-xs);
      }

      &.rejected {
        .seem-icon {
          font-size: 8px;
        }
      }
    }

    .jira-status-item-count {
      font-size: var(--font-size-s);
    }
    .jira-status-text {
      @include ellipsis(120px);
      display: inline-block;
      line-height: 20px;
    }
  }

  .rmq-last-update {
    font-weight: 400;
  }

  .last-update-row-data {
    display: flex;
    justify-content: space-between;

    .label-indicator-wrapper {
      &.synced {
        .label-indicator-icon {
          color: var(--secondery-main-color);
        }
      }

      &.not-synced {
        .label-indicator-icon {
          color: #bcbcc6;
        }
      }
    }
  }

  .table-wrapper .tbody {
    .rmq-input {
      .td-rmq-data {
        display: flex;
        justify-content: space-between;
        margin-top: 4px;

        .sync-icon {
          opacity: 0.4;
        }

        img {
          height: 20px;
        }
      }
    }
    .td-finding-num {
      @include ellipsis(100%);
    }
  }
}
