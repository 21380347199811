.create-finding-form {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 358px;

  .create-finding-msg-wrapper {
    grid-row: 1/2;
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    .create-finding-msg {
      display: flex;
      align-items: center;
      height: 30px;

      .seema-wrap {
        transform: scale(0.6);
        height: 80px;
        margin-left: -30px;
      }
    }
    .seem-icon-close {
      font-size: var(--font-size-xs);
      cursor: pointer;
    }
    &.not-visible {
      visibility: hidden;
    }
  }
}

.create-finding-wrapper {
  display: flex;
  flex-flow: row nowrap;
}

.create-finding-form-content {
  display: grid;
  grid-template-columns: 356px 356px;
  grid-column-gap: 15px;
  max-height: calc(100vh - 242px);
  overflow: auto;
}

.create-finding-title-field {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.create-finding-resource_name-field {
  grid-row: 2 / 3;
  grid-column: 2 / -1;
}

.create-finding-date-field {
  grid-row: 3 / 4;
  grid-column: 1 / 2;
}

.create-finding-score-field {
  grid-row: 3 / 4;
  grid-column: 2 / -1;
}

.create-finding-description-field {
  grid-row: 4 / 5;
  grid-column: 1 / -1;
  width: 100% !important;
  margin-bottom: 15px !important;
}

.create-finding-checkbox-field {
  margin-bottom: 0;
  margin-right: 10px;
}

.additional-data-container {
  width: 100%;
  grid-row: 5 / 6;
  grid-column: 1 / -1;
  border: var(--border-style);
  border-radius: var(--border-radius-s);
  cursor: pointer;

  .ReactCollapse--content {
    border-top: none;
    padding: 0 20px 25px;
  }

  .additional-data-fields {
    display: grid;
    grid-template-columns: 330px 340px;
    grid-column-gap: 15px;
    max-height: calc(100vh - 242px);
    overflow: auto;

    //.input-wrap {
    //  width: calc(100% - 7.5px);
    //}

    .create-finding-cloud_account-field {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }

    .create-finding-resource_type-field {
      grid-row: 2 / 3;
      grid-column: 2 / -1;
    }

    .create-finding-remediationText-field {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
    }

    .create-finding-remediationLink-field {
      grid-row: 3 / 4;
      grid-column: 2 / -1;
    }
  }

  .additional-data-item-toggle {
    display: flex;
    align-items: center;
    font-size: var(--font-size-s);
    color: var(--primary-main-color);
    font-weight: 500;
    height: 48px;
    &:hover {
      .additional-data-title {
        opacity: 0.85;
      }
    }

    .additional-data-sub-title {
      color: #b3b3b2;
      font-weight: normal;
      margin-left: 11px;
    }

    .additional-data-item-toggle-icon-wrap {
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform ease-in-out 0.05s;
      margin-left: 20px;
      margin-right: 12px;
      cursor: pointer;

      &.is-open {
        transform: rotate(90deg);
        transform-origin: center;
      }
    }
  }
}
