@import "../../constants";

.input-wrap.input-wrap-text-editor {
  .input-holder {
    flex-flow: column nowrap;
    border: none;
    box-shadow: none;
    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  .toolbar-container {
    width: 100%;
  }
  //.ck-editor {
  //  //border: 1px solid var(--main-border-color);
  //  //border-radius: var(--border-radius-s);
  //  cursor: text;
  //}
  //.ck.ck-icon {
  //  font-size: 0.5333350694em;
  //}

  //.ck-placeholder::before {
  //  font-size: var(--font-size-s);
  //  color: #9494a2;
  //}

  //.ck-toolbar {
  //  padding-left: 0;
  //  background: white;
  //  border: none;
  //}
  //.ck-toolbar.ck-toolbar_grouping {
  //  background: transparent !important;
  //}

  //.ck-editor__main .ck-editor__editable {
  //  border: var(--input-border);
  //  border-radius: var(--border-radius-s);
  //  font-size: var(--input-font-size);
  //  height: 80px;
  //}

  .ck-editor__editable {
    //border: var(--input-border);
    //border-radius: var(--border-radius-s);
  }

  &.is-disabled {
    .ck-editor {
      border: none;
    }
    .ck-editor__main {
      border: none;
    }
    .ck-editor__editable {
      background: transparent;
    }
  }
}
.word-count-wrap {
  display: flex;
  justify-content: flex-end;
  padding: 8px 5px;
  .word-count-wrap-allowed {
    color: var(--light-text-color);
    font-weight: 400;
    font-size: var(--font-size-xxs);
  }
}
.input-wrap-text-editor {
  &.has-size-max {
    .ck-editor {
      width: 100%;
    }
  }
  .input-holder {
    height: auto;
    border: none;
    padding: 0;
  }
  &.has-error {
    .input-holder {
      .ck-content {
        border-color: var(--input-error-color);
      }
    }
  }
}

.mce-content-body {
  width: 100%;
  border: var(--input-border);
  cursor: text;
  font-size: var(--input-font-size);
  height: 80px;
  padding: 8px 10px;
  border-radius: var(--border-radius-s);
  outline: none;
  flex: 1 1 auto;
  line-height: 1.4;
  overflow: auto;
  &:hover {
    box-shadow: var(--input-box-shadow);
  }
}

.mce-content-body[data-mce-placeholder]::before {
  font-size: var(--font-size-s);
  color: #9494a2 !important;
  padding: 0;
  left: 10px !important;
}

.has-disabled {
  .mce-content-body {
    border: none;
    background-color: var(--main-background-color);
  }

  .toolbar-container {
    display: none;
  }
}
.mce-content-body.mce-content-readonly:hover {
  box-shadow: none;
}
.tox-tinymce-inline .tox-editor-header {
  border: none !important;
}
