@import "../../constants";

.entity-details-pane-wrapper {
  background: #ffffff 0 0 no-repeat padding-box;
  border-radius: var(--border-radius-s);
  height: 100%;
  padding-bottom: 250px;

  &.is-full-screen {
    max-width: 980px;
    min-width: 880px;
    margin: 0 auto;
    padding: 15px 25px;
    padding-bottom: 250px;
    box-shadow: none;
    background: #ffffff 0 0 no-repeat padding-box;
  }
}

.details-pane-back-to {
  display: flex;
  align-items: center;
  max-width: 980px;
  min-width: 880px;
  margin: 0 auto;
  margin-bottom: 8px;
  width: 100%;
}

.details-elements-separator {
  height: 1px;
  background-color: var(--details-pane-separator-color);
  width: 100%;
  margin-bottom: 20px;
}

.details-pane-data-container {
  height: 100%;
  padding-left: 10px;
  overflow: scroll;
  position: relative;
}

.details-pane-navigator-wrapper {
  width: 100%;
  padding-bottom: 40px;
  margin-top: 20px;
  display: flex;
}

.details-pane-section {
  padding-right: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--details-pane-separator-color);

  &:last-child {
    margin-bottom: 250px;
  }

  .details-pane-section-title {
    font-weight: 500;
    font-size: var(--font-size-l);
    color: var(--details-pane-main-titles-color);
    margin-bottom: 5px;
  }

  .details-pane-section-data {
    height: 100%;
  }
}

.data-table {
  width: 100%;

  .data-table-row {
    display: flex;
    margin-top: 5px;
    &:first-child {
      margin-top: 0;
    }
  }

  .data-table-cell {
    padding: var(--padding-xs);
    background-color: var(--tables-background-color);
    border-radius: var(--border-radius-s);
    margin-left: 5px;
    word-wrap: break-word;
    font-size: 13px;
    min-height: 40px;
    color: #4d575c;

    .loading-row {
      @include loading-row(60%, 20px);
      left: 15px;
      top: 10px;
    }
  }

  .data-table-cell-header {
    color: var(--table-header-color);
    background: none;
    text-align: left;
    padding: 0;
    height: 20px;
    min-height: 20px;
  }
}

.details-pane-jira-copy {
  cursor: pointer;
  margin-left: 5px;
  height: 34px;
}

.summary-jira-number {
  display: flex;
  span {
    padding-right: 8px;
  }
  .summary-jira-number-copy {
    opacity: 1;
    @include animate-appear-from-left(0.1s);
    padding: 1px;
    font-size: 14px;
    cursor: pointer;
    transition: opacity ease-in-out 0.4s, transform ease-in-out 0.2s;

    &:hover {
      color: var(--link-color--hover);
    }
    &:active {
      opacity: 0;
    }
    i {
      font-size: 14px;
    }
    &.summary-jira-number-copy-bye {
      i:after {
        transform: translateY(-20px);
        transition: opacity ease-in-out 0.4s, transform ease-in-out 0.2s;
      }
    }
  }
}

.data-blocks {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;

  .summary-data-cell {
    width: 32%;
    margin-bottom: 15px;
    margin-right: 6px;
    display: flex;
    white-space: nowrap;
    min-height: 30px;
    max-height: 60px;
    font-size: 13px;
    position: relative;

    &:nth-child(3),
    &:nth-child(6) {
      .summary-cell-content {
        border-right: 0;
      }
    }

    &.additional-data-cell {
      width: calc(50% - 6px);
      &:nth-child(2n) {
        .summary-cell-content {
          border-right: none;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }

    .summary-cell-title {
      font-weight: 600;
      margin-right: 6px;
      flex: 0 0 115px;
      white-space: normal;
    }

    .summary-cell-content-wrap {
      flex: 1 1 auto;
      white-space: normal;
      height: 32px;
      @include ellipsis(100%);
    }

    .summary-cell-content {
      position: relative;
      height: 32px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 95%;
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid var(--table-border-color);

      &:after {
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 70%;
        height: 16px;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1) 50%
        );
      }
    }
  }

  .copy-tooltip {
    padding: 6px;
    font-size: 12px;
  }
}
