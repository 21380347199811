@import "../../constants";
:root {
  --widgets-padding: 20px;
}
.dashboard-widget-wrapper {
  border-radius: var(--border-radius-s);
  border: var(--border-style);
  background-color: white;
  padding: 0;
  box-shadow: var(--widget-box-shadow);
  margin-bottom: var(--grid-gap);
  margin-right: var(--grid-gap);
  min-height: calc(50% - 35px);
  flex: 0 0 calc(50% - #{var(--grid-gap)});
  width: calc(50% - #{var(--grid-gap)});

  flex-grow: 1 !important;

  &.widget-size-xs {
    flex-basis: calc(25% - #{var(--grid-gap)});
    width: calc(25% - #{var(--grid-gap)});
    min-width: 420px;
  }

  &.widget-size-s {
    flex: 1 1 calc(50% - #{var(--grid-gap)});
    width: calc(50% - #{var(--grid-gap)});
    min-width: 490px;
  }

  &.widget-size-l {
    flex-basis: calc(100% - #{var(--grid-gap)});
    width: calc(100% - #{var(--grid-gap)});
  }

  .widget-header {
    height: 38px;
    padding: 10px var(--widgets-padding) 0 var(--widgets-padding);
  }
  .widget-title {
    font-size: var(--font-size-xl);
    color: var(--details-pane-main-titles-color);
    border-bottom: 1px solid var(--details-pane-separator-color);
    font-weight: 500;
    padding-bottom: 5px;
  }
  .widget-body {
    position: relative;
    min-height: 286px;
    height: calc(100% - 37px);
    padding-right: 5px;
    padding-top: 15px;
    width: 100%;
    max-height: calc(100vh / 2 - 80px - 63px);
  }

  &.ticket-status-widget {
    .widget-body {
      padding-left: 7px;
    }
  }
  .loader-wrap.is-center .loader-icon {
    margin-top: 60px;
  }
}

.widgets-grid {
  display: flex;
  padding: 11px 0 0 var(--grid-gap);
  justify-content: space-between;
  align-items: stretch;
  flex-flow: row wrap;
  height: calc(100vh - 180px);
  overflow: auto;
}

@media screen and (max-width: 1745px) {
  .dashboard-widget-wrapper {
    &.widget-size-xs {
      flex-basis: calc(33.33% - 12px);
      width: calc(33.33% - #{var(--grid-gap)});
    }
  }
}
@media screen and (max-width: 1439px) {
  .dashboard-widget-wrapper {
    &.widget-size-xs {
      flex-basis: calc(50% - 12px);
      width: calc(50% - #{var(--grid-gap)});
    }
  }
}
@media screen and (max-width: 1279px) {
  .dashboard-widget-wrapper {
    &.widget-size-xs {
      flex-basis: calc(50% - 12px);
      width: calc(50% - #{var(--grid-gap)});
    }
    &.widget-size-s {
      flex-basis: calc(100% - 12px);
      width: calc(100% - #{var(--grid-gap)});
    }

    &.widget-size-l {
      flex-basis: calc(100% - #{var(--grid-gap)});
      width: calc(100% - #{var(--grid-gap)});
    }
  }
}
