@import "../../../constants";

.findings-list-wrapper {
  padding-bottom: 10px;
}

.findings-group {
  border-left-width: 3px;
  border-left-style: solid;
  border-radius: 3px 0 0 3px;

  @include group-colors(border-left-color);
}

.findings-infinite-list {
  overflow-x: hidden !important;
}

.finding-group-title-wrapper {
  & ~ .finding-list-item-table-row {
    border-bottom-left-radius: 3px;
  }
}
.group-indicator-container {
  width: var(--global-table-col-indicator-width);
  height: 100%;
  .group-indicator {
    height: 100%;
    width: var(--global-table-col-indicator-width);
    background-color: var(--secondery-main-color);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: -3px 0 3px #ddd;
  }
}
.finding-list-item-table-row {
  height: var(--findingListRowHeight) !important;
  white-space: nowrap;
  user-select: none;
  transition: background-color ease-in-out 0.15s;
  &.finding-list-item-table-row-open-in-details-pane {
    .group-indicator-container {
      .group-indicator {
        background-color: var(--primary-main-color);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
    + .finding-list-item-table-row {
      .group-indicator {
        border-top-left-radius: 0;
      }
    }
  }
  &.finding-list-item-table-row-checkbox-selected {
    .findings-list-item-wrapper,
    .findings-list-item-wrapper:hover {
      background-color: var(--secondary-main-hover-color);
    }
    .findings-list-item-wrapper {
      border-bottom: 1px solid #cdd1f2;
    }
    .group-indicator {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
}
.finding-group-title-wrapper {
  display: flex;
  height: 54px;
  flex: 0 0 54px;
  z-index: 999;
  box-shadow: 0px 2px 2px #dde0f1;
}

.findings-group-title {
  text-align: left;
  width: 500px;
  color: var(--primary-main-color);
  font-weight: 600;
  font-size: var(--font-size-m);
  display: flex;
  align-items: center;
  padding-left: 14px;
  & {
    @include group-colors(background-color);
  }
}

.findings-table-container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
}

.findings-table {
  width: 100%;
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.findings-navigator-wrapper {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #eaedf2;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  width: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 400px;
  margin-left: 20px;
  margin-top: 10px;
}

.ReactVirtualized__Grid:focus {
  outline: none;
}

.groups-scroller {
  @include group-colors(background-color);
}

.side-container {
  display: flex;
  flex-direction: column;
  padding: 20px 25px 0 30px;
  flex-basis: 160px;
  flex-grow: 1;
  width: 365px;
  min-width: 365px;
}
.aggregated-tip-icon {
  margin-left: 6px;
}
.totals-and-carousel-wrap {
  margin: 18px var(--page-padding) 34px var(--page-padding);
  height: 204px;
  display: flex;
  min-height: 204px;
  min-width: 700px;

  &.is-loading {
    .findings-navigation-bar {
      opacity: 0;
    }
  }
  .group-by-separator {
    width: 1px;
    height: calc(100% - 30px);
    margin-top: 15px;
    background: var(--border-color);
  }
  .carousel-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex: 1 1 auto;
    width: calc(100vw - 400px);
    padding-top: 20px;
    padding-left: 20px;
    padding-left: 48px;

    .group-by-panel {
      display: flex;
      padding-left: 10px;
    }
  }
}

.finding-item-data-container {
  position: absolute;
  display: flex;
  right: 0;
  width: var(--details-pane-width);
  min-width: var(--details-pane-min-width);
  top: 0;
  z-index: 1;
}

.findings-navigation-bar {
  display: flex;
  flex-direction: row;
  padding-top: 0;
  height: 142px;
  min-width: 492px;
}

.findings-data-area {
  position: relative;
  background-color: white;
  height: calc(100vh - var(--header-height) - 322px);
  min-height: 258px;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 6px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.12);
  @include box-style;
  margin: 5px var(--page-padding) 0 var(--page-padding);
  overflow: hidden;
  overflow-x: auto;
  max-width: calc(100% - (2 * var(--page-padding)));
  &.is-loading,
  &.is-no-data {
    overflow: hidden;
    height: calc(100vh - var(--header-height) - 330px);
    box-shadow: var(--box-shadow);
    position: relative;
    .loader-wrap {
      margin-left: 5px;
    }
    .table-header-cell.finding-account-col {
      padding-left: 35px;
    }
  }
  &.is-loading {
    .findings-list-header-wrapper {
      display: none;
    }
  }

  &:hover {
    .finding-account-col.table-cell {
      .checkbox-input-wrap.checkbox-style {
        opacity: 0.4;
      }
    }
  }
  .findings-operations-panel {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    display: flex;
    justify-content: flex-end;

    > div {
      padding-left: 8px;
    }
  }
  &.findings-data-area-details-pane-open {
    .finding-account-col.table-cell .checkbox-input-wrap.checkbox-style {
      opacity: 1;
    }
  }
  &.findings-data-area-checkbox-selected {
    .finding-account-col.table-cell .checkbox-input-wrap.checkbox-style {
      opacity: 1;
    }
  }
}

.filters-panel-is-visible {
  overflow: auto;
  padding-bottom: 15px;
  .findings-data-area {
    height: calc(
      100vh - var(--carousel-wrap-height) - var(--header-height) - 349px
    );
  }
}

.total-findings-title-wrap {
  flex: 1 0 auto;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  padding-left: 30px;
  padding-top: 28px;
  width: 356px;
  max-width: 356px;
  color: var(--app-header-color);

  &.total-findings-count-title-loading {
    @include loading-row(134px, 22px);
    top: 22px;
    left: 30px;
  }
}
.total-findings-subtitle {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
  color: var(--primary-main-color);
}
.total-findings-title-icon {
  font-size: 44px;
}
.table-total-findings-title-wrap {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #181818;
  text-align: left;
  padding-right: 30px;
  width: 100%;
}
.table-total-findings-title {
  display: flex;
}

.findings-navigation-bar-loading {
  .react-multi-carousel-track {
    .react-multi-carousel-item {
      width: 142px;
      height: 82px;
      margin-right: 10px;
      position: relative;
      @include loading-row(142px, 82px);
      top: 0;
      left: 0;
    }
  }
}

.groups-separator {
  height: 2px;
  background-color: #dfe3e8;
}

.finding-loading-row {
  //first row
  &:first-child {
    margin-left: 0;
    border-bottom: 0;
    height: 50px !important;
    svg {
      display: none;
      &:first-child {
        display: block;
        margin-left: 0px;
        margin-top: -10px;
      }
    }
  }
}

.finding-loading-row {
  background-color: var(--table-row-color);
  border-bottom: 1px solid var(--main-border-color);
  display: flex;
  box-shadow: unset;
  transition: var(--transition);
  height: 100%;
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;

  &:not(first-child) {
    margin-left: 0px;
    svg {
      display: block;
      &:first-child {
        display: none;
      }
    }
  }
  svg {
    margin-left: 20px;
  }
}

.sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 2;
}

.sticky {
  background-color: white;
}
.finding-list-item-table-row-wrapper,
.finding-loading-row {
  height: 100%;
  display: inline-flex;
  width: 100%;
  position: relative;

  .group-indicator-container {
    width: var(--global-table-col-indicator-width);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    .group-indicator {
      height: 100%;
      width: var(--global-table-col-indicator-width);
      background-color: #e3e3e7;
      border-radius: 0;

      &.is-top-edge {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      &.is-bottom-edge {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}

//* Styles for columns width *//

.findings-list-header-wrapper,
.findings-list-item-wrapper {
  position: relative;
  width: 100%;

  &:hover {
    .finding-account-col.table-cell {
      .checkbox-input-wrap.checkbox-style {
        opacity: 1;
      }
    }
  }
  .finding-cloud-provider-icon {
    width: var(--findings-col-account);
    text-align: center;
    flex: 0 0 25px;

    img {
      width: 25px;
      margin-top: 6px;
    }
  }

  .finding-account-col {
    width: var(--findings-col-account);
    flex: 0 0 var(--findings-col-account);
    flex-flow: row nowrap;

    &.table-cell {
      padding-left: 5px;
      .checkbox-input-wrap.checkbox-style {
        opacity: 0;
        transition: opacity ease-in-out 0.15s;
      }
    }
  }

  .finding-title-col {
    width: var(--findings-col-title);
    flex: 0 0 var(--findings-col-title);
  }

  .finding-score-col {
    width: var(--findings-col-score);
    flex: 0 0 var(--findings-col-score);
  }

  .finding-ticket-status-col {
    width: var(--findings-col-ticket-status);
    flex: 0 0 var(--findings-col-ticket-status);
    .jira-status-text {
      @include ellipsis(#{calc(var(--findings-col-ticket-status) - 50px)});
      display: inline-block;
      line-height: 20px;
    }
  }
  .finding-sla-status-col {
    width: var(--findings-col-sla-status);
    flex: 0 0 var(--findings-col-sla-status);
  }
  .finding-status-col {
    width: var(--findings-col-status);
    flex: 0 0 var(--findings-col-status);
    &.table-cell {
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: flex-start;
    }
  }
  .finding-resources-col {
    &.table-cell {
      &.resource-name {
        @include ellipsis(100%);
        display: inline-block !important;
        line-height: var(--findingListRowHeight);
      }
    }
  }
  .finding-resources-col {
    width: var(--findings-col-resources);
    flex: 0 0 var(--findings-col-resources);
  }

  .findings-actions-container {
    width: var(--findings-col-actions);
    flex: 0 0 var(--findings-col-actions);

    position: absolute;
    right: 0;
    top: 5px;
  }

  .finding-remediation {
    width: var(--findings-col-remedetion);
    flex: 0 0 var(--findings-col-remedetion);
  }

  .finding-tags-col {
    width: var(--findings-col-tags);
    flex: 0 0 var(--findings-col-tags);
  }

  .table-cell.finding-ticket-status-col {
    width: var(--finding-col-ticket-status);
    flex: 0 0 var(--finding-col-ticket-status);
    font-size: var(--font-size-xs);
  }

  .finding-more-actions {
    width: var(--findings-col-actions);
    flex: 0 0 var(--findings-col-actions);

    position: absolute;
    right: 10px;
    top: 5px;
  }
}

.findings-list-header-wrapper {
  .group-indicator-container {
    width: var(--global-table-col-indicator-width);
    flex: 0 0 var(--global-table-col-indicator-width);
  }
}
@media screen and (max-width: 1279px) {
  .side-container.side-container-placeholder {
    display: none;
  }
}
