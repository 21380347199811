@import "../../../constants";

$description-col: 250px;
$resource-name-col: 150px;
$org-score-col: 110px;
$status-col: 65px;
$view-col: 58px;
$source-col: calc(
  100% - #{$description-col} - #{$org-score-col} - #{$status-col} - #{$resource-name-col} -
    #{$view-col} - 20px
);

.raw-findings-progress-bar-container {
  margin-bottom: 34px;
  margin-top: 10px;
  .progress-bar-label {
    font-size: var(--font-size-xs);
    font-weight: 400;
  }
}

.finding-related-table {
  min-width: 600px;
  overflow: auto;
  .data-table-header-row {
    height: 40px;
    display: flex;
    align-items: center;
    .score-cell-header {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;

      i {
        margin-left: 7px;
      }
    }
  }
  .data-table-row {
    .data-table-cell:nth-child(1) {
      display: flex;
      align-items: center;
      width: $source-col;
      min-width: 80px;
      flex: 0 0 80px;
    }

    .data-table-cell:nth-child(2) {
      width: $description-col;
      flex: 1 0 auto;
    }

    .data-table-cell:nth-child(3) {
      flex: 0 0 $resource-name-col;
      width: $resource-name-col;
    }

    .data-table-cell:nth-child(4) {
      flex: 0 0 $org-score-col;
      width: $org-score-col;
    }

    .data-table-cell:nth-child(5) {
      flex: 0 0 $status-col;
      width: $status-col;
    }

    .data-table-cell:nth-child(6) {
      flex: 0 0 $view-col;
      width: $view-col;
      display: flex;

      .view-icon-container {
        color: var(--secondery-main-color);
        align-items: center;
        justify-content: center;
        width: 100%;
        display: flex;
        cursor: pointer;

        &.is-disabled {
          cursor: default;
          .form-button-wrap {
            &:hover {
              color: #d3d3d3;
            }
          }

          .seem-icon-expand {
            cursor: default;
            color: #d3d3d3;

            &:hover {
              color: #d3d3d3;
            }
          }
        }
      }
    }
  }

  .data-source-icon {
    height: 25px;
    max-width: 25px !important;
  }
}

.details-pane-related-view-btn-wrap {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}

//.data-source-icon {
//  height: 15px;
//  max-width: 15px !important;
//  background-repeat: no-repeat;
//  background-size: 100%;
//}
