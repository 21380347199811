@import "../../../../constants.scss";

.tags-select__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
}
@media screen and (min-width: 30em) {
  .tags-select__suggestions {
    min-width: 200px;
    max-width: 300px;
    width: auto;
    white-space: nowrap;
    color: #41425a;
    left: -90px;
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-normal);
    top: 0;
  }
}

.tags-select__suggestions {
  position: absolute;
  top: 100%;
  right: 100%;
  width: 100%;
  z-index: 10;
  li.is-active {
    background: #b7cfe0;
  }

  li.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }
}

.tags-select__suggestions ul {
  margin: 4px 0;
  padding: 0;
  list-style: none;
  background: white;
  border: var(--input-border);
  border-radius: var(--border-radius-s);
  box-shadow: var(--box-shadow);
}

.tags-select__suggestions li {
  padding: 6px 15px 6px 12px;
  transition: background ease-in-out 0.2s;
  display: flex;
  flex-direction: column;
}

.tags-select__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.tags-select__suggestions li:hover {
  cursor: pointer;
  background: var(--secondary-main-hover-color);
}

.tags-select__suggestions li.is-active {
  background: var(--secondary-main-hover-color);
}

.tags-select__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
