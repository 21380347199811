.findings-view {
  .total-findings-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .total-findings-title-num {
      margin-right: 10px;
    }
  }
}
