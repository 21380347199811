.create-group-form-wrapper {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}
.create-group-form-content {
  flex: 1 1 auto;
  .checkbox-list-wrapper .checkbox-list-options-container {
    height: 200px;
    overflow-y: auto;
  }

  .group-location-select {
    i {
      color: var(--secondery-main-color);
    }
    .select__single-value {
      margin-left: 30px;
      max-width: calc(100% - 30px);
    }
  }

  .group-children-container {
    border: 1px solid var(--border-color);
    height: 233px;
    /* display: flex; */
    overflow: auto;
    // margin-top: 15px;
    border-radius: var(--border-radius-s);
    padding-right: 15px;

    .tree-wrapper {
      .tree-node {
        margin-right: 0;
      }
    }
  }

  .warning-wrapper {
    height: 33px;
    width: 100%;
    margin-top: 10px;

    .warning-message-container {
      border-radius: var(--border-radius-s);
      box-shadow: var(--box-shadow);
      font-size: var(--font-size-xs);
      width: 100%;
      display: flex;
      align-items: center;
      border-left: 2px solid var(--high-alert-color);
      height: 100%;

      i {
        font-size: var(--icon-size-xs);
        color: var(--high-alert-color);
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
.create-group-form-buttons {
  flex: 0 0 auto;
}
.create-group-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &.delete-enabled {
    justify-content: space-between;
  }

  .form-button-wrap:first-child {
    margin-left: 0;
  }
}
.tree-in-new-group-menu .dropdown-tree {
  box-shadow: var(--menu-box-shadow);
  border-radius: var(--border-radius-s);
  border: var(--border-style);
  background: #fff;
  width: 512px;
  height: 250px;
}
