@import "../constants";

.resource-list {
  & {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 30px;
    overflow-y: auto;
  }

  &-title {
    color: var(--title-color);
    font-size: var(--font-size-xl);
    font-weight: 600;
    margin: 20px 0;
  }

  &-table-container {
    flex-grow: 1;
  }
  .tbody {
    .tr:after {
      display: none;
    }
  }
}

.expander {
  display: flex;
  align-items: baseline;

  &-icon {
    transition: transform 0.15s;
    margin-right: 12px;
  }
}

.tree-leaf-icon {
  align-self: center;
  margin: 0 16px 0 4px;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: var(--secondery-main-color);
}

.expanded {
  transform: rotate(90deg);
}

.critical-finding-cell {
  display: flex;
  align-items: baseline;
}

.critical-finding-square {
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 2px;
  margin-right: 8px;
}
