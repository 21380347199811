@import "../application/src/constants";

.profile-actions-form {
  .profile-scopes-container {
    .tree-nodes-wrap {
      border: 1px solid #e2e4ec;
      height: 260px;
      overflow: auto;
      border-radius: var(--border-radius-s);
      padding-right: 15px;
      padding-bottom: 8px;
    }

    .tree-wrapper {
      .tree-node {
        margin-right: 0;
      }
    }
  }

  & > :not(:last-child) {
    margin-bottom: 12px;
  }
}
