@import "../../constants.scss";
.password-policy-wrapper {
  z-index: 10;
  overflow: visible;
  white-space: normal;
  word-wrap: break-word;
  height: 85px;
  font-size: 14px;
  width: 250px;
  padding-left: 10px;
  border-radius: 5px;
  background-color: #eaedf2;
  .password-rule-row {
    color: #a5acaf;
    text-decoration: none;
    &.valid {
      text-decoration: line-through;
    }
  }
}
