@font-face {
  font-family: "seem-icons";
  src: url("fonts/seem-icons.eot?jwgdh4");
  src: url("fonts/seem-icons.eot?jwgdh4#iefix") format("embedded-opentype"),
    url("fonts/seem-icons.ttf?jwgdh4") format("truetype"),
    url("fonts/seem-icons.woff?jwgdh4") format("woff"),
    url("fonts/seem-icons.svg?jwgdh4#seem-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="seem-icon-"],
[class*=" seem-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "seem-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.seem-icon-change-score:before {
  content: "\e962";
}
.seem-icon-aggregated:before {
  content: "\e961";
}
.seem-icon-circle-plus:before {
  content: "\e95f";
}
.seem-icon-circle-minus:before {
  content: "\e960";
}
.seem-icon-new-actions:before {
  content: "\e95e";
}
.seem-icon-create-ticket:before {
  content: "\e95a";
}
.seem-icon-manualfinding:before {
  content: "\e95b";
}
.seem-icon-change-status:before {
  content: "\e95c";
}
.seem-icon-bulk-upload:before {
  content: "\e95d";
}
.seem-icon-checkbox-indeterminate:before {
  content: "\e959";
}
.seem-icon-open-confirmed:before {
  content: "\e917";
}
.seem-icon-browse:before {
  content: "\e958";
}
.seem-icon-scope-group:before {
  content: "\e957";
}
.seem-icon-exit-scope:before {
  content: "\e956";
}
.seem-icon-filter-recommended:before {
  content: "\e952";
}
.seem-icon-filter-saved:before {
  content: "\e953";
}
.seem-icon-filter-unsaved:before {
  content: "\e954";
}
.seem-icon-root:before {
  content: "\e955";
}
.seem-icon-add-user:before {
  content: "\e951";
}
.seem-icon-edit:before {
  content: "\e94a";
}
.seem-icon-risk-accepted:before {
  content: "\e948";
}
.seem-icon-false-positive:before {
  content: "\e949";
}
.seem-icon-info:before {
  content: "\e945";
}
.seem-icon-in-time:before {
  content: "\e946";
}
.seem-icon-overdue:before {
  content: "\e947";
}
.seem-icon-performance-down:before {
  content: "\e943";
}
.seem-icon-performance-up:before {
  content: "\e944";
}
.seem-icon-icon-resources-filled:before {
  content: "\e942";
}
.seem-icon-notebook:before {
  content: "\e93f";
}
.seem-icon-last-synced:before {
  content: "\e940";
}
.seem-icon-seemplicity-mark:before {
  content: "\e941";
}
.seem-icon-warning:before {
  content: "\e93e";
}
.seem-icon-download:before {
  content: "\e93d";
}
.seem-icon-link:before {
  content: "\e93b";
}
.seem-icon-root_old:before {
  content: "\e93a";
}
.seem-icon-scope:before {
  content: "\e938";
}
.seem-icon-scope-group_old:before {
  content: "\e939";
}
.seem-icon-search:before {
  content: "\e936";
}
.seem-icon-table-filter:before {
  content: "\e933";
}
.seem-icon-copy:before {
  content: "\e932";
}
.seem-icon-comment:before {
  content: "\e92f";
}
.seem-icon-new-ticket:before {
  content: "\e931";
}
.seem-icon-checkmark:before {
  content: "\e91b";
}
.seem-icon-slash:before {
  content: "\e91c";
}
.seem-icon-expand:before {
  content: "\e91f";
}
.seem-icon-left-arrow:before {
  content: "\e92b";
}
.seem-icon-right-arrow:before {
  content: "\e92c";
}
.seem-icon-close:before {
  content: "\e92d";
}
.seem-icon-scheduled:before {
  content: "\e92e";
}
.seem-icon-open:before {
  content: "\e930";
}
.seem-icon-checkbox-unchecked:before {
  content: "\e916";
}
.seem-icon-checkbox-checked:before {
  content: "\e915";
}
.seem-icon-alert:before {
  content: "\e929";
}
.seem-icon-dot-circle:before {
  content: "\e91e";
}
.seem-icon-help:before {
  content: "\e918";
}
.seem-icon-lock:before {
  content: "\e919";
}
.seem-icon-private:before {
  content: "\e91a";
}
.seem-icon-public:before {
  content: "\e91d";
}
.seem-icon-plus:before {
  content: "\e900";
}
.seem-icon-radio-unchecked:before {
  content: "\2022";
}
.seem-icon-add-comment:before {
  content: "\e901";
}
.seem-icon-fix:before {
  content: "\e902";
}
.seem-icon-close1:before {
  content: "\e903";
}
.seem-icon-ignore:before {
  content: "\e904";
}
.seem-icon-jira:before {
  content: "\e905";
}
.seem-icon-snooze:before {
  content: "\e906";
}
.seem-icon-target-date:before {
  content: "\e907";
}
.seem-icon-more-actions:before {
  content: "\e908";
}
.seem-icon-up-arrow:before {
  content: "\e909";
}
.seem-icon-back-arrow:before {
  content: "\e90a";
}
.seem-icon-next-arrow:before {
  content: "\e90b";
}
.seem-icon-jira-icon-backlog:before {
  content: "\e90c";
}
.seem-icon-jira-icon-done:before {
  content: "\e90d";
}
.seem-icon-jira-icon-in-progress:before {
  content: "\e90e";
}
.seem-icon-jira-icon-not-in:before {
  content: "\e90f";
}
.seem-icon-dashboard:before {
  content: "\e910";
}
.seem-icon-findings:before {
  content: "\e911";
}
.seem-icon-jira-icon-scheduled:before {
  content: "\e912";
}
.seem-icon-remed:before {
  content: "\e913";
}
.seem-icon-bin:before {
  content: "\e914";
}
.seem-icon-spinner:before {
  content: "\e981";
}
.seem-icon-chevron-down:before {
  content: "\e927";
}
.seem-icon-chevron-up:before {
  content: "\e928";
}
.seem-icon-filter:before {
  content: "\e921";
}
.seem-icon-line-plus:before {
  content: "\e922";
}
.seem-icon-icon-menu:before {
  content: "\e923";
}
.seem-icon-icon-plus:before {
  content: "\e925";
}
.seem-icon-icon-resources:before {
  content: "\e926";
}
.seem-icon-eye:before {
  content: "\e9ce";
}
.seem-icon-user:before {
  content: "\e92a";
}
.seem-icon-not-synced:before {
  content: "\e935";
}
.seem-icon-synced:before {
  content: "\e937";
}
.seem-icon-schedule:before {
  content: "\e934";
}
.seem-icon-down-arrow:before {
  content: "\e924";
}
.seem-icon-pause:before {
  content: "\ea16";
}
.seem-icon-dropdown-arrow:before {
  content: "\e920";
}
.seem-icon-x-small:before {
  content: "\e93c";
}
.seem-icon-connections:before {
  content: "\e94c";
}
.seem-icon-shield:before {
  content: "\e94d";
}
.seem-icon-key:before {
  content: "\e94e";
}
.seem-icon-account:before {
  content: "\e94b";
}
.seem-icon-bin-2:before {
  content: "\e94f";
}
.seem-icon-refresh:before {
  content: "\e950";
}
