@import "../../../constants";

.scope-settings-wrapper {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-left: 13px;
  width: 767px;

  .filter-panel-data .filter-rows {
    flex-flow: row wrap;
    padding-top: 4px;
    .filter-rows-add-value-btn {
      margin-top: 1px;
    }
  }
  .scope-settings-header {
    display: flex;
    height: 63px;
    align-items: center;
    padding: 0 20px;

    .seem-icon-exit-scope {
      margin-right: 20px;
    }
    .form-button-group {
      .form-button-wrap:nth-child(1) {
        margin-left: 15px;
      }
    }
  }

  .scope-separator {
    color: var(--secondery-main-color);
    font-weight: 300;
    margin-left: 8px;
    margin-right: 8px;
  }

  .header-separator {
    background-color: #e7e7e7;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 63px;
  }

  .scopes-panel-data {
    display: flex;
    flex-direction: column;
    padding: 18px 20px;

    .scope-add-condition {
      margin-top: 18px;
    }

    .remove-filter-panel {
      cursor: pointer;
      margin-left: 13px;
    }

    .scope-condition-row {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        padding-top: 14px;
      }
      .filter-panel-data {
        border-left: var(--input-border);
        border-radius: 8px;
      }
      .or-mark {
        border: 1px solid var(--input-border-color);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: var(--font-size-xs);
        color: #8d8e9c;
        margin-right: 13px;
        padding-top: 2px;
      }
    }
  }
}

.add-scope-field {
  display: flex;
  align-items: center;
  color: var(--secondery-main-color);
  font-weight: 500;
  cursor: pointer;

  i {
    font-size: var(--font-size-xs);
    margin-right: 6px;
  }
}
.tree-in-scope-menu .dropdown-tree {
  box-shadow: var(--menu-box-shadow);
  border-radius: var(--border-radius-s);
  border: var(--border-style);
  background: #fff;
  width: 298px;
  height: 238px;
  margin-left: 69px;
}
