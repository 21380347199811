@import "../../constants.scss";

.modal-aggregate-findings {
  .modal-content {
    display: flex;
    flex-flow: column nowrap;
  }
  .loader-wrap {
    height: 280px;
  }

  .prepare-aggregation-error {
    .form-button-group {
      justify-content: center;
    }
  }

  .findings-count-to-change {
    font-weight: 500;
  }
  .back-to-agggregation {
    height: 44px;
    margin-bottom: 12px;
    margin-top: 20px;
  }
  .aggregate-popup-wrapper {
    .buttons-container {
      display: flex;
      justify-content: center;
      margin-bottom: 78px;
    }
    .aggregation-popup {
      height: 315px;

      .title-text {
        font-size: var(--font-size-m);
      }

      .loader-icon-wrap {
        .seem-icon {
          color: #d3d7e3;
          font-size: 58px;
        }
      }
    }
  }

  .show-all-resources-btn-wrap {
    width: 100%;

    .show-all-resources {
      width: 100%;
      margin-top: 26px;
    }
  }
}
