@import "../../constants.scss";

.chart-and-legend-wrap {
  height: 100%;
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chart-message {
  font-size: 16px;
  font-weight: 500;
  color: #212b36;
}
.chart-wrap {
  width: 100%;
  height: calc(100% - 50px);
}
.legend-data-container {
  width: 100%;
  padding: 0 25px 12px 85px;
  .legend-title {
    font-weight: 500;
  }
}
.legend-wrap {
  height: 50px;
}
