@import "css/reset";
@import "css/variables";
@import "css/global";
@import "Forms/forms";
@import "icons/seem-icons/style.css";
@import-normalize;

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal-root {
  position: absolute;
}

.layout {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: var(--main-background-color);
  flex-direction: column;
  bottom: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  line-height: var(--line-height);
}

.App {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  display: block;
  //height: 100%;
  color: var(--primary-text-color);
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 400;
  padding: 0;
  height: calc(100vh - var(--header-height));
  //overflow: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.filters-header-row-wrap {
  //height: 60px;
  //padding-top: 17px;
  //outline: 1px solid red !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.highlight-text-wrap {
  background: transparent;
  color: #607aff;
  font-weight: 600;
}
.findings-page-wrapper {
  min-width: 1024px;
  overflow: auto;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  src: local("Open Sans"),
    url(./fonts/Open_Sans/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  src: local("Open Sans"),
    url(./fonts/Open_Sans/OpenSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  src: local("Open Sans"),
    url(./fonts/Open_Sans/OpenSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  src: local("Open Sans"),
    url(./fonts/Open_Sans/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 800;
  src: local("Open Sans"),
    url(./fonts/Open_Sans/OpenSans-ExtraBold.ttf) format("truetype");
}

/* Heebo */
@font-face {
  font-family: "Heebo";
  font-weight: 100;
  src: local("Heebo"), url(./fonts/Heebo/Heebo-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Heebo";
  font-weight: 200;
  src: local("Heebo"),
    url(./fonts/Heebo/Heebo-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Heebo";
  font-weight: 300;
  src: local("Heebo"), url(./fonts/Heebo/Heebo-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Heebo";
  font-weight: 400;
  src: local("Heebo"), url(./fonts/Heebo/Heebo-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Heebo";
  font-weight: 500;
  src: local("Heebo"), url(./fonts/Heebo/Heebo-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Heebo";
  font-weight: 600;
  src: local("Heebo"), url(./fonts/Heebo/Heebo-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Heebo";
  font-weight: 700;
  src: local("Heebo"), url(./fonts/Heebo/Heebo-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Heebo";
  font-weight: 800;
  src: local("Heebo"), url(./fonts/Heebo/Heebo-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Heebo";
  font-weight: 900;
  src: local("Heebo"), url(./fonts/Heebo/Heebo-Black.ttf) format("truetype");
}
