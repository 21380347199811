.saml-settings-wrapper {
  .sub-title {
    color: var(--app-header-color);
    font-size: var(--font-size-l);
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 20px;
  }

  & {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .saml-setup {
    width: 40%;
  }

  .saml-right-side {
    flex-grow: 1;
  }
}
