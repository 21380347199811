@import "../../../../../constants";

.filter-row-selected-value {
  border-radius: 0px 8px 8px 0px;
  @include filter-item-style;
  @include ellipsis(140px);
  line-height: 32px;

  .selected-filter-value {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selected-value-title {
      @include ellipsis(100%);
    }
  }
  &:hover {
    //max-width: 200px;
  }
}
.filter-dropdown-wrap {
  .filter-value-options-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 10px var(--filter-menu-item-paddings) 0
      var(--filter-menu-item-paddings);
    min-width: 173px;
    max-height: 180px;

    .checkbox-list-options-container {
      .checkbox-list-option {
        padding: 0;
        height: 33px;
        line-height: unset;
        padding-right: var(--filter-menu-item-paddings);

        .checkbox-input {
          height: 100%;
        }
      }
    }
    .loader-wrap {
      height: 80px;
      .loader-icon {
        margin-top: 0;
        margin-bottom: 0;
        img {
          width: 30px;
          height: 32px;
        }
      }
    }

    .menu-no-results {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
    }
    .tags-select {
      padding: 5px 8px;
    }
    .tags-select .tags-select__search .tags-select__search-input input {
      width: 100%;
    }
  }
}
