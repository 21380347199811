.queue-activity-header {
  display: flex;
  justify-content: space-between;

  .remediation-queue-title {
    width: 100px;
  }

  .activity-level-data {
    display: flex;
    flex-direction: column;

    .level-of-activity-title {
      color: var(--findings-header-color);
    }

    .level-of-activity-data {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .level-value {
        font-size: var(--font-size-m);
        color: #26026c;
        font-weight: 500;
      }
    }
  }
}