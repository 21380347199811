//load icon font
@import "icons/seem-icons/variables";

@function unicode($str) {
  @return unquote('"') + $str + unquote('"');
}
//end icon font

/* mixins */

@mixin ellipsis($max-width) {
  display: inline-block;
  max-width: $max-width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin line-clamping($max-lines) {
  --max-lines: 2;
  position: relative;
  max-height: calc(var(--max-lines) * var(--line-height));
  overflow: hidden;
  padding-right: 1px; /* space for ellipsis */
  display: block;

  &::before {
    position: absolute;
    content: "...";
    bottom: 0; /* "bottom" */
    right: 0; /* "right" */
  }
  &::after {
    content: "";
    position: absolute;
    right: 0; /* "right" */
    width: 1rem;
    height: 1rem;
    background: white;
    margin-top: -0.8rem;
  }
}
@mixin table-header-cell {
  color: var(--primary-text-color);
  font-size: var(--font-size-xs);
  font-weight: 500;
}
@mixin table-cell {
  font-size: var(--font-size-s);
  font-weight: 400;
  color: var(--primary-text-color);
}

@mixin group-colors($styleAttribute) {
  $default: (
    0: #f44236,
    1: #e91e63,
    2: #9c27b0,
    3: #673ab7,
    4: #3f51b5,
    5: #2396f3,
    6: #03a9f4,
    7: #00bcd4,
    8: #039588,
    9: #4caf50,
    10: #8bc34a,
    11: #ccdc39,
    12: #ffeb3b,
    13: #ffc107,
    14: #ff9900,
    15: #ff5722,
    16: #795548,
    17: #9e9e9e,
    18: #557e9e,
    19: #455a64,
  );

  $severity: (
    0: var(--low-alert-color),
    1: var(--medium-alert-color),
    2: var(--high-alert-color),
    3: var(--critical-alert-color),
  );

  &[class*="group-default-"] {
    #{$styleAttribute}: purple;
  }

  &[class*="group-severity-"] {
    #{$styleAttribute}: purple;
  }

  @each $groupId, $color in $default {
    &.group-default-#{$groupId} {
      #{$styleAttribute}: $color;
    }
  }

  @each $groupId, $color in $severity {
    &.group-severity-#{$groupId} {
      #{$styleAttribute}: $color;
    }
  }
}

@mixin bubble-style {
  padding: 5px 10px;
  border-radius: var(--border-radius-s);
  box-shadow: var(--box-shadow);
  border: 1px solid var(--main-border-color);
  background: #fff;
  color: var(--primary-text-color);
  font-size: var(--font-size-s);
  &:after {
    transform: translateY(-1px) scale(1.2);
  }
}

// This mixin adds the triangle corner to a box, usually used in our dropdown menu's.
// To use provide side(left/right), distance from corner (in pixels), size of triangle (in pixels)
@mixin bubble-top($side, $distance, $size) {
  overflow: visible;
  position: absolute;
  @include bubble-style;
  &:after,
  &:before {
    position: absolute;
    content: " ";
    border-bottom: none;
    border-right: none;
    z-index: -1;
    bottom: 100%;
    border: 1px solid transparent;
    pointer-events: none;
    height: 0;
    width: 0; // sets distance from left or right
    @if $side == "left" {
      left: $distance;
    } @else if $side == "right" {
      right: $distance;
    }
  }
  &:after {
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: #fff;
    border-width: calc(#{$size} - 2px);
    z-index: 2;
    @if $side == left {
      margin-left: calc(#{-$size} + 2px);
    } @else if $side == right {
      margin-right: calc(#{-$size} + 2px);
    }
  }

  &:before {
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: var(--main-border-color);
    border-width: $size;
    z-index: 1;
    @if $side == left {
      margin-left: -$size;
    } @else if $side == right {
      margin-right: -$size;
    }
  }
}

@mixin tag-shape($background-color, $color) {
  border-radius: var(--border-radius-m);
  //max-width: 36px;
  //min-width: 36px;
  //max-height: 30px;
  //min-height: 30px;
  height: 30px;
  background-color: $background-color;
  color: $color;
  text-align: center;
  padding: 3px 5px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin round-shape($background-color, $color) {
  border-radius: 200px;
  width: 30px;
  height: 30px;
  background-color: $background-color;
  color: $color;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

@mixin box-style {
  border-radius: var(--border-radius-s);
}

@mixin box-padding {
  padding: 14px 10px;
}

/* forms */
@mixin input-wrap {
  display: flex;
  position: relative;
  color: var(--input-color);
  font-size: var(--input-font-size);
  font-weight: var(--font-weight-normal);
  //margin-top: var(--input-margin-top);
  margin-bottom: var(--input-margin-bottom);
}

@mixin input-label {
  //width: var(--input-label-width);
  //color: var(--input-label-color);
  font-size: var(--font-size-s);
  flex-shrink: 0;
  //min-height: var(--input-height);
  //line-height: var(--input-height); // centers the text in the component
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  padding-right: 15px;
  padding-top: 8px;
  height: 28px;
  line-height: 1;
}

@mixin bomba-style-label {
  font-size: var(--font-size-s);
  color: var(--primary-main-color);
  font-weight: 500;
  margin-bottom: 5px;
}
@mixin mini-label {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-normal);
  color: var(--input-color);
}
@mixin input-holder {
  position: relative;
  border-radius: var(--input-border-radius);
  border: var(--input-border);
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--input-color);
  background: #fff;
  height: var(--input-height);
  margin-bottom: var(--input-margin-bottom);
  margin-bottom: 0;

  &.focused,
  &:focus {
    border: var(--input-border--focused);
  }
  &:hover {
    box-shadow: var(--input-box-shadow);
  }
  input {
    border: none;
    outline: none;
    background: transparent;
  }
}
@mixin animate-rotate {
  animation-iteration-count: infinite;
  animation-duration: 0.5s;
  animation-name: loaderRotate;
  animation-timing-function: linear;
}
@mixin animate-appear-from-top($seconds) {
  animation: animation-appear-from-top $seconds ease-out;
}
@mixin animate-appear-from-left($seconds) {
  animation: animation-appear-from-left $seconds ease-in-out;
}
@mixin animate-appear-from-right($seconds) {
  animation: animation-appear-from-right $seconds ease-in-out;
}
@mixin animate-fade-in($seconds) {
  animation: animation-fade-in $seconds ease-in-out;
}
@mixin animate-fade-out($seconds) {
  animation: animation-fade-out $seconds ease-in-out;
}
@mixin animate-position {
  transition: transform ease-out 0.1s;
}
@mixin animate-color {
  transition: color ease-out 0.1s, background ease-out 0.1s,
    background-color ease-out 0.1s;
}

@mixin animate-opacity {
  transition: opacity ease-out 0.1s;
}

@keyframes animation-appear-from-top {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes animation-appear-from-left {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes animation-exit-from-left {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(-10px);
  }
}
@keyframes animation-appear-from-right {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes animation-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animation-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes loading-dots {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes loaderRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bouncing {
  0% {
    transform: translateY(0px);
  }
  35% {
    transform: translateY(0px);
    opacity: 0.3;
  }
  50% {
    transform: translateY(-6px);
    opacity: 0.8;
  }
  70% {
    transform: translateY(2px);
    opacity: 0.6;
  }
  85% {
    transform: translateY(0px);
  }
}

@keyframes bouncing-quick {
  0% {
    transform: translateY(0px);
    opacity: 0.3;
  }
  15% {
    transform: translateY(-6px);
    opacity: 0.8;
  }
  35% {
    transform: translateY(2px);
    opacity: 0.6;
  }
  55% {
    transform: translateY(0px);
  }
}

@keyframes rotate-forever {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes loading-animation {
  0% {
    background-position: 150% 0;
  }

  100% {
    background-position: -150% 0;
  }
}
@keyframes loaderRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@mixin loading-row($width, $height) {
  position: absolute;
  height: $height;
  width: $width;
  animation: none;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: loading-animation;
  animation-timing-function: linear;
  background: #f0f0f0;
  background-image: linear-gradient(
    to left,
    #f9f9fb 0%,
    #f0f0f0 20%,
    #f9f9f9 40%,
    #f0f0f0 100%
  );
  background-image: linear-gradient(
    to right,
    #f6f6f6 8%,
    #f0f0f0 18%,
    #f6f6f6 33%
  );
  background-repeat: no-repeat;
  background-size: 200% auto;
}
@mixin animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}
@mixin animated-border {
  transition: border ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
///////////////////////////
// Filter styles - Start //

@mixin filter-item-style {
  background: #f0f0f3;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background ease-in-out 0.15s;
  user-select: none;

  &:hover {
    background: #dddde7;
  }
  &.is-active {
    background: #607aff;
    color: #fff;
    i.remove-filter-icon {
      color: #fff;
    }
  }
  &.is-empty {
    border-radius: 8px;
  }
  &.is-disabled {
    background: #f0f0f3;
    cursor: default;
  }
  &.is-disabled {
    color: #8c8c96;
    cursor: default;
  }
}
// This style is for the entire dropdown wrap
@mixin filter-dropdown-wrap-style {
  display: flex;
  //background: red;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
// This style is for each item wrap
@mixin filter-dropdown-item-wrap-style {
  padding: 6px var(--filter-menu-item-paddings) 6px
    var(--filter-menu-item-paddings);
  width: 100%;
  transition: background ease-in-out 0.15s;
  cursor: pointer;
  flex: 0 0 36px;
  height: 36px;
  @include ellipsis(100%);
  &:first-child {
    margin-top: 8px;
  }
  &:last-child {
    margin-bottom: 8px;
  }
}

// This style is for the item test itself
@mixin filter-dropdown-item-style {
  height: 24px;
  vertical-align: middle;
  padding-top: 4px;
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-main-color);
  @include ellipsis(100%);
  .checkbox-label {
    height: 22px;
    padding-top: 4px;
    font-size: 18px;
    .checkbox-label-data {
      padding-left: 13px;
      font-size: var(--font-size-s);
    }
  }
}

// Filter styles - End //
/////////////////////////

@mixin dropdown-loading {
  opacity: 1;
  display: flex;
  align-self: center;
  //font-size: 4px;
  height: 5px;
  line-height: 5px;
  margin-right: 4px;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  color: var(--primary-text-color);
  padding: 0;
  position: relative;
  left: 10px;
  width: 18px;
  justify-content: space-between;
  transition: opacity ease-in-out 0.15s, color ease-in-out 0.15s;

  .loading-dot {
    animation: loading-dots 1s ease-in-out 0ms infinite;
    background-color: currentColor;
    border-radius: 5px;
    display: inline-block;
    height: 4px;
    vertical-align: middle;
    width: 4px;
    &:nth-child(2) {
      animation: loading-dots 1s ease-in-out 160ms infinite;
    }
    &:nth-child(3) {
      animation: loading-dots 1s ease-in-out 320ms infinite;
    }
  }
}

@mixin tip($left-margin) {
  position: relative;
  &:before {
    top: 100%;
    content: " ";
    display: block;
    height: 20px;
    left: calc(50% + #{$left-margin - 2px});
    position: absolute;
    border-color: var(--border-color) transparent transparent transparent;
    border-style: solid;
    border-width: 12px;
  }

  &:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid var(--border-color) 10px;
    border-top: solid #fff 10px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: $left-margin;
    position: absolute;
    width: 0;
    top: 100%;
  }
}
