@import "../../../../constants";

.finding-comments-list {
  .finding-comment-wrapper {
    display: flex;
    flex-direction: column;
    margin: 12px 0;
    color: var(--input-color);
    font-size: var(--font-size-s);

    .seem-icon.seem-icon-bin {
      opacity: 0;
    }
    &:hover {
      .seem-icon.seem-icon-bin {
        opacity: 1;
        transition: var(--transition);
      }
    }

    .finding-comment-content-wrapper {
      margin-top: 6%;
      display: flex;
      flex-direction: row;

      .user-avatar {
        margin-right: 8px;
      }

      .mce-content-body,
      .finding-comment-content {
        border-radius: var(--border-radius-s);
        width: 100%;

        .input-wrap.is-disabled {
          .mce-content-body {
            border: none;
            background-color: var(--main-background-color);
            color: var(--input-color) !important;
          }
        }

        .input-wrap.is-disabled .input-holder {
          border: 0;
        }
        .ck.ck-editor__main {
          background: var(--main-background);
        }
        .status-marker {
          border-radius: var(--border-radius-s);
          color: white;
          padding: 1px 3px;
          font-weight: 500;
        }

        .marker-arrow {
          background-color: transparent;
          font-weight: 600;
        }

        .marker-open-status {
          background-color: var(--jira-backlog-color);
          border-radius: var(--border-radius-s);
          color: white;
          padding: 1px 5px;
          font-weight: var(--font-weight-semibold);
        }

        .marker-ignored-status {
          background-color: var(--jira-in-progress-color);
          border-radius: var(--border-radius-s);
          color: white;
          padding: 1px 5px;
          font-weight: 500;
        }

        .marker-fixed-status {
          background-color: var(--jira-done-color);
          border-radius: var(--border-radius-s);
          color: white;
          padding: 1px 5px;
          font-weight: 500;
        }

        .marker-reviewed-status {
          background-color: var(--finding-open-confirmed);
          border-radius: var(--border-radius-s);
          color: white;
          padding: 1px 5px;
          font-weight: 500;
        }
      }
    }
    .comment-wrap {
      width: 100%;
    }
    .finding-comment-data {
      display: flex;
      justify-content: space-between;
      padding: 0;

      .comment-creation-data {
        font-size: var(--font-size-s);
        display: flex;
        justify-content: space-between;
        color: #9494a2;

        .comment-username {
          flex: 0 1 auto;
        }
        .comment-post-time {
          flex: 0 1 auto;
        }

        .ticket-logo {
          flex: 0 1 auto;
          margin-left: 5px;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: contain;
          cursor: auto;
        }

        .comment-time-operations {
          display: flex;
          align-items: center;

          .comment-last-updated {
            text-align: end;
            font-size: var(--font-size-xs);
            width: 100px;
            color: var(--input-color-disabled);
            margin-right: 10px;
          }

          .seem-icon-more-actions {
            color: var(--input-color);
          }
        }
      }
    }
  }
  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only {
    background: var(--main-background-color);
    border: 0;
    padding: var(--padding-xs);
    height: auto;
    font-size: var(--font-size-s);
    margin-top: 5px;
  }
  .ck.ck-editor__editable_inline > p {
    padding: 0;
    margin: 0;
    line-height: var(--font-size-s);
  }
}
.finding-comment-content {
  .input-wrap.is-disabled .input-holder {
    border: 0;
  }
}

.tootip-ticket-manager {
  padding: 6px;
  font-size: 12px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  z-index: 99999;
}
