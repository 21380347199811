@import "../constants";

.app-view-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  min-height: 64px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  padding: 0 var(--page-padding);

  .scope-and-filters-header {
    display: flex;
    position: relative;
    width: 100%;
    padding-top: 12px;
    z-index: 1;
    .scope-management-wrap {
      flex: 0 0 var(--scope-management-wrap-width);
      height: 40px;
    }
    .app-view-separator-wrap {
      display: flex;
      align-items: center;
      padding: 0 15px 12px 15px;
      flex: 0 0 31px;
      .app-view-separator {
        width: 1px;
      }
    }
    .filters-management-wrapper {
      position: relative;
      flex: 1 1 auto;
      width: calc(100% - var(--scope-management-wrap-width) - 142px);
      .form-button-group {
        padding-top: 6px;
      }
    }
    .header-icon-button {
      display: flex;
      padding: 12px 8px;
      align-items: flex-start;
      margin-left: 8px;
    }
    .filters-management-wrapper:hover {
      .filter-separator {
        border-top: 1px solid var(--secondery-main-color);
        border-bottom: 1px solid var(--secondery-main-color);
      }
    }
  }
  .scope-management-container {
    width: 296px;
  }
  .app-view-separator {
    width: 1px;
    height: 100%;
    background-color: #d9d9de;
  }
}
.filter-rows-wrap {
  display: flex;
  width: 100%;
  padding: 3px 0 0 0;
  position: relative;
}
.filter-panel-data {
  display: flex;
  min-height: 39px;
  border-top: var(--input-border);
  border-right: var(--input-border);
  border-bottom: var(--input-border);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 3px;
  flex: 1 0 auto;
  justify-content: space-between;
  color: var(--primary-main-color);
  font-size: var(--font-size-s);
  width: calc(100% - 200px);
  overflow: auto;
  margin-right: 15px;

  .filter-rows {
    display: flex;
  }
}
.filters-panel-form-wrapper .form-button-group {
  .form-button-group {
    width: 200px;
  }
}

.toggle-filters-panel-wrap {
  display: flex;
  width: 54px;
  height: 12px;
  position: absolute;
  left: 50%;
  transition: opacity ease-in-out 0.15s;
  transform: translate(-50%, 0);
  top: 64px;
  background: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 0 0;
  box-shadow: 0px 2px 3px #ddd;
  z-index: 9;

  .toggle-filters-panel {
    width: 54px;
    transition: top ease-in-out 0.15s, transform ease-in-out 0.15s;

    .form-button {
      width: 100%;
    }
    .seem-icon {
      transition: transform ease-in-out 0.15s;
      transform: rotate(0deg);
    }
  }
  .form-button-wrap.is-xs.is-icon .form-button .seem-icon {
    padding: 0;
  }
}

/* !!! LAYOUT FILTERS OPEN !!! */

.layout-filters-short {
  .toggle-filters-panel-wrap {
    opacity: 0;
    top: -200px;
  }
}
.layout-filters-long {
  .app-view-wrapper-placeholder {
    display: block;
    position: relative;
  }
  .app-view-wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 10;
    height: auto;
    transition: min-height ease-in-out 2s, height ease-in-out 2s;
  }
  .filters-management-wrapper {
  }
  .filters-menu-header {
    height: 100%;
  }

  .toggle-filters-panel-wrap {
    top: calc(100% + 5px);
    opacity: 1;
    transform: translate(-50%, -6px);
    .seem-icon {
      transform: rotate(180deg);
    }
  }
}

.filters-toggle-open {
  .scope-and-filters-header {
    //background: pink;
    height: unset;
  }
}

.filters-toggle-closed {
  .app-view-wrapper {
    min-height: unset;
    max-height: 67px;
    max-height: 64px;
    z-index: 3;

    transition: min-height ease-in-out 2s, height ease-in-out 2s;
  }
  .toggle-filters-panel {
    transform: rotate(180deg);
    transform-origin: center;
  }
  .filters-management-wrapper {
    //height: 52px;
  }
}
//temporary remove
.app-view-wrapper:hover {
  min-height: 64px;
  max-height: unset;
  .scope-and-filters-header {
    height: unset;
  }
}

.dashboard-wrapper .app-view-wrapper {
  min-height: 64px;
  max-height: unset;
  .scope-and-filters-header {
    height: unset;
  }
}
.filters-toggle-closed {
  .dashboard-wrapper .app-view-wrapper {
    max-height: 67px;
    &:hover {
      max-height: unset;
    }
  }
}

.layout-filters-long.filters-menu-active {
  .app-view-wrapper {
    min-height: 64px;
    max-height: unset;
    .scope-and-filters-header {
      height: unset;
    }
  }
}
