@import "../../../constants.scss";

.popup-dialog-container {
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
  width: 100vw;
  height: 100vh;
  display: block;
  z-index: 99999999999;
  background: rgba(0, 0, 0, 0.15);

  .popup-dialog-overlay {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .popup-dialog-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.warning {
      width: 575px;
      .popup-dialog-text {
        padding: 10px 40px 0;
        font-size: var(--font-size-s);
      }
    }
  }

  .popup-dialog-content {
    background: #fff;
    border: 1px solid #ccc;
    min-height: 100px;
    flex-flow: column nowrap;
    justify-content: space-between;
    display: flex;
    border-radius: var(--border-radius-s-m);
    flex-flow: column nowrap;
    min-width: 400px;
    padding: 0;
  }

  .confirmation-dialog-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    font-weight: 600;
    color: var(--primary-main-color);
    border-bottom: 1px solid var(--main-border-color);
    margin: 0 var(--padding-l);
    height: 80px;
    align-items: center;

    .confirmation-dialog-title {
      color: var(--title-color);
      font-size: var(--font-size-l);
      font-weight: 500;

      [class^="seem-icon"] {
        margin-right: 10px;
      }
    }
  }

  .confirmation-dialog-character-container {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    height: 160px;
    padding-top: 20px;

    &.warning {
      padding: 0px 28px 0px 25px;
      height: 88px;
    }
  }

  .popup-dialog-text {
    font-size: var(--font-size-m);
    text-align: center;
    padding: 35px 40px 0;
    color: var(--primary-main-color);
  }
}
.popup-dialog-buttons {
  margin-top: 34px;
  margin-bottom: 45px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  .form-button-wrap {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}
