.modal-fields .modal-content {
  .endpoint-list {
    height: 560px;
    overflow: scroll;
  }
}

.rmq-ticket-settings-wrapper {
  .rmq-ticket-settings-keys {
    min-height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .endpoint-keys {
      width: 100%;
    }
  }
}
