@import "../../../../constants";

@mixin rmq-input-label {
  font-size: var(--font-size-m);
  color: var(--title-color);
  font-weight: 500;
}
.rmq-settings-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .rmq-name-input {
    margin-bottom: 15px;
    .input-label {
      @include rmq-input-label;
    }
  }
  .rmq-settings-prioritize-wrapper {
    width: 622px;
    position: absolute;
    top: 0;
    right: 5px;
    height: 44px;
  }

  .rmq-settings-panel-wrapper {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding-top: 0;
    padding-bottom: 20px;
    min-height: 110px;
    min-height: 290px;
    position: relative;
    justify-content: space-between;

    .rmq-settings-panel-title {
      @include rmq-input-label;
    }
    .rmq-settings-panel-title-to {
      @include mini-label;
    }
    .rmq-settings-input-panel {
      width: calc(50% - 40px);
      flex: 0 0 calc(50% - 40px);
      min-width: 550px;
      max-width: 742px;
      display: flex;
      flex-flow: column nowrap;

      .filter-panel-data {
        width: 100%;
      }
      .findings-table-filters {
        padding: 0;
      }
      .findings-table-filters .findings-table-filters-left {
        height: auto;
      }
      .select-input-menu.filter-menu-wrap .select__menu {
        width: 120%;
      }
      &:nth-child(1) {
        @include animate-fade-in(0.35s);
      }
      .rmq-settings-output-panel {
        @include animate-fade-in(0.35s);
        animation-delay: 0.4s;
      }

      //new style
      //new style
      //new style

      .old-rmq-scope-and-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //background: lightcyan;
        flex-flow: column nowrap;
        margin-top: 10px;
        .scope-management-wrap {
          width: 100%;
          margin-bottom: 5px;
          .selected-scope-data {
            width: 100%;
          }
        }
        .scope-management-container {
          width: 100%;
        }
        .filters-management-wrapper {
          height: unset;
        }
        .modal-buttons-container {
          margin-top: 12px;
          margin-bottom: 12px;
          width: 100%;

          .form-button-wrap:nth-child(2) {
            margin-left: auto;
          }
        }
        .filters-management-wrapper {
          width: 100%;
          flex-flow: column nowrap;
        }
        .filters-menu-header {
          width: 100%;
          flex: 1 1 auto;
          height: 40px;
          border: var(--border-style);
          //border-bottom:;
          //margin-right: 0;
          padding-right: 0;
          border-top-left-radius: var(--input-border-radius);
          border-top-right-radius: var(--input-border-radius);
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          border-bottom: var(--border-style);
          .dropdown-indicator {
            padding-right: 17px;
            margin: 0;
          }
          &:after {
            content: none;
          }
        }
        .filters-panel-form-wrapper {
          padding-top: 1px;
          min-height: unset;
          height: 40px;
          .filter-panel-data {
            //background: lightblue;
            border: var(--input-border);
            border-top: 0;
            border-bottom-left-radius: var(--input-border-radius);
            border-bottom-right-radius: var(--input-border-radius);
            border-top-right-radius: 0;
            margin-right: 0;
          }
        }
        .rmq-settings-input-panel {
          height: 100%;
        }
        .filter-buttons-wrap .form-button-wrap.is-icon .form-button {
          width: unset;
        }
      }
    }
    .rmq-settings-separator-wrap {
      position: relative;
      width: 80px;
      flex: 0 0 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 0 5px 0;
      @include animate-fade-in(0.35s);
      animation-delay: 0.2s;
    }

    .rmq-settings-separator {
      background: var(--border-color);
      width: 1px;
      height: 100%;
    }
    .rmq-settings-separator-arrow {
      background: #fff;
      color: var(--border-color);
    }
    .seem-icon-next-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 22px;
      background: #fff;
      padding: 10px;
      color: #a4a5a7;
    }
    .rmq-settings-output-panel {
      width: calc(50% - 40px);
      flex: 0 0 calc(50% - 40px);
      .radio-button-list-wrapper {
        margin-top: 2px;
        flex-flow: row wrap;
      }
      &.rmq-settings-output-panel-disabled {
        filter: grayscale(1);
        opacity: 0.5;
        .rmq-settings-panel-title {
          opacity: 0.4;
        }
      }
    }
  }
  .rmq-form-btn-container {
    padding-top: 20px;
    //margin-top: 15px;
    justify-content: flex-end;
    border-top: var(--border-style);
  }
  .queue-operation-settings {
    display: flex;
  }
}

@media screen and (max-width: 1310px) {
  .rmq-settings-wrapper .rmq-settings-panel-wrapper .rmq-settings-input-panel {
    width: 100%;
    min-width: 650px;
  }

  .rmq-settings-wrapper .rmq-settings-panel-wrapper .rmq-settings-output-panel {
    width: 100%;
    min-width: 530px;
    //max-width: 530px;
    padding-top: 10px;
    flex: 1 1 auto;
  }
  .rmq-settings-wrapper
    .rmq-settings-panel-wrapper
    .rmq-settings-separator-wrap {
    display: none;
  }
  .settings-panel-wrapper
    .remediation-queue-container
    .rmq-settings-output-panel {
    .input-wrap.issue-types-menu,
    .input-wrap.project-menu {
      width: 50%;
    }
  }
}
