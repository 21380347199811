.sections {
  .section {
    .section-row {
      width: 100%;
      display: flex;
      margin-top: 8px;

      & > :last-child {
        margin-left: 15px;
      }

      & > :only-child {
        margin-left: 0;
      }

      .section-field {
        &.section-field-large {
          width: 100%;
          padding-right: 15px;
        }

        &.section-field-small {
          width: 50%;
          max-width: 421px;
        }
      }
    }

    .sections-separator {
      margin-top: 15px;
      margin-bottom: 10px;
      border-top: var(--border-style);
      width: 100%;
    }
  }
}
