@import "../application/src/constants";

.integrations-ticket-wrap {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  width: 234px;
  height: 251px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-s);
  overflow: hidden;
  box-shadow: var(--integration-ticket-shadow);

  &:hover {
    box-shadow: var(--integration-ticket-shadow--hover);
    border: 1px solid #b3b6cc;
  }

  .integrations-ticket-footer {
    width: 100%;
    height: 44px;

    .integration-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      &.is-secondary {
        .form-button {
          &:hover,
          &:active {
            color: white;
          }

          &:disabled {
            color: var(--primary-main-color);
            background-color: rgba(65, 66, 90, 0.1);
          }
        }
      }

      .form-button {
        width: 100%;
        height: 100%;
      }
    }
  }

  .integrations-ticket-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 28px;
    padding-bottom: 19px;
    color: var(--primary-text-color);
    border-bottom: 1px solid var(--border-color);

    .integrations-ticket-img {
      background-size: contain;
      background-repeat: no-repeat;
      height: 50px;
      width: 150px;
      margin-bottom: 19px;
      background-position: center;
    }

    .integrations-ticket-source {
      font-weight: 600;
      vertical-align: top;
      font-size: small;
    }
  }

  .integrations-ticket-content {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    align-items: center;
    padding: 12px 16px;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--border-color);

    .integrations-ticket-prop {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: var(--font-size-xs);

      .integrations-ticket-def {
        font-weight: 500;
      }

      .integrations-ticket-value {
        display: flex;
        align-items: center;

        .not-connected {
          opacity: 0.6;
        }

        .status-indicator {
          margin-right: 5px;
          width: 6px;
          height: 6px;
          border-radius: 50%;

          &.is-enabled {
            background-color: var(--low-alert-color);
          }

          &.is-disabled {
            background-color: #c6c6ce;
          }
        }
      }
    }
  }
}
