@import "../../../constants.scss";

.input-wrap-text-area {
  .input-holder {
    min-height: 36px;
    height: auto;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 4px 0 4px 10px;
    textarea {
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      resize: none;
      border: none;
      outline: none;
      margin: 0;
    }
  }
}
