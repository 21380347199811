@import "../../../../constants";

.findings-list-item-wrapper {
  background-color: var(--table-row-color);
  border-bottom: solid 1px var(--main-border-color);
  box-shadow: unset;
  transition: var(--transition);
  width: 100%;
  height: var(--findingListRowHeight);

  .finding-item-data table-row {
    height: var(--findingListRowHeight);
  }

  &.is-selectable {
    cursor: pointer;
    &:hover {
      background-color: var(--table-row-color-hover);

      .findings-actions-container {
        opacity: 1;
      }
    }
  }

  &.is-selected {
    background-color: var(--table-row-color-hover);
    border-right: none;
    transform: scale(1, 1);

    .finding-resources-col:before,
    .finding-resources-col:after,
    .finding-title-col:before,
    .finding-title-col:after {
      background: #eff2f5 0 0 no-repeat padding-box !important;
    }
  }
  .finding-title-col-data {
    @include ellipsis(100%);
  }
  .finding-item-data {
    .finding-cloud-account {
      flex: 1 1 auto;
      @include ellipsis(200px);
      margin-left: 15px;
    }

    .finding-resources-col,
    .finding-title-col {
      overflow: hidden;
    }

    .finding-remediation {
      align-items: flex-end;

      .remediation-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .finding-account-col {
      &.with-region {
        flex-direction: column;
      }
    }

    .findings-more-actions {
      flex: inherit;
      justify-content: flex-end;
      margin: 5px 20px 5px 12px;
    }
  }

  .finding-score-age-percentage-wrapper {
    width: 100%;
    height: 4px;
    border-radius: 0 0 0 2px;
    display: flex;
    align-items: flex-end;
  }

  .finding-score-col {
    display: flex;
    align-self: center;
    justify-content: center;
    line-height: 20px;
    padding-left: 10px;
  }

  .finding-resources-col {
    display: flex;
    align-self: center;
    justify-content: center;
    line-height: 20px;
    padding-left: 10px;
  }

  .finding-item-separator {
    background-color: var(--main-border-color);
    height: 2px;
    width: 100%;
  }

  .table-cell {
    position: relative;
    text-align: left;
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    justify-content: flex-start;
    height: var(--findingListRowHeight);
    padding: 0 var(--global-table-col-padding);
    font-size: var(--font-size-xs);
    font-weight: 400;

    &.finding-ticket-status-col {
      .jira-status-container {
        cursor: default;
      }
    }
    &.finding-ticket-status-col {
      width: var(--rmq-col-ticket-status);
      flex: 0 0 var(--rmq-col-ticket-status);

      .ticket-url {
        padding-left: var(--padding-s);
      }
      img {
        height: 20px;
        cursor: auto;
      }
    }
  }
  .findings-actions-container {
    opacity: 0;
    transition: var(--transition);
  }
  .finding-status-col-value {
    @include ellipsis(100%);
  }
  .finding-status-col-progress {
    cursor: auto;
  }
}

.findings-list-item-wrapper {
  .findings-age {
    justify-content: center;
    font-size: var(--font-size-xxs);
  }

  .finding-tags-col {
    align-items: center;
    justify-content: space-between;

    .tags-container {
      // max-width = col_width - 26px (3dots icon) - 20px (paddings) - {x}px (4px space * num of spaces between tags) / {num of tags}
      width: calc(100% - 40px);

      .tag-wrapper {
        flex: 1 1 auto;
      }
      &.has-1-tags,
      &.has-2-tags,
      &.has-3-tags {
        width: 100%;
      }
      &.has-1-tags {
        .tag-wrapper {
          max-width: 100%;
        }
      }
      &.has-2-tags {
        .tag-wrapper {
          max-width: calc(100% / 2);
        }
      }
      &.has-3-tags {
        .tag-wrapper {
          max-width: calc(100% / 3 - 5px);
        }
      }

      &.more-than-3-tags {
        max-width: calc(100% - 30px);
      }
    }

    .tags-popover-wrapper {
      padding-right: 5px;
      padding-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .seem-icon-more-actions {
      color: var(--secondery-main-color);
    }
  }
}

.tags-popover-container {
  background: white;
  border: 1px solid var(--main-border-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-s);
  padding: 12px;
  width: 316px;
  max-width: 316px;
  height: max-content;
  margin-right: 15px;
  @include tip(108px);
}
