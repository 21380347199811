@import "../../../../constants";

.remediation-queue-container {
  &.remediation-queue-overview {
    width: 890px;
    flex: 0 0 890px;
    min-height: 452px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }

  .remediation-queue-overview-header {
    display: flex;
    flex-direction: column;

    .rmq-overview-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .rmq-overview-prioritize-wrapper {
      margin-bottom: 15px;
      align-self: center;
      border: none;
    }
  }

  .remediation-queue-overview-grid {
    display: flex;
    flex-direction: column;

    .grid-row {
      display: flex;
      margin-bottom: 23px;

      & > :not(:last-child) {
        margin-right: 45px;
      }
    }

    .overview-sync-now {
      display: flex;
    }

    .findings-table-filters {
      padding: 0;

      .findings-table-filters-left {
        height: 100%;

        .selected-resource-title {
          color: var(--primary-text-color) !important;
        }

        .select__single-value {
          color: var(--primary-text-color) !important;
        }
      }
    }

    .queue-sync-now {
      color: var(--link-color);
      font-weight: 500;
      margin-left: 5px;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
        color: var(--input-color--disabled);
      }

      &:hover {
        color: var(--link-color--hover);
      }
    }

    .overview-synced-with {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      img {
        height: 22px;
      }
    }

    .overview-status {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .overview-status-title {
      font-size: var(--font-size-xl);
      font-weight: 500;
      color: #26026c;
    }

    .overview-status-title {
      .overview-status-title-num {
        font-size: var(--font-size-xl);
        color: #181818;
      }
      .overview-status-title-text {
        font-size: var(--font-size-s);
        color: #181818;
      }
    }

    .overview-status-chart {
      width: 100%;
      height: 92px;

      .chart-and-legend-wrap {
        height: 92px;
      }

      .chart-wrap {
        min-height: 80px;
      }

      .legend-wrap {
        padding: 0;
        margin-top: -25px;
      }
    }
  }
}

.remediation-queue-overview-sync {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #41425a;
  font-size: 14px;
  font-weight: 500;

  .datatip-wrap {
    display: flex;
  }
  .sync-tooltip {
    padding: 5px 8px;
    font-size: var(--font-size-s);
    font-weight: 400;
  }
}

.remediation-queue-overview-sync-btn-wrap {
  display: flex;
  align-items: center;
  .form-button-wrap {
    margin-left: 15px;
  }
  .remediation-queue-overview-sync-comment {
    color: #acadaf;
    font-weight: normal;
    font-size: var(--font-size-xs);
    margin-right: 15px;
    padding-top: 3px;
    @include ellipsis(100%);
  }
}
@media screen and (max-width: 1439px) {
  .remediation-queue-overview-grid {
    grid-column-gap: 20px;
  }
  .remediation-queue-wrapper .findings-data-area {
    border-right: 1px solid var(--border-color);
  }
  .rmq-queue-data {
    .settings-panel-wrapper {
      flex-flow: row wrap;
      max-height: unset;
    }
  }

  .remediation-queue-container.remediation-queue-overview {
    width: calc(100% - 2 * var(--page-padding));
    flex: 1 1 calc(100% - 2 * var(--page-padding));
    margin: 20px var(--page-padding) 0 var(--page-padding);
    overflow: auto;
  }
  .remediation-queue-container.remediation-queue-activity {
    width: calc(100% - 40px);
    flex: 1 1 calc(100% - 40px);
    min-height: 160px;
    max-height: 380px;
    .remediation-queue-title {
      margin-bottom: 0;
    }
    .loader-wrap.is-center .loader {
      padding: 0;
    }
  }
}

//style to scope and filter inside overview screen

.overview-scope-filter-wrap {
  display: flex;
  flex-flow: row nowrap;

  .scope-management-wrap {
    flex: 0 0 180px;
    width: 180px;
  }
  .scope-management-container {
    width: 100%;
  }
  .filters-menu-header {
    height: auto;
  }
  .scope-management-wrap .scope-management-wrapper .selected-scope-data {
    width: 100%;
  }
  .filters-management-wrapper {
    flex: 1 1 calc(100% - 187px);
    margin-left: 7px;
    height: unset;
  }

  .filter-row-selected-value {
    max-width: 400px;
  }
  .filters-panel-form-wrapper {
    min-height: unset;
    height: 40px;
  }
  .filters-menu-header:after {
    height: 100%;
  }
  .filter-row-selected-value {
    max-width: 400px;
  }
}
.layout-filters-long .remediation-queue-overview-grid {
  .overview-scope-filter-wrap .filters-panel-form-wrapper {
    height: unset;
  }
}
