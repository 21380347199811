@import "../constants.scss";

.remediation-queues-wrapper {
  padding: 0 12px;

  .tabs-menu-wrapper {
    position: relative;
    padding-top: 19px;
  }

  .workflows-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .back-to-all-queues-wrap {
    padding-top: 15px;
  }
  .rmq-title {
    color: var(--title-color);
    font-size: var(--font-size-xl);
    font-weight: 600;
    padding: 20px 0 10px 0;
  }

  .rmq-data-container {
    height: calc(100vh - 176px);
    overflow: auto;

    .no-remediation-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      .no-remediation-msg {
        font-weight: 500;
        font-size: var(--font-size-xl);
        margin-top: 27px;
      }

      .no-remediation-sub-title {
        margin-top: 24px;
        width: 462px;
      }

      .create-rmq-btn {
        margin-top: 24px;
      }
    }
  }
}
