.progress-bar-container {
  display: flex;
  width: 80px;
  margin-top: 4px;
  flex-direction: column;

  .progress-bar-wrap {
    width: 100%;
    height: 5px;
    background-color: #f0f0f3;
    border-radius: var(--border-radius-s);

    .progress-bar-completed {
      border-radius: var(--border-radius-s);
      background-color: var(--secondery-main-color);
      height: 100%;
      transition: width 1s ease-in-out;
    }
  }

  .progress-bar-labels-container {
    display: flex;

    .progress-bar-label {
      display: flex;
      justify-content: flex-end;
      font-weight: 500;
      font-size: var(--font-size-s);
    }
  }

  .progress-bar-legend {
    display: flex;
    margin-top: 20px;

    .progress-bar-legend-item {
      display: flex;
      align-items: center;
      margin-right: 21px;
      font-weight: 500;
      font-size: var(--font-size-s);
    }

    .progress-label-count {
      font-weight: 400;
      margin-left: 2px;
    }

    .legend-item-indicator {
      width: 12px;
      height: 12px;
      border-radius: 2px;
      margin-right: 4px;

      &.completed {
        background-color: var(--secondery-main-color);
      }

      &.total {
        background-color: #f0f0f3;
      }
    }
  }
}

.progress-bar-container {
  &.bomba-style {
    width: 100%;

    .progress-bar-wrap {
      height: 26px;
      border-radius: 0;
      border: 1px solid #e1e1e1;

      .progress-bar-completed {
        border-radius: 0;
        border: 1px solid var(--secondery-main-color);
        height: 26px;
        margin-top: -1px;
        margin-left: -1px;
      }
    }
  }
}
