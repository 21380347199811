@import "../../../../../constants.scss";

.async-progress-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 46px;
  height: 342px;
  color: var(--primary-main-color);

  .seema-wrap {
    height: 50px;
    transform: scale(1.25);
    margin-bottom: 60px;
    padding-top: 10px;
  }
  .progress-bar-container {
    width: 100%;
    margin-bottom: 16px;

    .progress-bar-wrap {
      height: 8px;
    }
  }
  .async-progress-description {
    font-size: var(--font-size-s);
    font-weight: 500;
    margin-bottom: 57px;
    color: var(--primary-main-color);
  }
  .async-progress-indication {
    font-size: var(--font-size-xs);
    font-weight: 500;
    margin-bottom: 89px;
    color: var(--primary-main-color);
  }
}
