@import "../../../constants";

.mapping-field {
  .mapping-field-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .mapping-field-cell-from {
      width: 172px;
    }

    .mapping-field-cell-to {
      width: 308px;

      .input-wrap {
        margin-bottom: 0;
      }
    }

    &.mapping-data {
      .mapping-field-cell-from {
        background: var(--main-background-color);
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 32px;
        margin-right: 12px;
        border-radius: var(--border-radius-s);
        font-size: var(--font-size-s);
      }

      .seem-icon-next-arrow {
        font-size: var(--icon-size-xs);
        color: var(--light-text-color);
        margin-right: 12px;
      }

      .cell-icon {
        width: 8px;
        height: 8px;
        margin-right: 8px;
      }
    }

    &.mapping-title {
      @include bomba-style-label;
      display: flex;
      align-items: center;

      .mapping-field-cell-to {
        &.mapping-title {
          margin-left: 35px;
        }
      }

      .to-icon,
      .from-icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }
}
