@import "../../../constants";

.dynamic-mapping-field {
  .title {
    @include bomba-style-label;
    margin-top: 10px;
  }

  .row {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    .dynamic-mapping-field-inputs {
      display: flex;
      width: 100%;
      > div {
        flex: 0 0 50%;
      }
      .dynamic-mapping-field-to {
        display: flex;
        align-items: center;
        margin-left: 15px;

        .remove-dynamic-mapping-field {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 38px;
          margin-bottom: 13px;
        }
      }
    }
  }

  .add-dynamic-mapping-field {
    &.is-hidden {
      display: none;
    }
  }
}
