@import "../../constants.scss";

.loader-wrap {
  width: 300px;
  height: 200px;
  background: #fff;
  color: #9c9c9c;

  .loader-icon-wrap {
    margin-bottom: 20px;
    .seem-icon {
      font-size: var(--icon-size-xxxxl);
      color: #9c9c9c;
    }
  }

  &.is-center {
    position: relative;
    width: calc(100% - 5px);
    height: 100%;
    margin: 0 auto;
    text-align: center;

    .loader {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: var(--padding-m);
    }
    .loader-icon {
      margin-bottom: 30px;
    }
  }

  &.flexcenter {
    width: 100%;

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      .loader-icon {
        margin-top: 58px;
        margin-bottom: 34px;
        text-align: center;
      }

      .title-text {
        text-align: center;
      }

      .subtitle-text {
        text-align: center;
      }

      .loader-action {
        margin-top: 24px;
      }
    }
  }
  .title-text {
    font-weight: 600;
    font-size: var(--font-size-s);
    color: var(--primary-main-color);
    margin-bottom: 10px;
  }
  .subtitle-text {
    font-weight: 400;
    font-size: var(--font-size-s);
    color: var(--primary-main-color);
    line-height: 1.4;
    margin-bottom: 20px;
  }
  &.is-placeholder {
    .loader-icon-wrap {
      .seem-icon {
        color: #f1f1f1;
      }
    }
  }
}
