@import "../../../constants";

.radio-button-list-option {
  margin-inline-end: 10px;
  min-width: 74px;
}

.radio-button-input-wrap {
  display: flex;
  height: 40px;
  align-items: center;
  .radio-input {
    flex: 0 0 30px;
    width: 30px;
  }
  .radio-button-label {
    font-size: var(--input-font-size);
    cursor: pointer;
  }
  .selection-img {
    cursor: pointer;
    height: 20px;
    img {
      height: 20px;
    }
  }

  &.is-custom {
    .input-wrap {
      margin-bottom: 0;
    }
  }

  &.is-disabled {
    filter: grayscale(100%);
    cursor: default;
    .radio-input {
      cursor: default;
    }
    .radio-button-label {
      cursor: default;
    }
    .selection-img {
      cursor: default;
    }

    &.is-active {
      filter: none;
      opacity: 0.4;

      .radio-input {
        cursor: default;
      }
    }
  }
  &.has-left-img {
    &.is-disabled {
      border-color: var(--input-border-color--disabled);
      opacity: 0.33;
    }
  }
}
