@import "../../constants";

.widgets-grid-sla {
  .dashboard-widget-wrapper {
    &.sla-expected-workload-widget {
      .widget-body {
        min-height: 350px;
      }
    }
  }
  .sla-avg-fix-by-severity-widget {
    .widget-body {
      padding-left: var(--widgets-padding);
      padding-right: var(--widgets-padding);
    }
  }
  .sla-fixed-findings-widget,
  .sla-compliance-widget {
    .legend-data-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 40px 0 0 0;
      flex: 1 1 40%;
    }

    .chart-wrap {
      flex: 1 1 60%;
      height: 100%;

      > div {
        top: -20px;
      }
    }
    .chart-and-legend-wrap {
      flex-direction: row;
      align-items: flex-start;

      .legend-wrap {
        height: 108px;
      }
    }
  }

  .sla-fixed-findings-widget {
    .chart-and-legend-wrap {
      .legend-wrap {
        height: 70px;
      }
    }
  }
}
.sla-fixed-findings-widget,
.sla-compliance-widget {
  .legend-data-container .legend-title {
    color: #41425a;
    margin-left: 10px;
  }
}
@media screen and (max-width: 1745px) {
  .sla-avg-fix-by-severity-widget {
    min-height: 388px;
  }
}
@media screen and (max-width: 1439px) {
  .sla-avg-fix-by-severity-widget {
    min-height: unset;
  }
}
@media screen and (min-width: 1980px) {
  .widgets-grid-sla .dashboard-widget-wrapper.sla-expected-workload-widget {
  }
}

@media screen and (max-height: 920px) {
  .fix-time-by-severity-wrapper {
    .sla-item-row .sla-field-wrap {
      height: 34px;
    }
  }
  .widgets-grid-sla {
    .dashboard-widget-wrapper {
      max-height: 387px;
      .widget-body {
        min-height: 250px;
      }
    }
  }
}
@media screen and (min-height: 900px) and (min-width: 1440px) {
  .widgets-grid.widgets-grid-sla {
    height: auto;
  }
}
