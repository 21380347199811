@import "../../../constants";

$finding-resource-table-col-1: 205px;
$finding-resource-table-col-3: 230px;
$finding-resource-table-col-2: calc(
  100% - #{$finding-resource-table-col-3} - #{$finding-resource-table-col-1} -
    20px
);

.finding-resource-table {
  min-height: 65px;
  min-width: 600px;
  overflow: auto;
  .data-table-row {
    .data-table-cell:nth-child(1) {
      width: $finding-resource-table-col-1;
      flex: 1 0 auto;
    }

    .data-table-cell:nth-child(2) {
      flex: 0 0 $finding-resource-table-col-2;
      width: $finding-resource-table-col-2;
    }

    .data-table-cell:nth-child(3) {
      width: $finding-resource-table-col-3;
      flex: 0 0 $finding-resource-table-col-3;
    }
  }
}
