@import "../../../constants.scss";

.input-file {
  display: none;
}

.file-input-label {
  cursor: pointer;
  font-size: 14px;
  color: var(--secondery-main-color);
  font-weight: 500;
}

.remove-file {
  margin-left: 12px;
  color: var(--primary-main-color);
}

.seem-icon-browse:before {
  color: var(--secondery-main-color);
  padding-right: 8px;
}

.is-disabled {
  cursor: default;

  .file-input-label,
  input,
  .seem-icon-browse:before {
    pointer-events: none;
    cursor: default;
    border-color: var(--input-border-color--disabled);
    color: var(--input-color--disabled);
  }
}
