@import "../../../constants";

.additional-data-wrapper {
  background-color: var(--main-background-color);
  padding: var(--padding-xs);

  .additional-data-header {
    display: flex;
    justify-content: flex-end;
  }

  .additional-data-item {
    margin-bottom: 20px;
  }
}
