@import "../../../constants";

.fix-time-by-severity-wrapper {
  height: calc(100% - 2px);
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
  overflow: auto;
}
.severities-wrapper {
  width: 100%;
}
.sla-item-row {
  display: flex;

  .sla-field-wrap {
    height: 40px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-s);

    &.sla-field-title {
      color: var(--primary-main-color);
      font-weight: 500;
      height: 20px;
    }

    &.sla-item-wrapper {
      background: #f9f9fb;
      padding: 0 18px;
      font-weight: 400;
    }

    .sla-field-indicator {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #ddd;
      margin-right: 8px;

      &.sla-field-indicator-low {
        background: var(--low-alert-color);
      }
      &.sla-field-indicator-medium {
        background: var(--medium-alert-color);
      }
      &.sla-field-indicator-high {
        background: var(--high-alert-color);
      }
      &.sla-field-indicator-critical {
        background: var(--critical-alert-color);
      }
    }

    &.sla-avg-fix-time-wrapper {
      position: relative;
      border: 1px solid var(--border-color);
      padding: 0 16px;
      //width: 237px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //background: red !important;
      min-width: 160px;
      flex: 1 1 160px;
      .loading-row {
        width: calc(100% - 30px) !important;
      }
      i {
        &.seem-icon-overdue {
          color: #eb2a45;
          font-size: var(--font-size-xxs);
        }

        &.seem-icon-in-time {
          color: var(--jira-done-color);
          font-size: var(--font-size-m);
        }
      }
    }
  }

  .sla-item-left {
    width: 173px;
    margin-right: 12px;
  }
  .sla-item-right {
    flex: 1 1 auto;
  }
}
.sla-avg-time-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
}
