@import "../../../constants.scss";

.checkbox-input-wrap {
  &.is-disabled {
    cursor: default;
  }
}
.checkbox-input-wrap.checkbox-style {
  position: relative;
  transition: var(--transition);
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 1;
  }
  input {
    //hide the real input but keep it clickable by automation
    z-index: 999;
    position: absolute;
    left: 0px;
    top: 2px;
    width: 100%;
    height: 36px;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox-input + .checkbox-label {
    position: relative;
    padding-left: 25px;
    margin-right: 4px;
    max-width: 100%;
    margin-bottom: 0;
  }
  .checkbox-label-data {
    display: flex;
    align-items: center;
    @include ellipsis(100%);
    line-height: 21px;

    i {
      margin: 0 15px;
      color: var(--secondery-main-color);
    }
  }
  .checkbox-input + label:before,
  .checkbox-input + label:after {
    position: absolute;
    top: 1px;
    left: 6px;
  }

  &.is-unchecked .checkbox-input + label:before {
    background-color: transparent;
    display: block;
    content: unicode($seem-icon-checkbox-unchecked);
    font-family: "seem-icons";
    color: var(--secondery-main-color);
  }

  .checkbox-input:checked + label:after {
    content: unicode($seem-icon-checkbox-checked);
    font-family: "seem-icons";
    color: var(--secondery-main-color);
  }

  &.is-indeterminate .checkbox-input + label:after {
    content: unicode($seem-icon-checkbox-indeterminate);
    opacity: 0.4;
    font-family: "seem-icons";
    color: var(--secondery-main-color);
  }
}

//TOGGLE STYLE

.checkbox-input-wrap.toggle-style {
  position: relative;
  transition: var(--transition);
  //margin-left: 10px;
  padding-top: 8px;

  input {
    //hide the real input but keep it clickable by automation
    position: absolute;
    left: 0;
    top: 7px;
    opacity: 0;
    cursor: pointer;
    background: #ffc0cb;
    z-index: 9999;
    height: 24px;
    width: 44px;
  }

  .checkbox-input + .checkbox-toggle {
    position: relative;
    padding-left: 25px;
    margin-right: 4px;
    width: 44px;
    height: 22px;
    background: #d9d9de;
    max-width: 100%;
    border-radius: 38px;
    @include ellipsis(100%);
    transition: 0.4s;
  }
  .checkbox-label-data {
    display: inline-block;
    margin-left: 4px;
    margin-top: 5px;
    @include ellipsis(100%);
  }
  .checkbox-input + .checkbox-toggle:before,
  .checkbox-input + .checkbox-toggle:after {
    position: absolute;
    top: 0;
    left: 0;
  }
  .checkbox-input:checked + .checkbox-toggle:before {
    display: block;
    content: "";
    background: #607aff;
    width: 44px;
    height: 22px;
    border-radius: 38px;
    transition: background-color 0.4s;
  }
  .checkbox-input + .checkbox-toggle:after {
    content: "";
    top: 2px;
    left: 2px;
    background: #ffffff;
    z-index: 1;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: transform 0.4s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  }
  .checkbox-input:checked + .checkbox-toggle:after {
    content: "";
    background: #ffffff;
    z-index: 1;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transform: translateX(22px);
  }
}
