.endpoint-keys {
  .loading-row {
    position: unset;
    width: 100%;
    height: 36px;
    margin-top: 34px;
  }

  .sections-loader {
    &.error {
      height: 100%;
    }
  }

  &.no-data {
    display: none;
  }
}
