.Popover {
  z-index: 9999;
}

.actions-menu-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: var(--main-button-color--hover);
  }
}
.actions-menu-popover {
  margin-left: -20px;
}
.action-menu-item-wrapper {
  display: flex;
  flex-direction: column;
}

.actions-menu-title {
  font-size: var(--font-size-xs);
  color: var(--primary-main-color);
  font-weight: 500;
  height: 34px;
  display: flex;
  align-items: center;
  border-bottom: var(--border-style);
}

.action-menu-ul {
  width: 200px;
  background-color: white;
  color: var(--primary-main-color);
  font-size: var(--font-size-s);
  cursor: pointer;

  .action-menu-li {
    display: flex;
    align-items: center;
    padding: 12px 18px;
    font-weight: 500;
    height: 42px;
    font-size: var(--font-size-s);
    background: #f9f9fb;
    margin-bottom: 4px;
    transition: background ease-in-out 0.15s;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #e4e7fe;
    }
    &.is-disabled {
      cursor: default;
      background-color: #fff !important;
    }
  }
  &.clean-list-bg {
    .action-menu-li {
      background: transparent;
    }
  }
}
