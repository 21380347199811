.has-error {
  .rc-slider-dot:first-child {
    background: #fa0050;
  }
}
.is-idle {
  &.has-error {
    .rc-slider-dot:first-child {
      background: #fa0050;
    }
  }
  .rc-slider-rail {
    background-color: #ebebeb;
  }

  .rc-slider-track {
    background-color: #ebebeb;
  }

  .rc-slider-mark-text,
  .rc-slider-mark-text-active {
    color: #4d575c;
    font-weight: 500;
  }

  .rc-slider-dot:first-child {
    background: #acacac;
    border: 2px solid #ffffff;
    box-shadow: 0px 2px 2px rgba(44, 48, 67, 0.16);
    width: 10px;
    height: 10px;
  }
  .rc-slider-dot {
    background-color: transparent;
    border: none;
  }
}

.is-active {
  .rc-slider-rail {
    background-color: #ebebeb;
  }

  .rc-slider-track {
    background-color: #607aff;
  }

  .rc-slider-dot {
    background-color: transparent;
    border: none;
  }

  .rc-slider-handle {
    background: #607aff;
    border: 2px solid #ffffff;
    box-shadow: 0px 2px 2px rgba(44, 48, 67, 0.16);
    width: 12px;
    height: 12px;
  }

  .rc-slider-mark-text,
  .rc-slider-mark-text-active {
    color: #4d575c;
    opacity: 0.45;
    font-weight: 500;
  }

  .slider-container {
    &:hover {
      .rc-slider-track {
        background-color: #4c63dd;
      }
      .rc-slider-handle {
        background: #4c63dd;
      }
      .rc-slider-mark-text,
      .rc-slider-mark-text-active {
        color: #4d575c;
        opacity: 0.6;
        font-weight: 500;
      }
    }
  }

  @for $i from 1 through 10 {
    .active-mark-#{$i} {
      &:hover {
        .rc-slider-mark-text-active:nth-child(#{$i}) {
          color: #4c63dd;
        }
      }
      .rc-slider-mark-text-active:nth-child(#{$i}) {
        opacity: 1;
        color: var(--secondery-main-color);
        font-weight: 600;
      }
    }
  }
}
