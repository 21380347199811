.tooltip-wrapper {
  background-color: white;
  border-radius: 4px;
  padding: 5px;
  width: 26px;
  height: 26px;
  text-align: center;
  display: flex;
  align-items: center;
}
