/* All CSS variables */

:root {
  --primary-main-color: #41425a;
  --secondery-main-color: #607aff;
  --secondary-main-hover-color: #e4e7fe;
  --active-color: #000000;
  --primary-text-color: #41425a;
  --primary-text-color--hover: #797a98;
  --light-text-color: #c5c6c7;
  --secondary-text-color: #fff;
  --main-border-color: #ebebeb;
  --findings-header-color: #a4a5a7;
  --filters-main-color: #fff;
  --group-by-panel-background: rgba(65, 66, 90, 0.08);
  --title-color: #26026c;
  --app-header-color: #0e0522;

  --light-grey: #f3f3f3;
  --secondery-hover-color: #4962e2;

  --link-color: var(--secondery-main-color);
  --link-color--hover: #a2b3ff;
  --link-color--focus: #293eab;
  --link-color--active: #293eab;

  //titles
  --light-title-color: #9797a5;
  --details-pane-main-titles-color: #26026c;

  /* button styles */
  --main-button-color: var(--primary-main-color);
  --main-button-background-color: #fff;
  --main-button-border: 1px solid var(--primary-main-color);

  /* Hover style */
  --main-button-color--hover: #8c8c96;
  --main-button-border--hover: 1px solid var(--main-button-color--hover);

  /* disabled style */
  --main-button-color--disabled: #d6d6da;
  --main-button-border--disabled: 1px solid #d6d6da;

  /* secondary button style */
  --secondary-button-color-hover: var(--secondery-hover-color);
  --secondary-button-border-hover: 1px solid #607aff;
  --secondary-button-background-color: var(--secondery-main-color);
  --secondary-button-background-disabled: #d3d8f4;
  --secondary-button-color-disabled: #f7f8fa;

  /* danger button style */
  --danger-button-color: #e02727;

  --danger-button-border-hover: 1px solid #e02727;
  --danger-button-background-color: #fff;
  --danger-button-color-hover: #fff;
  --danger-button-background-color-hover: #e02727;
  --danger-button-background-disabled: #e95555;
  --danger-button-color-disabled: #ccc;

  /* invert button styles */
  --invert-button-color: #fff;
  --invert-button-background-color: var(--secondery-main-color);
  --invert-button-border: 1px solid var(--secondery-main-color);

  /* Hover style */
  --invert-button-color--hover: #fff;
  --invert-button-background-color--hover: var(--secondery-hover-color);
  --invert-button-border--hover: 1px solid var(--secondery-hover-color);

  /* Icon Ghost Button */
  --icon-ghost-button-color: #41425a;
  --icon-ghost-button-background--hover: #f8f8f8;

  --box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  --menu-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  --scope-filter-menu-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  --filter-menu-item-paddings: 12px;
  --scope-management-wrap-width: 240px;
  --widget-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
  --integration-ticket-shadow: 0 1px 2px rgba(232, 233, 240, 1);
  --integration-ticket-shadow--hover: 0 2px 4px rgba(232, 233, 240, 1);
  --details-box-shadow: 0px 0px 52px -15px rgb(0 0 0 / 60%);
  --side-drawer-shadow-s: 0 0 16px rgba(0, 0, 0, 0.16);
  --side-drawer-shadow-l: 0 0 52px -15px rgb(0 0 0 / 60%);
  --box-shadow-large: 0 8px 10px 0px rgba(0, 0, 0, 0.03);
  --box-shadow-prioritize-indicator: 0px 2px 4px rgba(0, 0, 0, 0.08);
  --table-row-box-shadow: 0 1px 3px 0 rgba(63, 63, 68, 0.15),
    0 0 0 1px rgba(63, 63, 68, 0.05);
  --rmq-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);

  --border-radius-xs: 2px;
  --border-radius-s: 4px;
  --border-radius-s-m: 8px;
  --border-radius-m: 15px;
  --border-radius-l: 30px;
  --border-radius-scope-filter: 6px;

  /* severity colors */
  --critical-alert-color: #ec2a3f;
  --high-alert-color: #ff7506;
  --medium-alert-color: #f7bb00;
  --low-alert-color: #21bc89;

  /* sla colors */
  --finding-sla-overdue-color: #ec2a3f;
  --finding-sla-due-soon-color: #fe752a;
  --finding-sla-in-time-color: #26bc8b;

  /* jira status colors */
  --jira-scheduled-color: #555af9;
  --jira-done-color: #2dbf9f;
  --jira-deleted-color: #ccc; // To be decided on
  --jira-backlog-color: #8fc8f3;
  --jira-in-progress-color: #a56af1;
  --jira-rejected-color: #ec2a3f;

  /* finding status colors */
  --finding-risk-accepted-color: #008265;
  --finding-flase-positive-color: #4e495d;
  --finding-open-confirmed: #039dbe;

  --header-height: 60px;
  --findings-filter-panel-min-height: 192px;
  --carousel-wrap-height: 208px;

  --main-background-color: #f9f9fb;
  /*--main-border-color: #EAEDF2;*/
  --table-header-color: #acadaf;
  --table-row-color: #ffffff;
  --table-row-color-hover: #f9f9fb;
  --table-sub-row-color-hover: #ededf0;
  /*--main-text-color: #1e1d1d;*/

  --critical-alert-text-color: #e64a19;
  --high-alert-text-color: #f57c02;
  --medium-alert-text-color: #fbc02c;
  --low-alert-text-color: #699f38;

  --finding-operations-color: #8e979b;

  /* Icon sizes */
  --icon-size-xxxs: 8px;
  --icon-size-xxs: 10px;
  --icon-size-xs: 12px;
  --icon-size-s: 14px;
  --icon-size-m: 16px;
  --icon-size-l: 18px;
  --icon-size-xl: 24px;
  --icon-size-xxl: 34px;
  --icon-size-xxxl: 44px;
  --icon-size-xxxxl: 80px;

  /* Fonts */

  --font-weight-normal: 400;
  --font-weight-semibold: 500;
  --font-weight-bold: 600;
  --font-weight-extra-bold: 700;

  --font-family: "Heebo", sans-serif;
  --font-size-xxs: 10px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 20px;
  --font-size-xl: 22px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 34px;
  --font-size-xxxxl: 44px;

  --line-height: 1.5;
  --transition: transform ease-in-out 0.1s, opacity ease-in-out 0.1s,
    background-color ease-in-out 0.1s, background ease-in-out 0.1s,
    color ease-in-out 0.1s, box-shadow ease-in-out 0.1s,
    border-color ease-in-out 0.1s;

  /* forms */

  /* input */
  --input-font-size: var(--font-size-s);
  --input-height: 38px;
  --input-margin-bottom: 12px;
  --input-color: #41425a;
  --input-color--active: #41425a;
  --input-color--hover: #7d7d7c;
  --input-error-color: #fb004b;
  --input-placeholder-color: #b3b3b2;
  --input-color--disabled: #b4b4b3;
  --input-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  --input-error-background: #ffe6ec;
  --border-color: #e8e9f0;
  --input-border-radius: 4px;
  --border-style: 1px solid var(--border-color);

  /* inputs - border-colors */
  --input-border-color: #ebebeb;
  --input-border-color--focused: #727173;
  --input-border-color--hover: #a3a3a3;
  --input-border-color--disabled: #e3e4e6;
  --input-border-color--readOnly: transparent;

  /* inputs - border-colors - all states*/
  --input-border: 1px solid var(--input-border-color);
  --input-border--hover: 1px solid var(--input-border-color--hover);
  --input-border--focused: 1px solid var(--input-border-color--focused);
  --input-border--disabled: 1px solid var(--input-border-color--disabled);

  --dropdown-background-color: #eee;

  /* details pane */
  --tables-background-color: #f9f9fb;
  --details-pane-separator-color: #e8e9f0;

  /* react-table */
  --table-border-color: #ebebeb;

  /* label width definitions */
  --label-width-xxs: 70px;
  --label-width-xs: 90px;
  --label-width-s: 110px;
  --label-width-m: 130px;
  --label-width-l: 170px;
  --label-width-xl: 200px;
  --label-width-xxl: 200px;
  --label-width-max: 170px;

  /* input width definitions */
  --input-width-xxs: 108px;
  --input-width-xs: 128px;
  --input-width-s: 138px;
  --input-width-m: 178px;
  --input-width-l: 250px;
  --input-width-xl: 338px;
  --input-width-xxl: 498px;
  --input-width-max: 100%;

  --input-label-width: 170px;

  //page paddings

  --page-padding: 17px;

  @media screen and (max-width: 1440px) {
    --page-padding: 12px;
  }
  @media screen and (max-width: 1080px) {
    --page-padding: 8px;
  }

  //padding
  --padding-xxs: 5px;
  --padding-xs: 10px;
  --padding-s: 15px;
  --padding-m: 20px;
  --padding-l: 25px;
  --padding-xl: 45px;
  --padding-xxl: 55px;
  --padding-xxxl: 75px;

  --global-table-col-padding: 15px;
  --global-table-col-indicator-width: 4px;
  --global-table-col-dummy: 0px;

  /***************************/
  //Findings table col widths
  --findings-table-max-width: 100vw;

  --findings-col-remedetion: 280px;
  --findings-col-account: 185px;
  --findings-col-score: 90px;
  --findings-col-status: 110px;
  --findings-col-ticket-status: 130px;
  --findings-col-resources: 195px;
  --finding-col-ticket-status: 130px;
  --findings-col-sla-status: 120px;
  --findings-col-title: calc(
    var(--findings-table-max-width) - var(--global-table-col-dummy) -
      var(--findings-col-account) - var(--findings-col-score) -
      var(--findings-col-status) - var(--finding-col-ticket-status) -
      var(--findings-col-resources) - var(--findings-col-tags) -
      var(--findings-col-sla-status) - var(--page-padding) * 2 - 20px
  );

  --findings-col-tags: 330px;

  @media screen and (max-width: 1319px) {
    --findings-col-tags: 280px;
  }
  //.hasnt-finding-ticket-status-col {
  //  --findings-col-ticket-status: 0;
  //}
  --findings-col-actions: 0; // position absolute
  --findings-total-width: calc(
    var(--global-table-col-indicator-width) + var(--global-table-col-dummy) +
      var(--findings-col-account) + var(--findings-col-title) +
      var(--findings-col-score) + var(--findings-col-ticket-status) +
      var(--findings-col-status) + var(--findings-col-resources) +
      var(--findings-col-tags) + var(--finding-col-ticket-status) +
      var(--findings-col-sla-status)
  );

  --findings-table-min-width: 1140px;

  /***************************/

  --findingListRowHeight: 52px;

  //------------------------------------------

  --details-pane-width: 40vw;
  --details-pane-min-width: 820px;

  --change-status-width: calc(var(--details-pane-width) - 60px);
  --change-status-min-width: calc(var(--details-pane-min-width) - 60px);

  --ck-focus-ring: var(--input-border--focused);

  --widget-graph-height: calc((100vh - 120px - 120px) / 2);

  --data-sources-header-height: 76px;

  --node-border-distance: -33px;

  --menu-min-height: 240px;
  --menu-max-height: calc(100vh - 360px);
  --grid-gap: 12px;
  --rmq-header-height: 95px;
  --rmq-list-header-wrapper-height: 78px;

  --sidebar-display-area-height: 66px;
}
