.new-ts-btns {
  width: 740px;
  display: flex;
  justify-content: space-between;
}

.back-to-ts-details {
  button {
    padding-left: 0;
  }
}

.new-ts-instance-sub-title {
  font-size: var(--font-size-l);
  color: var(--app-header-color);
  font-weight: 500;
  margin-top: 40px;
}

.integration-fields-wrap {
  .ts-form-btns {
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
  }
}

.ts-instruction-desc {
  width: 50%;
  margin: 20px 0;
}
