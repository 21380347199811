@import "../../../constants";

.tree-wrapper {
  @for $i from 1 through 10 {
    .tree-node-level-#{$i} {
      margin-left: calc(33px * #{$i}); //calc(18px * #{$i});
    }
  }

  .tree-node-wrapper {
    display: flex;
    flex-direction: column;
  }

  .node-branch-border {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 1px;
      border-left: 1px dashed #3f51b5;
      height: 50px;
      top: 0;
    }

    &.node-branch-border-last {
      &:after {
        height: 25px;
      }
    }
  }
  @for $i from 1 through 10 {
    .tree-node-wrapper-data-#{$i} {
      display: flex;
      @for $i from 0 through 10 {
        .node-space-0 {
          margin-left: 15px;
        }

        .node-space-#{$i} {
          margin-left: 33px;
        }

        .node-branch-border-#{$i} {
          margin-left: 33px;
        }
      }
    }
  }

  .tree-node {
    display: flex;
    flex: 1 1 auto;
    margin-right: 20px;
    height: 40px;
    margin-top: 7px;

    .checkbox-input-wrap {
      height: 100%;
      line-height: 40px;
      margin-bottom: 0;
    }

    &.is-selected {
      .highlight-text-wrap {
        color: var(--primary-text-color);
        color: #fff;
      }
      .tree-node-data {
        background-color: var(--secondery-main-color);
        color: white;
        border: 1px solid var(--secondery-main-color);

        &.group,
        &.is-root {
          background-color: var(--secondery-main-color);
          color: white;
          border: 1px solid var(--secondery-main-color);

          .tree-node-edit {
            background-color: var(--secondery-main-color);
            color: white;
            border-left: 1px solid #4f66db;

            &:hover {
              border-left: 1px solid #4f66db;

              .seem-icon-edit {
                color: #fff;
              }
            }
          }
        }
      }

      .tree-node-edit {
        background-color: var(--secondery-main-color);
        color: white;
        border-left: 1px solid #4f66db;

        &:hover {
          border-left: 1px solid #4f66db;

          .seem-icon-edit {
            color: #fff;
          }
        }
      }
      .tree-node-data {
        &.is-root.selectable {
          &:hover {
            background: var(--secondery-main-color);
            color: white;
            .tree-node-edit {
              background: var(--secondery-main-color);
              color: white;
              &:hover {
                background: var(--secondery-main-color);
                color: white;
              }
            }
          }
        }

        &:hover {
          background: var(--secondery-main-color);
          color: white;
          .tree-node-edit {
            background: var(--secondery-main-color);
            color: white;
            &:hover {
              background: var(--secondery-main-color);
              color: white;
            }
          }
        }
      }
    }

    .expandable-indicator {
      position: relative;
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      i {
        font-size: var(--icon-size-xs);
        color: var(--title-color);
        transition: transform ease-in-out 0.07s;
        transform-origin: center;

        &.is-collapsed {
          transform: rotate(-90deg);
        }
      }

      .tree-leaf-icon {
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background-color: var(--title-color);
      }
    }

    .tree-node-data {
      position: relative;
      display: flex;
      align-items: center;
      background-color: #f9f9fb;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius-s-m);
      font-size: var(--font-size-s);
      font-weight: 400;
      cursor: pointer;
      border: 1px solid #e7e7e7;

      &.selectable {
        cursor: pointer;
      }

      &.group {
        background-color: white;

        .tree-node-edit {
          cursor: pointer;
          background-color: white;
        }
      }

      &.is-root {
        background: #fff;
        color: var(--primary-text-color);
        &.not-selectable {
          cursor: default;
          &:hover {
            background-color: #f9f9fb;
          }
        }
        &.selectable {
          &:hover {
            background-color: #fff;
          }
        }
      }

      &:hover {
        // background-color: #e4e7fe;
        border: 1px solid var(--secondery-main-color);
      }

      .tree-node-icon {
        // color: var(--secondery-main-color);
        margin-right: 11px;
      }

      .tree-node-label-container {
        display: flex;
        height: 40px;
        align-items: center;
        width: 100%;
        padding-left: 17px;
        vertical-align: middle;
        line-height: 40px;
        .tree-node-label {
          @include ellipsis(100%);
        }
        .node-warning-icon {
          color: var(--high-alert-color);
          margin-left: auto;
          margin-right: 15px;
          font-size: var(--font-size-xs);
        }
      }
    }

    .tree-node-edit {
      width: 45px;
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
      margin-left: 5px;
      background-color: #f9f9fb;
      // border-radius: var(--border-radius-s);
      border-left: 1px solid #e7e7e7;
      border-top-right-radius: var(--border-radius-s-m);
      border-bottom-right-radius: var(--border-radius-s-m);
      cursor: pointer;

      &:hover {
        color: var(--secondery-main-color);
        border-left: 1px solid var(--secondery-main-color);

        .seem-icon-edit {
          color: var(--secondery-main-color);
        }
        // background-color: #e4e7fe;
      }
    }
    &:last-child {
      .tree-node-data:after {
        height: 20px;
      }
    }
  }

  .node-branch-border-last {
    height: 25px;
  }
}

.dropdown-tree.tree-wrapper {
  @for $i from 1 through 10 {
    .tree-node-level-#{$i} {
      margin-left: calc(25px * #{$i}); //calc(18px * #{$i});
    }
  }
  .node-branch-border {
    @for $i from 1 through 10 {
      .tree-node-wrapper-data-#{$i} {
        @for $i from 0 through 10 {
          .node-space-#{$i} {
            margin-left: 25px;
          }

          .node-branch-border-#{$i} {
            margin-left: 25px;
          }
        }
      }
    }
  }
  .tree-node .tree-node-data .tree-node-label-container {
    padding-left: 0;
  }
}
