@import "../constants.scss";

/*:root {*/
/*    --main-background-color: #292E30;*/
/*    --main-border-color: #292E30;*/
/*    --table-row-color: #4D575C;*/
/*    --main-text-color: #FFFFFF;*/

/*    !* severity colors *!*/
/*    --critical-alert-color: #FB3A29;*/
/*    --high-alert-color: #FF8902;*/
/*    --medium-alert-color: #FAC000;*/
/*    --low-alert-color: #00D068;*/

/*    --finding-operations-color: #8E979B;*/
/*}*/

.seem-icon {
  font-size: var(--font-size-m);
}

.seem-icon-xs {
  font-size: var(--font-size-xs);
}

input[type="submit"] {
  outline: none;
  cursor: pointer;
}

input[type="submit"]:hover {
  outline: none;
  cursor: pointer;
  box-shadow: 0 3px 16px #00000029;
}

::-webkit-scrollbar {
  // Width of vertical scroll bar
  width: 5px;
  // Height of horizontal scroll bar
  height: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #c2c9d2;
}

.input-wrap-select {
  &.temp-is-inline {
    margin-right: 10px;
  }
}
.spinner {
  animation: rotate-forever 0.7s infinite;
}
.see-link,
a {
  color: var(--link-color);
  cursor: pointer;
  text-decoration: underline;
  &:visited {
    color: var(--link-color);
  }
  &:hover {
    color: var(--link-color--hover);
  }
  &:active {
    color: var(--link-color--active);
  }
  &:focus {
    color: var(--link-color--focus);
  }
}
.bold {
  font-weight: 500;
}
@keyframes grow {
  0% {
    transform: scale(0.4) translateY(10px);
  }
  100% {
    transform: scale(1) translateY(0px);
  }
}

@keyframes float-around {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(random(30) + px, 1px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes up-and-away {
  0% {
    transform: translate(0px, 60px);
    opacity: 1;
  }
  100% {
    transform: translate(0px, 0px);
    opacity: 0;
  }
}
@keyframes move-thumb {
  0% {
    transform: translate(0px, 8px) rotate(30deg);
    opacity: 0.4;
  }
  15% {
    transform: translate(0px, 0px) rotate(0deg);
    opacity: 1;
  }
  25% {
    transform: rotate(10deg);
  }
  35% {
    transform: rotate(-10deg);
  }
  45% {
    transform: rotate(10deg);
  }
  55% {
    transform: rotate(-10deg);
  }
  65% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  85% {
    transform: rotate(10deg);
  }
  95% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes twiches {
  0% {
    opacity: 1;
    transform: rotate(10deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(-10deg);
  }
  80% {
    transform: rotate(10deg);
  }
  90% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes move-hand {
  0% {
    transform: translate(0px, 8px) rotate(30deg);
    opacity: 0.4;
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
    opacity: 1;
  }
}

@keyframes up-and-away-10 {
  0% {
    transform: translate(40px, 40px) rotate(-40deg);
    opacity: 0.1;
  }
  100% {
    transform: translate(0px, 0px) rotate(10deg);
    opacity: 1;
  }
}
@keyframes up-and-away-20 {
  0% {
    transform: translate(-10px, 30px) rotate(-10deg);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg) scale(1.1);
    opacity: 1;
  }
}
@keyframes up-and-away-50 {
  0% {
    transform: translate(-40px, 20px) rotate(10deg);
    opacity: 0.3;
  }
  100% {
    transform: translate(0px, 0px) rotate(65deg) scale(1.3);
    opacity: 1;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.align-left {
  display: flex;
  justify-content: flex-start;
}
.seema-error-wrap {
  position: relative;
  width: 105px;
  height: 105px;

  .seema-bg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 231, 237, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    animation: grow 0.5s ease-out;
    //animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
  .seema-question-mark {
    width: 30px;
    height: 30px;
    top: 25px;
    left: 5px;
    background-image: url("../icons/images/questionmark.svg");
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    animation: up-and-away-10 0.7s ease-out;
    //animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    //animation-direction: alternate;
    animation-delay: 0.4s;
    opacity: 0;
  }
  .seema-question-mark:nth-child(3) {
    left: 25px;
    top: 6px;
    animation: up-and-away-20 0.8s ease-out;
    animation-fill-mode: forwards;
    animation-delay: 0.4s;
  }

  .seema-question-mark:nth-child(4) {
    left: 69px;
    top: 18px;
    animation: up-and-away-50 0.6s ease-out;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
  }

  .seema-face {
    background-image: url("../icons/images/girl.svg");
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    opacity: 0;
    top: 15px;
    transform: translatey(0px);
    animation-delay: 1s;
    animation: appear 0.5s ease-in;
    animation-fill-mode: forwards;
  }

  .seema-hand {
    background-image: url("../icons/images/girl-hand.svg");
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 21px;
    left: -12px;
    opacity: 0;
    transform: translate(0px, 8px) rotate(30deg);
    animation: move-hand 0.2s ease-out;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    transform-origin: 50% 70%;
  }
}

.seema-success-wrap {
  height: 50px;
  width: 100px;
  position: relative;

  .seema-face {
    background-image: url("../icons/images/girl-face-success.svg");
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    opacity: 0;
    transform: translatey(0px);
    animation: appear 0.1s;
    animation-fill-mode: forwards;
    top: 15px;
  }

  .seema-twiches {
    background-image: url("../icons/images/girl-thumb-twiches.svg");
    width: 17px;
    height: 9px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 16px;
    top: 23px;
    opacity: 0;
    animation: twiches 0.8s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
  }
  .seema-thumb {
    background-image: url("../icons/images/girl-thumb.svg");
    width: 19px;
    height: 24px;
    left: 19px;
    top: 39px;
    background-position: center;
    background-repeat: no-repeat;
    animation: move-thumb 1s ease-out;
    position: absolute;
    animation-fill-mode: forwards;
  }
}

div[class^="css-"] {
  z-index: 9999 !important;
}
.__react_component_tooltip {
  padding: 6px 10px !important;
}
.loading-row {
  @include loading-row(80%, 20px);
}
.loading-blocks-wrap {
  position: relative;
}
.loading-block-row {
  @include loading-row(100px, 24px);
  top: 0px;
  left: 30px;
}
.loading-block-square {
  @include loading-row(50px, 50px);
  top: 0px;
  left: 30px;
}
.center {
  margin: 0 auto;
  text-align: center;
}
.title {
  font-size: var(--font-size-l);
  color: var(--title-color);
  font-weight: 600;
  margin-bottom: 10px;
}
.subtitle {
  font-size: var(--font-size-s);
}

@keyframes flicker-animation {
  from {
    opacity: 0;
    transform: translate3d(0, 4px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.animate-flicker {
  animation: flicker-animation 1s ease-in-out 0s forwards infinite;
}
