.scoring-customization-form {
  max-height: calc(100vh - 200px);
}

.scoring-customization-modal {
  .modal-content {
    //max-height: calc(100vh - 200px);
    min-height: 551px;
  }
}
.scoring-customization-form {
}
.ReactCollapse--content {
  padding: 20px 25px;
  background: #fff;
  border-top: var(--border-style);
}
.customization-item-wrapper {
  align-items: center;
  i.seem-icon-next-arrow {
    font-size: 10px;
  }
}
.scoring-customization-item-toggle {
  display: flex;
  align-items: center;
  padding: 10px;
  background: var(--main-background-color);

  .scoring-customization-item-toggle-icon-wrap {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform ease-in-out 0.05s;
    margin-right: 10px;

    &.is-open {
      transform: rotate(90deg);
      transform-origin: center;
    }
  }
  &:hover {
    opacity: 0.85;
    background: var(--main-background-color);
  }
}
.customization-item-wrapper-titles {
  display: flex;
  color: var(--title-color);
  justify-content: space-between;
  margin-bottom: 15px;
  width: 270px;
  font-size: var(--font-size-s);
  font-weight: 500;
}
.scoring-customization-item {
  cursor: pointer;
  //margin: 20px 10px;
  border: var(--border-style);
  //padding: 8px 12px;
  .input-wrap.input-wrap-text {
    width: 60px;
  }
  .ReactCollapse--content {
    padding: 15px 20px;
  }
}
.original-score-wrap {
  display: flex;
  margin-bottom: 5px;
  height: 38px;
  align-items: center;
  font-size: var(--font-size-s);
  padding-top: 2px;
  width: 74px;
  font-weight: 500;
  color: var(--primary-text-color);
  text-transform: capitalize;
}
.scoring-customization-form-content {
  border-bottom: var(--border-style);
  height: 435px;
  max-height: calc(100vh - var(--header-height) - 215px);
  overflow: auto;

  img {
    height: 34px;
  }
}
