@import "../../constants";

.share-app-view-wrap {
  display: flex;
  padding: 13px 15px 0 15px;
}
.scope-filter-copy {
  cursor: pointer;
  &:hover {
    color: var(--secondery-hover-color);
  }
}
