.react-tags {
  position: relative;
  padding: 3px 0 0 6px;
  border: var(--border-style);
  border-radius: var(--border-radius-s);
  padding-right: 50px;
  font-size: var(--font-size-s);
  line-height: 1.2;
  cursor: text;
  width: 100%;
  min-height: 36px;
  display: flex;
  align-items: center;

  .react-tags__selected {
    display: inline;

    .tag-wrapper {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .react-tags__search {
    display: inline-flex;
    .react-tags__search-wrapper {
      margin-left: 8px;
      width: 100%;
      color: inherit;

      .react-tags__search-input {
        /* prevent autoresize overflowing the container */
        max-width: 100%;
        min-width: 80px;
        margin: 0;
        padding: 0;
        border: 0;
        outline: none;

        /* match the font styles */
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
}
.tag-wrapper {
  display: inline-flex;
  box-sizing: border-box;
  margin: 3px 6px 3px 0;
  padding: 2px 8px;
  line-height: inherit;
  align-items: center;
  border: 1px solid var(--secondery-main-color);
  border-radius: 100px;
  height: 24px;
  font-size: var(--font-size-xs);
  color: var(--secondery-main-color);

  i {
    margin-left: 5px;
    font-size: 7px;
    cursor: pointer;
  }

  &:hover {
    border: 1px solid #4c63dd;
    color: #4c63dd;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
  }
}
