.ds-actions-wrapper {
  width: 741px;

  .ds-header-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .ds-actions-title {
    font-size: var(--font-size-l);
    color: var(--app-header-color);
    font-weight: 500;
    &.instance-details {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .ds-actions-wrapper {
    width: 480px;
  }
}
