@import "../../../../constants.scss";
.tag-wrapper {
  display: inline-flex;
  box-sizing: border-box;
  margin-right: 4px;
  padding: 3px 8px;
  line-height: inherit;
  align-items: center;
  border: 1px solid var(--secondery-main-color);
  border-radius: 100px;
  height: 24px;
  font-size: var(--font-size-xs);
  color: var(--secondery-main-color);
  &:last-child {
    margin-right: 0;
  }

  .tag-name {
    @include ellipsis(100%);
  }

  &.is-owner {
    border: 1px solid var(--secondery-main-color);
    color: var(--secondery-main-color);
  }

  &.is-regular {
    border: 1px solid var(--secondary-button-background-disabled);
    color: var(--primary-text-color--hover);

    &:hover {
      border: 1px solid #b1b7dd;
      color: var(--primary-main-color);
    }
  }

  i {
    margin-left: 5px;
    font-size: 7px;
    cursor: pointer;
  }

  &:hover {
    border: 1px solid #4c63dd;
    color: #4c63dd;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
  }

  &.is-disabled {
    cursor: pointer;
  }
}
.Popover-body {
  .tag-wrapper {
    margin-bottom: 4px;
  }
}
