.ticketing-systems-wrapper {
  display: flex;
  height: 100%;
}

.ticketing-systems-wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .integrations-sub-title {
    font-size: var(--font-size-l);
    color: var(--app-header-color);
    font-weight: 500;
    margin: 8px 0 24px 0;
  }

  .integrations-section-wrap {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    margin-bottom: 35px;
    width: 100%;
  }
}
