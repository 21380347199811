@import "../../constants.scss";

.sidebar-display-area-wrapper {
  background-color: white;
  width: 100%;
  height: 100%;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-s);
  position: relative;

  .sidebar-display-area-top-button {
    position: absolute;
    right: 24px;
    top: 17px;
  }
  .settings-section + .settings-section {
    margin-top: 50px;
  }
  .sidebar-display-area-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
  }

  .sidebar-display-area-title-wrap {
    color: var(--app-header-color);
    padding: 0 24px;
    display: flex;
    align-items: center;
    height: var(--sidebar-display-area-height);
    border-bottom: var(--border-style);
  }
  .sidebar-display-area-title {
    font-weight: 500;
    font-size: var(--font-size-xl);

    .display-area-title-separator {
      color: var(--secondery-main-color);
    }
  }
  .sidebar-display-area-content {
    padding: 24px 28px 0 28px;
    height: calc(100% - var(--sidebar-display-area-height));
    overflow: auto;
  }
}
