.workflows-container {
  height: 100%;
  position: relative;
  .react-flow {
    height: 100%;
  }

  .loader-wrap {
    background: transparent;
  }

  .create-rmq-btn {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 30px;
    z-index: 5;
  }

  &.is-empty {
    .react-flow {
      height: calc(100% - 164px);
    }

    padding-top: 21px;
  }
}
