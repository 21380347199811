@import "../../constants";

.user-profile-wrapper {
  .user-profile-details-form {
    .user-profile-details-fields {
      width: 369px;

      & > :not(:last-child) {
        margin-bottom: 12px;
      }
    }

    .save-user-profile-details {
      margin-top: 26px;
    }
  }

  .mfa-already-enabled {
    display: flex;
    align-items: center;
    font-size: var(--font-size-s);
    font-weight: 500;
    margin-top: 18px;

    i {
      font-size: var(--font-size-m);
      margin-right: 8px;
      color: var(--secondery-main-color);
    }
  }
}

.reset-password-form {
  .modal-buttons-container {
    justify-content: space-between;
    margin-top: 22px;

    .form-button-wrap {
      margin: 0;
    }
  }
}
