@import "../../constants";

.last-sync-wrapper {
  position: relative;
  width: 346px;
  font-size: 14px;
  color: #41425a;
  padding: 10px 5px;

  .data-table-cell-header {
    color: #41425a;

    &.integration-header {
      width: 140px;
    }
    &.update-header {
      width: 190px;
    }
  }

  .data-table-row {
    font-weight: 500;

    .data-table-cell {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      position: relative;

      &.is-loading {
        padding: 0px;
      }

      &.integration-content {
        width: 140px;
        height: 42px;
        padding-left: 8px;

        .data-source-icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          overflow: hidden;
        }
      }

      &.update-content {
        width: 190px;
        padding-left: 12px;
      }

      .loading-row {
        width: 80%;
      }
    }
  }

  .last-sync-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: inherit;
  }
}
