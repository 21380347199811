@import "../../../../constants";

@keyframes background-blink {
  0% {
    background-color: var(--secondary-button-background-color);
    color: #fff;
  }
  75% {
    background-color: var(--secondary-button-background-color);
    color: #fff;
  }
  100% {
    background-color: #f0f0f3;
    color: var(--primary-main-color);
  }
}
.filter-row-item {
  display: flex;
  margin-right: 4px;
  margin-bottom: 3px;
  height: 32px;
  transition: background-color ease-in-out 0.35s;

  &.is-blink {
    .filter-row-selected-value,
    .condition-selected-value,
    .field-selected-value,
    .seem-icon {
      animation-name: background-blink;
      animation-fill-mode: forwards;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
    }
  }
}
.filter-row-item {
  .is-active {
    .selected-filter-value {
      .remove-filter-icon {
        .seem-icon {
          color: #fff;
        }
      }
    }
  }
}

.filter-row-selected-value {
  .selected-filter-value {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-button-wrap.remove-filter-icon {
      height: unset;
      margin-left: 10px;
      margin-right: 0;
      margin-top: 0;
      font-size: var(--font-size-xxs);
      color: var(--primary-main-color);
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.dropdown-tag-container {
  padding-top: var(--filter-menu-item-paddings);
  width: 100%;
  .dropdown-tag-container-tags {
    max-height: 120px;
    overflow: auto;
    padding: 0 var(--filter-menu-item-paddings);
  }

  .filter-values-text-box {
    padding: 0 var(--filter-menu-item-paddings);
  }
}
