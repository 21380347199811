@import "../application/src/constants";

.ds-instance-wrapper {
  display: flex;
  align-items: center;
  height: 44px;
  width: 997px;
  border: 1px solid var(--main-border-color);
  border-radius: var(--border-radius-s);
  color: var(--primary-main-color);
  margin-bottom: 8px;

  & > :not(:last-child) {
    border-right: 1px solid var(--main-border-color);
  }

  .ds-instance-cell {
    display: flex;
    align-items: center;
    height: 37px;
    padding-right: 16px;
    padding-left: 16px;

    &.ds-instance-name {
      font-weight: 500;
      width: 180px;
      padding-top: 7px;
      @include ellipsis(100%);
    }

    &.ds-instance-schedule {
      justify-content: flex-end;
    }

    &.ds-instance-last-sync {
      flex-grow: 1;
      justify-content: space-between;
      width: 260px;
    }
  }
}
