@import "../../constants";

.tabs-menu-wrapper {
  width: 100%;

  .menu-underline {
    height: 2px;
    background: #efefef;
    width: 100%;
    margin-top: -2px;
  }

  .menu-tabs {
    display: flex;

    .sub-header-menu-item-link {
      margin-right: 15px;
      font-weight: 500;
      font-size: var(--font-size-s);
      line-height: 20px;
      transition: color ease-in-out 0.2s;
      @include ellipsis(100%);
      cursor: pointer;

      .sub-menu-item-label {
        .sub-menu-item-title {
          padding-left: 15px;
          padding-right: 15px;
          cursor: pointer;
        }

        &.has-left-img {
          display: flex;

          img {
            height: 16px;
          }

          .sub-menu-item-title {
            padding-left: 7px;
          }
        }
      }

      .sub-item-underline {
        border-top-left-radius: var(--border-radius-xs);
        border-top-right-radius: var(--border-radius-xs);
        width: 100%;
        height: 2px;
        margin-top: 12px;
        opacity: 0;
        transition: opacity ease-in-out 0.2s, background-color ease-in-out 0.2s;
      }

      &:hover {
        opacity: 0.7;

        .sub-item-underline {
          opacity: 0.7;
          background-color: var(--primary-main-color);
        }
      }

      &.active {
        &:hover {
          opacity: 1;
        }
        color: var(--secondery-main-color);
        .sub-item-underline {
          opacity: 1;
          background-color: var(--secondery-main-color);
        }
      }
    }
  }
}
