@import "../application/src/constants";

.integrations-wrap {
  height: 100%;
  overflow: hidden;
}

.integrations-section {
  width: 100%;
  height: calc(100% - 101px);
  overflow: scroll;
  &.is-loading {
    padding: 0;
  }

  .integrations-section-title {
    color: var(--app-header-color);
    font-size: var(--font-size-l);
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 22px;
  }

  .integrations-section-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
    margin-bottom: 35px;
    margin-right: 12px;
  }
}

.edit-datasource-form-wrapper {
  width: 100%;
}
