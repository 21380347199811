.rmq-queue-header {
  background-color: var(--secondery-main-color);
  height: var(--rmq-header-height);
  width: 100%;
  display: flex;
  justify-content: center;

  &:after {
    content: "";
    background: var(--secondery-main-color);
    width: 100%;
    position: absolute;
    left: 0;
    top: 86px;
    right: 0;
    height: 90px;
  }
  &.rmq-queue-header-edit {
    background-color: #f9f9fb;
    &:after {
      content: none;
    }
  }

  .rmq-queue-header-content {
    padding: 15px calc(var(--page-padding) * 2);
    width: calc(100% - 2 * var(--page-padding));
    max-width: var(--rmq-container-width);
    display: flex;
    justify-content: space-between;
  }

  .rmq-queue-header-content-read {
    .header-summary {
      color: #ffffff;

      .header-back-button {
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        .seem-icon-left-arrow {
          font-size: var(--icon-size-xs);
          margin-right: 4px;
        }
        .header-back-button-text {
          margin-top: 2px;
        }
        &:hover {
          color: #cacaca;
        }
      }

      .header-update {
        font-size: 12px;
        height: 12px;
        line-height: 12px;
      }
    }
  }

  .rmq-queue-header-content-edit,
  .rmq-queue-header-content-create {
    align-items: center;
    transform: translateY(-15px);
    padding-bottom: 0;
    padding-top: 75px;
  }

  .header-actions {
    & *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.header-queue-title {
  font-size: var(--font-size-xl);
  font-weight: 500;
  color: #ffffff;
  min-height: 34px;

  &.header-queue-title-edit {
    color: #26026c;
  }
}
