@import "../../constants.scss";

.sla-customization-wrapper {
  min-height: 520px;
  max-width: 750px;

  .sla-customization-description {
    margin-bottom: 21px;
    font-weight: 400;
    color: var(--primary-main-color);
    font-size: var(--font-size-s);
  }

  .sla-preferences-form {
    display: flex;
    height: 490px;
    overflow: auto;

    &.is-loading {
      justify-content: center;
      align-items: center;
    }
  }

  .sla-form-separator {
    height: 1px;
    background-color: var(--main-border-color);
    width: 100%;
    margin-bottom: 28px;
    margin-top: 18px;
  }

  .sla-item-row {
    display: flex;
    height: 36px;
    margin-bottom: 12px;

    &.prioritize-rmq {
      i {
        margin-bottom: 6px;
      }
    }

    &.title-row {
      margin-bottom: 0;
    }

    .sla-preference-expected {
      width: 300px;
      margin-right: 14px;
    }

    .sla-field-wrap {
      display: flex;
      align-items: center;
      border-radius: var(--border-radius-s);
      margin-right: 14px;
      height: 38px;

      &.sla-field-title {
        color: var(--title-color);
        font-weight: 500;
        background-color: transparent !important;
        padding: 0 !important;
        font-size: var(--font-size-s);
      }

      &.sla-preference-name {
        background: #f9f9fb;
        padding: 0 18px;
        width: 137px;

        .sla-field-text {
          color: var(--primary-main-color);
          font-weight: 400;
          font-size: var(--font-size-s);
        }
      }

      .sla-field-indicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #ddd;
        margin-right: 8px;

        &.sla-field-indicator-low {
          background: var(--low-alert-color);
        }
        &.sla-field-indicator-medium {
          background: var(--medium-alert-color);
        }
        &.sla-field-indicator-high {
          background: var(--high-alert-color);
        }
        &.sla-field-indicator-critical {
          background: var(--critical-alert-color);
        }
      }
    }

    .prioritize-tooltip {
      width: 400px;
      border-radius: var(--border-radius-xs);
      height: 80px;
      font-size: var(--font-size-s);
      font-weight: 400;
      line-height: 19.6px;
      display: flex;
      align-items: center;
    }
  }

  .sla-form-data {
    width: 100%;

    .sidebar-display-area-footer {
      .action-buttons {
        & > :not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }

  .sla-tracking-toggle-wrap {
    display: flex;
    align-items: center;

    .checkbox-label-data {
      color: var(--primary-main-color);
      font-weight: 700;
    }

    .sla-tracking-toggle {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      .checkbox-label-data {
        margin-top: 0;
      }

      i {
        color: var(--primary-main-color);
      }
    }

    .input-help-text {
      margin-left: 8px;
    }
  }
}
/* Style while in modal */
.modal-content {
  .sidebar-display-area-footer {
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding-top: 22px;
  }
}
.sidebar-display-area-content {
  .sla-customization-wrapper {
    min-height: unset;
  }
  .sla-preferences-form {
    height: auto;
    overflow: auto;
  }
}
