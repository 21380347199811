.sync-queue-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .sync-queue-message {
    width: 315px;
    height: 100px;
    font-size: 14px;
    color: #41425a;
    line-height: 1.43;

    & :first-child {
      margin-bottom: 10px;
    }
  }

  .sync-queue-buttons {
    margin-top: 80px;
    margin-bottom: 30px;

    & :first-child {
      margin-right: 10px;
    }
  }
}
