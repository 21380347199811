@import "../../../constants";

.user-actions-form {
  margin-top: 6px;
  height: fit-content;
  min-height: 560px;

  & > :not(:last-child) {
    margin-bottom: 20px !important;
  }

  .user-actions-email {
    display: flex;
    align-items: center;
  }

  .user-actions-field {
    width: 360px;
  }

  .allowed-scopes-btn {
    margin-top: -8px;
    margin-bottom: 38px;
  }
}

.user-added-success-msg {
  display: flex;
  flex-direction: column;
  line-height: 20.5px;

  i {
    font-size: var(--font-size-xxxxl);
    color: var(--low-alert-color);
    margin-bottom: 16px;
  }
}
