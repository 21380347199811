@import "../../constants";

.filters-management-wrapper {
  position: relative;
  display: flex;
  background: #ffffff;
  min-height: 40px;
  padding-bottom: 12px;
  //padding-bottom: 8px;
  //&:after {
  //  position: absolute;
  //  bottom: 0;
  //  left: 0;
  //  right: 0;
  //  content: '';
  //  height: 20px;
  //  //background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,.5) 0%);
  //  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,1) 100%);
  //}
  &:hover {
    .filters-menu-header {
      border: 1px solid var(--secondery-main-color);
      border-right: 0;
    }
    .filter-panel-data {
      border: 1px solid var(--secondery-main-color);
      border-left: 0;
    }
  }

  &.read-only {
    //pointer-events: none;
    .filters-menu-header {
      .selected-filter-title {
        color: #8c8c96;
      }
    }
    .filters-menu-header {
      cursor: default;
      border-color: var(--input-border-color);
    }
    .filter-panel-data {
      border-color: var(--input-border-color);
    }
    .filter-row-selected-value {
      max-width: 400px;
    }
  }

  .filter-separator-wrap {
    display: flex;
    width: 1px;
  }
  .filter-separator {
    width: 1px;
    height: 100%;
    background-color: var(--input-border-color);
    z-index: 1;
  }
}
