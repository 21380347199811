@import "../../../constants.scss";

.checkbox-list-wrapper {
  display: flex;
  //border-radius: var(--border-radius-m);
  font-size: var(--input-font-size);

  &.row {
    flex-flow: row wrap;
    padding-top: 5px;
  }

  &.column {
    flex-direction: column;
  }
  .checkbox-list-option {
    height: 30px;
    line-height: 32px;
    padding-right: 10px;
  }
  .checkbox-list-option:last-child {
    margin-right: 0;
  }
  .checkbox-list-label {
    font-weight: 600;
    margin-left: 3px;
  }
  .checkbox-list-options-container {
    display: flex;
    &.with-border {
      border: var(--input-border);
      padding: 10px 8px;
    }
    &.row {
      flex-flow: row wrap;
    }

    &.column {
      flex-direction: column;

      .checkbox-list-option {
        padding-bottom: 5px;
        padding-right: 0;
        &:last-child {
          padding-bottom: 0 !important;
          margin-bottom: 0 !important;
        }
      }
    }
    .checkbox-list-option {
      flex: 0 0 auto;
      white-space: nowrap;
      width: unset;
      padding: 0;

      &:first-child {
        margin-top: 0;
      }
      .checkbox-label {
        padding-top: 2px;
      }
    }
  }
  &.checkbox-list-wrapper-dateselect {
    .checkbox-list-option {
      height: 35px;
    }
    .checkbox-input-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eeeeee;
      height: 35px;
      width: 45px;

      .checkbox-label:before,
      .checkbox-label:after {
        display: none;
      }

      .checkbox-input + .checkbox-label {
        padding: 0;
        margin: 0;
      }
      &.is-checked {
        background: var(--secondery-main-color);
        color: #fff;
      }
    }
  }
  .loader-wrap .loader-icon img {
    width: 36px;
    height: 38px;
    margin-bottom: 5px;
  }
}
.has-search {
  .checkbox-list-option:first-child {
    margin-top: 0;
  }
}
