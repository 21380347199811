.tree-wrapper {
  display: flex;
  flex-flow: column nowrap;
  padding: 7px 3px;

  .tree-nodes-wrap {
    height: 100%;

    &.is-loading,
    &.no-data {
      height: 200px;
    }
  }

  &.dropdown-tree {
    overflow-y: auto;
    color: var(--primary-main-color);
    .tree-node-wrapper {
      .expandable-indicator {
        .seem-icon-chevron-down {
          color: var(--primary-main-color);
        }
      }
      &.is-selected {
        @for $i from 0 through 10 {
          > .tree-node-wrapper-data-#{$i} {
            background-color: #e3e6fe;
            &:hover {
              background-color: #e3e6fe;
            }

            .tree-node-data {
              &.is-root {
                color: var(--primary-main-color);
                &:hover {
                  background-color: #e3e6fe;
                  color: var(--primary-main-color);
                }
              }
            }
          }
        }
      }

      @for $i from 0 through 10 {
        .tree-node-wrapper-data-#{$i} {
          &:hover {
            background-color: #ddd;

            .tree-node-data {
              border: none;
              background-color: transparent;

              &.is-root {
                background-color: transparent;
              }
            }
          }
        }
      }

      .tree-node {
        height: 32px;
        margin-top: 0;

        &.is-selected {
          .tree-node-data {
            border: none;
            background-color: transparent;
            color: var(--primary-main-color);
          }
        }

        .tree-node-data {
          border: none;
          background-color: transparent;
        }
      }
    }
  }
}
