.saml-instructions-wrapper {
  .download {
    color: var(--secondery-main-color);
    font-weight: 500;
    text-decoration: none;
  }

  .seem-icon-download:before {
    color: var(--secondery-main-color);
    padding-right: 8px;
  }
}
