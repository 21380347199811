@import "../../../../../constants";

.badge-icon-container {
  &.backlog {
    background-color: var(--jira-backlog-color);
  }

  &.scheduled {
    background-color: var(--jira-scheduled-color);
  }

  &.in_progress {
    background-color: var(--jira-in-progress-color);
  }

  &.done {
    background-color: var(--jira-done-color);
  }
  &.deleted {
    background-color: #ededed;
    color: #303144;
  }
  &.rejected {
    background-color: var(--jira-rejected-color);

    .seem-icon {
      font-size: var(--font-size-xs);
    }
  }
}

.ticket-status-wrap .jira-status-container {
  cursor: default;
}
.details-pane-status-container {
  .jira-status-container:hover {
    .jira-status-text {
      color: #303144;
      cursor: default;
    }
  }
}
.table-cell,
.jira-status-td {
  .jira-status-container:hover .jira-status-text {
    color: var(--input-color);
  }
}
